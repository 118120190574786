import React, { useState } from 'react';
import { Box, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import NumericInput from 'components/inputs/NumericInput';
import clsx from 'clsx';

export default function ProductInformation({ onError }) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            stage: user.ActiveProfile?.meta?.stage || '',
            fullTime: user.ActiveProfile?.meta?.fullTime || '',
            employees: user.ActiveProfile?.meta?.employees || '',
            raised: user.ActiveProfile?.meta?.raised || '',
            amountRaised: user.ActiveProfile?.meta?.amountRaised || '',
            ownerPercent: user.ActiveProfile?.meta?.ownerPercent || '',
            hasUsers: user.ActiveProfile?.meta?.hasUsers || '',
            activeUsers: user.ActiveProfile?.meta?.activeUsers || '',
            profitable: user.ActiveProfile?.meta?.profitable || '',
        },
    });

    const fullTime = watch('fullTime', user.ActiveProfile?.meta?.fullTime || '');
    const raised = watch('raised', user.ActiveProfile?.meta?.raised || '');
    const hasUsers = watch('hasUsers', user.ActiveProfile?.meta?.hasUsers || '');

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/founder/product', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Additional Information" sx={{ width: '100%' }}>
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />

                <Box className={classes.fieldContainer}>
                    <Typography>Current Product Stage</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.stage)}
                        defaultValue={user.ActiveProfile?.meta?.stage || ''}
                        {...register('stage', {})}
                    >
                        <MenuItem value="">Select your stage</MenuItem>
                        <MenuItem value="Idea">Idea</MenuItem>
                        <MenuItem value="MVP/Prototyping">MVP/Prototyping</MenuItem>
                        <MenuItem value="Live product being used">Live product being used</MenuItem>
                    </Select>
                </Box>
                <Box className={classes.fieldContainer}>
                    <Typography>
                        Are you full time on <span className="underline">{user?.ActiveProfile?.meta?.companyName}</span>
                        ?
                    </Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.fullTime)}
                        defaultValue={user.ActiveProfile?.meta?.fullTime || ''}
                        {...register('fullTime', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </Box>
                {fullTime === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>How many people besides yourself are current working?</Typography>
                        <NumericInput
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`employees`]}
                            helperText={errors?.[`employees`]?.message}
                            {...register(`employees`, {})}
                        />
                    </Box>
                )}
                <Box className={classes.fieldContainer}>
                    <Typography>Have you raised money yet?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.raised)}
                        defaultValue={user.ActiveProfile?.meta?.raised || ''}
                        {...register('raised', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </Box>
                {raised === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>How much have you currently raised?</Typography>
                        <NumericInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`amountRaised`]}
                            helperText={errors?.[`amountRaised`]?.message}
                            {...register(`amountRaised`, {})}
                        />
                    </Box>
                )}
                {/* {raised === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>
                            How much ownership do you have?
                        </Typography>
                        <NumericInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            className={classes.field}
                            fullWidth
                            precision={2}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`ownerPercent`]}
                            helperText={errors?.[`ownerPercent`] && 'Choose a value between 0 and 100'}
                            {...register(`ownerPercent`, {
                                validate: (value) => {
                                    console.log(value);
                                    return value?.stringToNumber?.() >= 0 && value?.stringToNumber?.() <= 100;
                                },
                            })}
                        />
                    </Box>
                )} */}

                <Box className={classes.fieldContainer}>
                    <Typography>
                        Do you have users/customers using{' '}
                        <span className="underline">{user?.ActiveProfile?.meta?.companyName}</span>?
                    </Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.hasUsers)}
                        defaultValue={user.ActiveProfile?.meta?.hasUsers || ''}
                        {...register('hasUsers', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </Box>
                {hasUsers === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>How many users/customers do you have?</Typography>
                        <NumericInput
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`activeUsers`]}
                            helperText={errors?.[`activeUsers`]?.message}
                            {...register(`activeUsers`, {})}
                        />
                    </Box>
                )}

                <Box className={classes.fieldContainer}>
                    <Typography>Are you current making money?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.profitable)}
                        defaultValue={user.ActiveProfile?.meta?.profitable || ''}
                        {...register('profitable', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes, profitable">Yes, profitable</MenuItem>
                        <MenuItem value="Yes, but not profitable">Yes, but not profitable</MenuItem>
                        <MenuItem value="No, we aren’t making money">No, we aren’t making money</MenuItem>
                    </Select>
                </Box>
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Skip
                </LoadingButton>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
