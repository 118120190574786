import React, { useEffect, useState } from 'react';
import { setSnackbar } from 'redux/actions/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
// Custom
import { Box, Container, Typography, Breadcrumbs, Paper, Button, Hidden, Skeleton } from '@mui/material';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
// images
// import { Search } from '@mui/icons-material';
// Styles
import useStyles from './styles';
import checkError from 'utils/check-error';
import wait from 'utils/wait';
import { RequestStatus } from 'utils/constants';
import { Link } from 'react-router-dom';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';

const TableHeaders = ['To', 'Status', 'Their Response', 'Created'];

const translation = {
    To: 'To.User.firstName',
    Status: 'status',
    'Their Response': 'reply',
    Created: 'createdAt',
};

export default function Introductions(props) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [requests, setRequests] = useState({ items: [], count: 0 });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ order: 'asc', orderBy: 'Created' });

    useEffect(() => {
        getRequests();
    }, [sort, page, perPage]);

    async function getRequests() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];
            const response = await SeedscoutAPI.get(
                `/v1/requests?FromId=${user.ActiveProfile.id}&perPage=${perPage}&page=${page}&order=${sort.order}&orderBy=${orderByTl}`,
            );
            setRequests(response.data);
            await wait(1);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <Box className={classes.header} sx={{ mb: 2 }}>
                <Hidden smDown>
                    <Typography variant="h6">Introductions</Typography>
                </Hidden>
                <Box className={classes.center} sx={{ marginTop: { xs: 2, md: 0 } }}>
                    {/* <TextField
                        size="small"
                        placeholder="search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                    <Button
                        component={Link}
                        to="/investors"
                        variant="contained"
                        sx={{ marginLeft: 1, color: 'white !important' }}
                    >
                        Add New
                    </Button>
                </Box>
            </Box>
            <Card cardOnly>
                <CustomTable
                    page={page}
                    count={requests.count}
                    onSort={handleOnSort}
                    rowsPerPage={perPage}
                    onChangePage={handleChangePage}
                    onChangeRows={handleChangeRows}
                    pagination={requests.items.length !== 0}
                    initialOrderIndex={3}
                    headers={TableHeaders}
                    rows={
                        loading
                            ? Array.from(new Array(5)).map((_, i) => {
                                  return {
                                      columns: [<Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />],
                                  };
                              })
                            : requests.items.map((item) => {
                                  return {
                                      columns: [
                                          <Typography variant="font5">
                                              {item?.To?.User?.firstName} {item?.To?.User?.lastName}
                                          </Typography>,
                                          <Typography variant="font5">{RequestStatus[item?.status]}</Typography>,
                                          <Typography variant="font5">{item?.reply ?? 'No Reply yet'}</Typography>,
                                          <Typography variant="font5">
                                              {dayjs(item?.createdAt).format('MM/DD/YYYY')}
                                          </Typography>,
                                      ],
                                  };
                              })
                    }
                />
                {!loading && requests.items.length === 0 && (
                    <Box className={classes.emptyTable}>
                        <Typography variant="font4">No Intro Requests made yet!</Typography>
                    </Box>
                )}
            </Card>
        </Container>
    );
}
