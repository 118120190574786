import { combineReducers } from 'redux';

// Import reducers here
import user from './user';
import snackbar from './snackbar';
import notifications from './notifications';
import subscriptionProducts from './subscriptionProducts';

const rootReducer = combineReducers({
    user,
    snackbar,
    notifications,
    subscriptionProducts,
});

export default rootReducer;
