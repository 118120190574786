import React, { useState } from 'react';
import { Alert, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import { DeleteForever } from '@mui/icons-material';
import NumericInput from 'components/inputs/NumericInput';
import validator from 'validator';
import Lottie from 'lottie-react';
import EmptyTable from 'assets/animations/empty-table2.json';

export default function InvestorPast({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            investments: user.ActiveProfile?.meta?.investments || [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'investments', // unique name for your Field Array
    });

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/investor/investments', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card
            title="Past Select Investments"
            actions={
                <Button
                    className={classes.actionButtonSm}
                    variant="contained"
                    disabled={fields.length === 4}
                    onClick={() => fields.length < 4 && append({ name: '', amount: '', website: '' })}
                >
                    Add
                </Button>
            }
        >
            <Alert severity="info" sx={{ mb: 2 }}>
                Investment amounts are not required information.
            </Alert>
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />
                {fields.length === 0 && (
                    <Box className={classes.empty}>
                        <Typography>No investments added. Click "Add" to add a new one!</Typography>
                        <Lottie loop={false} animationData={EmptyTable} className={classes.animation} />
                    </Box>
                )}

                {fields.map((field, i) => {
                    return (
                        <Box key={field.id} className={clsx(classes.fieldRow)}>
                            <Box className={clsx(classes.fieldTitle)} sx={{ mb: 1 }}>
                                <Typography variant="font1" fontWeight={'500'}>
                                    Investment {i + 1}
                                </Typography>
                                <DeleteForever className={classes.deleteBtn} onClick={() => remove(i)} />
                            </Box>
                            <Box className={classes.fieldBox}>
                                <TextField
                                    className={classes.field}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Name</InputAdornment>,
                                    }}
                                    fullWidth
                                    placeholder="Google"
                                    error={!!errors?.investments?.[i]?.name}
                                    helperText={errors?.investments?.[i]?.name?.message}
                                    {...register(`investments.${i}.name`, { required: 'Please fill out this value' })}
                                />
                                <NumericInput
                                    fullWidth
                                    className={classes.field}
                                    precision={0}
                                    placeholder="Investment Amount"
                                    decimalChar="."
                                    thousandChar=","
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    error={!!errors?.investments?.[i]?.amount}
                                    helperText={errors?.investments?.[i]?.amount?.message}
                                    {...register(`investments.${i}.amount`)}
                                />

                                <TextField
                                    className={classes.field}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">Startup website</InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    placeholder="https://example.com"
                                    error={!!errors?.investments?.[i]?.website}
                                    helperText={errors?.investments?.[i]?.website?.message}
                                    {...register(`investments.${i}.website`, {
                                        required: 'Field is required',
                                    })}
                                />
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    className={classes.actionButton}
                    onClick={() => handleContinue({ investments: [] })}
                >
                    Skip
                </LoadingButton>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
