import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function Dropdown({ buttonProps, title, close, children }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        close?.(handleClose);
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <Box>
            <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                {...buttonProps}
            >
                {title}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {children}
            </Popover>
        </Box>
    );
}
