import React, { useState } from 'react';
import clsx from 'clsx';
//MUI
import { Box, Button, Grid, Skeleton, Switch, Typography } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Hiking, Explore } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
// Styles
import { AppColors } from 'theme';
import useStyles from './styles';

export default function ProfileDrawerSkeleton(props) {
    const { onClose } = props;
    const classes = useStyles();
    const [introSwitch, setIntroSwitch] = useState(true);
    const [autoIntroSwitch, setAutoIntroSwitch] = useState(false);
    return (
        <Box className={classes.drawerContainer} sx={{ padding: 1 }}>
            <Box className={clsx(classes.section, classes.header)}>
                <Typography
                    variant="font4"
                    sx={{ color: AppColors.Gray1, fontWeight: 700 }}
                >
                    Profile
                </Typography>
                <Box className={classes.headerIcons}>
                    <Box className={classes.icon}>
                        <IosShareOutlinedIcon />
                    </Box>
                    <Box
                        onClick={onClose}
                        className={classes.icon}
                        sx={{ ml: 2 }}
                    >
                        <CloseOutlinedIcon />
                    </Box>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Box className={classes.profileInfo} sx={{ mb: 2 }}>
                    <Skeleton sx={{ width: '100%', mr: 1 }} />
                    <Box className={classes.membership}>
                        <Skeleton
                            sx={{
                                p: 0.2,
                                ml: 1,
                                mr: 1,
                            }}
                        />
                    </Box>
                </Box>
                <Box>
                    <Skeleton sx={{ mb: 1 }} />
                    <Box className={classes.userType} sx={{ mb: 1 }}>
                        <Hiking fontSize="10px" />
                        <Skeleton sx={{ width: '100%' }} />
                    </Box>
                    <Box className={classes.userType}>
                        <Explore fontSize="10px" />
                        <Skeleton sx={{ width: '100%' }} />
                    </Box>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography
                    variant="font4"
                    sx={{ color: AppColors.Gray1, fontWeight: 700 }}
                >
                    Engagement
                </Typography>
                {[1, 2, 3, 4, 5, 6].map((item, i) => {
                    return (
                        <Grid container spacing={1} key={`row-${i}`}>
                            <Grid item xs={6}>
                                <Skeleton />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton />
                            </Grid>
                        </Grid>
                    );
                })}
            </Box>
            <Box className={classes.section}>
                <Box className={classes.intros}>
                    <Typography
                        variant="font5"
                        sx={{
                            color: introSwitch
                                ? AppColors.Gray1
                                : 'rgba(128, 128, 128, 0.5)',
                            fontWeight: 700,
                        }}
                    >
                        Intro Requests
                    </Typography>
                    <Box className={classes.centerRow}>
                        <Typography
                            variant="font6"
                            sx={{
                                fontStyle: 'italic',
                                color: introSwitch
                                    ? AppColors.Gray1
                                    : 'rgba(128, 128, 128, 0.5)',
                            }}
                        >
                            {introSwitch ? 'Enabled' : 'Disabled'}
                        </Typography>
                        <Switch color="primary" defaultChecked />
                    </Box>
                </Box>

                <Skeleton />
                <Box className={classes.intros}>
                    <Typography
                        variant="font5"
                        sx={{
                            color: autoIntroSwitch
                                ? AppColors.Gray1
                                : 'rgba(128, 128, 128, 0.5)',
                            fontWeight: 700,
                        }}
                    >
                        Auto Intro Requests
                    </Typography>
                    <Box className={classes.centerRow}>
                        <Typography
                            variant="font6"
                            sx={{
                                fontStyle: 'italic',
                                color: autoIntroSwitch
                                    ? AppColors.Gray1
                                    : 'rgba(128, 128, 128, 0.5)',
                            }}
                        >
                            {autoIntroSwitch ? 'Enabled' : 'Disabled'}
                        </Typography>
                        <Switch color="primary" />
                    </Box>
                </Box>
                <Skeleton />
            </Box>
            <Box className={classes.section}>
                <Typography
                    variant="font5"
                    sx={{ color: AppColors.Gray1, fontWeight: 700 }}
                    mb={1}
                >
                    Recent connections
                </Typography>
                <Box className={classes.connectionList}>
                    <Typography>No Recent Connections</Typography>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography
                    variant="font4"
                    sx={{ color: AppColors.Gray1, fontWeight: 700 }}
                    mb={1}
                >
                    Contact Information
                </Typography>
                <Box className={classes.contactList}>
                    <EmailIcon />
                    <Box className={classes.contactInfo} sx={{ ml: 1 }}>
                        <Typography
                            variant="font6"
                            sx={{ color: AppColors.Gray1 }}
                        >
                            Email (Primary)
                        </Typography>
                        <Skeleton />
                    </Box>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography
                    variant="font4"
                    sx={{
                        color: AppColors.Gray1,
                        fontWeight: 700,
                        mb: 1,
                    }}
                >
                    User Settings
                </Typography>
                <Box className={classes.buttonLayout}>
                    <Button
                        sx={{ mb: 1 }}
                        // component="a"
                        variant="contained"
                        className={classes.buttonOne}
                    >
                        Pause Account
                    </Button>
                    <Button
                        sx={{ backgroundColor: AppColors.Red }}
                        // component="a"
                        variant="contained"
                        className={classes.buttonTwo}
                    >
                        Remove User
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
