import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    activeButton: {
        backgroundColor: 'rgba(233,92,12,0.1)',
        borderRadius: 12,
    },
}));

export default useStyles;
