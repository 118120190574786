import { makeStyles } from '@mui/styles';
import theme from 'theme';

export const HEADER_HEIGHT = 80;
export const HEADER_HEIGHT_MD = 72;

const useStyles = makeStyles(() => ({
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '8px 68px 8px 12px',
        gap: 8,

        '&:hover': {
            color: theme.palette.primary.main,

            '& > svg': {
                color: theme.palette.primary.main,
            },
        },
    },
    dropdownContent: {
        padding: '16px 0px',
    },
    profileDropdown: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    profilePicture: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        objectFit: 'cover',
    },
}));

export default useStyles;
