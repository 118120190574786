import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            '& > h6': {
                marginRight: theme.spacing(3),
            },
        },
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    filterActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 8,

            '& > :first-child': {
                minWidth: 247,
            },
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
        flexWrap: 'wrap',
    },

    searchField: {
        fontSize: 12,
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: 4,
        '& input::placeholder': {
            fontSize: 12,
        },
        '& input': {
            borderRadius: 4,
            fontSize: 12,
        },
    },
}));

export default useStyles;
