import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
// MUI
import IntroRequestCard from './IntroRequestCard/IntroRequestCard';
import ViewsApi from 'integrations/api/routes/views';
import { Box, Container, Grid, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
// Styles
import useStyles from './styles';
import ProfileCard from 'components/Cards/ProfileCard/ProfileCard';
import UserProfileCard from 'components/Cards/UserProfileCard/UserCard';
import StripeWidget from 'components/Widgets/StripeWidget/StripeWidget';
import { cloneDeep } from 'lodash';

export default function Profile(props) {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [profile, setProfile] = useState(null);
    const [requests, setRequests] = useState({ items: [], count: 0 });
    const [loading, setLoading] = useState(true);
    const { user } = useSelector(({ user }) => ({ user }));

    const activeSubscription = user?.ActiveSubscription?.status === 'active' || (user?.requests?.intros || 0) !== 0;

    useEffect(() => {
        if (activeSubscription) loadData();
    }, []);

    async function loadData() {
        setLoading(true);
        await Promise.all([getProfile(), getRequests()]);
        setLoading(false);

        ViewsApi.post({ ViewableId: params.id, ViewableType: 'Profile' });
    }

    async function getRequests() {
        try {
            const response = await SeedscoutAPI.get(`/v1/requests?FromId=${user.ActiveProfile.id}&ToId=${params.id}`);
            setRequests(response.data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    async function getProfile() {
        try {
            const response = await SeedscoutAPI.get(`/v1/profiles/${params.id}`);
            setProfile(response.data.profile);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    async function handleRequest() {
        await getRequests();
        await getProfile();
    }

    async function handleEdit(updatedUser) {
        const _profile = cloneDeep(profile);
        const { ActiveProfile, ...User } = updatedUser;
        setProfile({
            ..._profile,
            ...ActiveProfile,
            User,
        });
    }

    if (!activeSubscription) {
        return (
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                <StripeWidget />
            </Container>
        );
    }

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Box className={classes.column}>
                            <Skeleton height={500} width="100%" variant="rectangular" />
                            <Skeleton height={226} width="100%" variant="rectangular" />
                            <Skeleton height={500} width="100%" variant="rectangular" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </Grid>
            </Container>
        );
    }

    console.log(user);

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Box className={classes.column}>
                        <UserProfileCard
                            onEdit={handleEdit}
                            user={profile?.User}
                            profile={profile}
                            bookmarkable={true}
                            editable={Boolean(user.isAdmin)}
                        />
                        <IntroRequestCard
                            profile={profile}
                            requests={requests}
                            onAutoIntro={handleRequest}
                            onRequest={handleRequest}
                        />
                        <ProfileCard
                            onEdit={handleEdit}
                            user={profile.User}
                            profile={profile}
                            editable={Boolean(user.isAdmin)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}></Grid>
            </Grid>
        </Container>
    );
}
