import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { FOUNDER_SECTORS } from 'utils/constants';
import NumericInput from 'components/inputs/NumericInput';

export default function UpdateInvestorDialog({ open, profile, onEdit, onClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            companyName: profile.meta.companyName || '',
            investorType: profile.meta.investorType || '',
            sectors: profile.meta.sectors || '',
            minCheckSize: profile.meta.minCheckSize || '',
            maxCheckSize: profile.meta.maxCheckSize || '',
            geographyPreferences: profile.meta.geographyPreferences || '',
            website: profile.meta.website || '',
            founderTraits: profile.meta.founderTraits || '',
        },
    });

    async function handleUpdate(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch(`/v1/users/${profile.UserId}`, values);
            onEdit(response.data);
            onClose?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Box className={classes.dialogTitle} id="alert-dialog-title">
                <Typography variant="font1">Edit Profile</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleUpdate)} mt={1}>
                    <input style={{ display: 'none' }} type="submit" />
                    <Box className={classes.fieldContainer}>
                        <Typography>What is your firm/brand name? If you have one.</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="Investing Inc."
                            error={!!errors.companyName}
                            helperText={errors?.companyName?.message}
                            {...register('companyName', {})}
                        />
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>Do you hae website? Add it below!</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="https://example.com"
                            error={!!errors.website}
                            helperText={errors?.website?.message}
                            {...register('website', {})}
                        />
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>What type of investor are you?</Typography>
                        <Select
                            className={classes.field}
                            displayEmpty
                            fullWidth
                            error={Boolean(errors.investorType)}
                            defaultValue={profile.meta.investorType || ''}
                            {...register('investorType', {})}
                        >
                            <MenuItem value="">Select a value</MenuItem>
                            <MenuItem value="Angel Investor">Angel Investor</MenuItem>
                            <MenuItem value="Venture Capitalist">Venture Capitalist</MenuItem>
                            <MenuItem value="Limited Partner">Limited Partner</MenuItem>
                            <MenuItem value="Syndicate Lead">Syndicate Lead</MenuItem>
                        </Select>
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>What sectors do you like investing in?</Typography>
                        <Select
                            className={classes.field}
                            displayEmpty
                            fullWidth
                            error={Boolean(errors.sectors)}
                            multiple
                            defaultValue={profile.meta.sectors || []}
                            {...register('sectors', {})}
                        >
                            {FOUNDER_SECTORS.map((sector) => {
                                return (
                                    <MenuItem key={sector} value={sector}>
                                        {sector}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>What is your checksize?</Typography>
                        <NumericInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">Minimum</InputAdornment>,
                            }}
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`minCheckSize`]}
                            helperText={errors?.[`minCheckSize`]?.message}
                            {...register(`minCheckSize`, {})}
                            sx={{ mb: 1 }}
                        />
                        <NumericInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: <InputAdornment position="start">Maximum</InputAdornment>,
                            }}
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`maxCheckSize`]}
                            helperText={errors?.[`maxCheckSize`]?.message}
                            {...register(`maxCheckSize`, {})}
                        />
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>Where do you like to invest?</Typography>
                        <Select
                            className={classes.field}
                            displayEmpty
                            fullWidth
                            defaultValue={profile.meta.geographyPreferences || ''}
                            error={Boolean(errors.geographyPreferences)}
                            {...register('geographyPreferences', {})}
                        >
                            <MenuItem value="">Select a value</MenuItem>
                            <MenuItem value="Within my city">Within my city</MenuItem>
                            <MenuItem value="Across my state">Across my state</MenuItem>
                            <MenuItem value="Across the country">Across the country</MenuItem>
                            <MenuItem value="Across the world">Across the world</MenuItem>
                        </Select>
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>What qualities do you look for in a founder?</Typography>
                        <TextField
                            multiline
                            rows={2}
                            className={classes.field}
                            fullWidth
                            error={!!errors.founderTraits}
                            helperText={errors?.founderTraits?.message}
                            {...register('founderTraits', {})}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <Box className={classes.actionContainer}>
                <LoadingButton variant="contained" loading={submitting} onClick={handleSubmit(handleUpdate)}>
                    Update
                </LoadingButton>
            </Box>
        </Dialog>
    );
}
