import { AutoAwesome, Construction, MyLocation } from '@mui/icons-material';

const SeekerFilters = [
    { label: 'City', field: 'city', type: 'string', Icon: MyLocation },
    {
        label: 'Team Role',
        field: 'talentType',
        type: 'string',
        Icon: AutoAwesome,
    },
    {
        label: 'Skill Set',
        field: 'stack',
        type: 'string',
        Icon: Construction,
    },
];

export default SeekerFilters;
