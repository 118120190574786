import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    paper: {
        backgroundImage: 'none',
    },
    messageTemplate: {},
    customMessage: {
        display: 'inline-block',
        fontStyle: 'italic',
        textIndent: '20%',
        fontWeight: 700,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionLink: {
        fontStyle: 'italic',
    },
    textField: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialogTitle: {
        borderBottom: '1px solid white',
        marginBottom: 2,
    },
}));

export default useStyles;
