import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    rankingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid',
        borderRadius: '5px',
        cursor: 'pointer',
    },
}));

export default useStyles;
