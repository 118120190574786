import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    subscription: {
        textAlign: 'left',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        rowGap: theme.spacing(4),

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'auto auto',
        },
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        flexWrap: 'wrap',
    },
}));

export default useStyles;
