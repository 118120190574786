import React from 'react';
import useStyles from './styles';
import { Box, Typography } from '@mui/material';
import { Link } from '@mui/icons-material';

export default function LinkButton({ link, linkText, ...props }) {
    const classes = useStyles();
    if (!link) return null;
    link = link.toLowerCase();

    link = link?.includes?.('https://') || link?.includes?.('http://') ? link : `https://${link}`;

    return (
        <Box component="a" href={link} target={'_blank'} className={classes.root} {...props}>
            <Link />
            <Typography variant="font4" fontWeight={'400'}>
                {linkText ? linkText : link}
            </Typography>
        </Box>
    );
}
