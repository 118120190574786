import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Layer } from 'recharts';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
//MUI
import { Typography, Box, Grid } from '@mui/material';
// Styles
import useStyles from './styles';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';

export default function IntrosTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [introsData, setIntrosData] = useState([]);

    useEffect(() => {
        getIntroData();
    }, []);

    async function getIntroData() {
        try {
            const response = await SeedscoutAPI.get(`/v1/admin/stats/intros`);
            const { results } = response.data;

            setIntrosData(
                results.map((data) => {
                    const introsIgnored = data.introsRequested - (data.introsAccepted + data.introsRejected);
                    return { ...data, introsIgnored };
                }),
            );
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    const calcPercentageChange = (current, past) => {
        if (past === 0) {
            return {
                change: '+100.00',
                color: 'green',
            };
        }
        const change = (current / past) * 100 - 100;
        return {
            change: `${change > 0 ? '+' : ''}${change.toFixed(2)}`,
            color: change >= 0 ? 'green' : 'red',
        };
    };

    const CustomLegend = ({ color }) => {
        return <Box style={{ color }} sx={{ mb: 1 }} />;
    };

    const dataSummary = [
        {
            title: 'Auto Intros Sent',
            currentMonth: introsData[0]?.autoIntros,
            pastMonth: introsData[1]?.autoIntros,
        },
        {
            title: 'Intros Requested',
            currentMonth: introsData[0]?.introsRequested,
            pastMonth: introsData[1]?.introsRequested,
        },
        {
            title: 'Intros Accepted',
            currentMonth: introsData[0]?.introsAccepted,
            pastMonth: introsData[1]?.introsAccepted,
        },
        {
            title: 'Intros Rejected',
            currentMonth: introsData[0]?.introsRejected,
            pastMonth: introsData[1]?.introsRejected,
        },
        {
            title: 'Intros Ignored',
            currentMonth: introsData[0]?.introsIgnored,
            pastMonth: introsData[1]?.introsIgnored,
        },
    ];

    const CustomTooltip = ({ active, payload }) => {
        const labels = ['Auto Intros', 'Intros Requested', 'Intros Accepted', 'Intros Rejected', 'Intros Ignored'];
        if (active && payload && payload.length) {
            return (
                <Box className={classes.tooltip}>
                    {labels.map((label, i) => (
                        <Typography variant="font6" color={payload[i].color} key={`label - ${i}`}>
                            {`${label} : ${payload[i].value}`}
                        </Typography>
                    ))}
                </Box>
            );
        }
        return null;
    };

    return (
        <Card cardOnly>
            <Grid container spacing={2} className={classes.chartContainer}>
                <Grid item sm={3} xs={12} className={classes.chartInfo}>
                    <Box>
                        <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
                            Introductions
                        </Typography>
                    </Box>
                    {dataSummary.map((data, i) => {
                        return (
                            <Box key={`dataPoint-${i}`} className={classes.dataText} sx={{ mb: 2.1 }}>
                                <Typography variant="font5" color={'Gray1.main'} sx={{ fontWeight: 700 }}>
                                    {data.title}
                                </Typography>
                                <Box className={classes.introCount}>
                                    <Typography variant="font5" color={'primary.main'} sx={{ fontWeight: 700 }}>
                                        {data.currentMonth}
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="font6"
                                    color={calcPercentageChange(data.currentMonth, data.pastMonth).color}
                                    className={classes.percentageText}
                                >
                                    {calcPercentageChange(data.currentMonth, data.pastMonth).change}%
                                </Typography>
                            </Box>
                        );
                    })}
                </Grid>
                <Grid item sm={9} xs={12} className={classes.chartLayout}>
                    <ResponsiveContainer width="100%" height={220}>
                        <LineChart
                            // width={500}
                            // height={250}
                            data={introsData}
                            margin={{
                                top: 5,
                                right: 20,
                                left: 20,
                                bottom: 20,
                            }}
                        >
                            <CartesianGrid strokeDasharray="4 4" />
                            {/* <CartesianGrid horizontal={false} /> */}
                            <XAxis
                                dataKey="month"
                                orientation="top"
                                axisLine={false}
                                tickLine={false}
                                padding={{ left: 30, right: 30 }}
                                tickMargin={5}
                                reversed={true}
                            />
                            <YAxis axisLine={false} tickLine={false} tickMargin={5} className={classes.YAxis} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend layout="vertical" align="left" iconSize={20} formatter={CustomLegend} />
                            <Line type="monotone" dataKey="autoIntros" stroke="#95CDE7" />
                            <Line type="monotone" dataKey="introsRequested" stroke="#219EBC" />
                            <Line type="monotone" dataKey="introsAccepted" stroke="#123D52" />
                            <Line type="monotone" dataKey="introsRejected" stroke="#FFBB14" />
                            <Line type="monotone" dataKey="introsIgnored" stroke="#FB8C0F" />
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Card>
    );
}
