import React, { useState } from 'react';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import { FOUNDER_SECTORS } from 'utils/constants';

export default function InvestorDetails({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));

    const {
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            companyName: user.ActiveProfile?.meta?.companyName || '',
            website: user.ActiveProfile?.meta?.website || '',
            investorType: user.ActiveProfile?.meta?.investorType || '',
            sectors: user.ActiveProfile?.meta?.sectors || [],
        },
    });

    const sector = watch('sector');

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const { file, ...rest } = values;
            let body = rest;
            if (file) {
                body = new FormData();
                body.append('file', file);
                body.append('oneLiner', values.oneLiner);
                body.append('companyName', values.companyName);
                body.append('startupURL', values.startupURL);
            }

            const response = await SeedscoutAPI.patch('/v1/signup/investor/initial', body);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Setup your Investor Profile" subtitle="Add your logo if you have one!">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />

                <Box className={classes.fieldContainer}>
                    <Typography>What is your firm/brand name? If you have one.</Typography>
                    <TextField
                        className={classes.field}
                        fullWidth
                        placeholder="Investing Inc."
                        error={!!errors.companyName}
                        helperText={errors?.companyName?.message}
                        {...register('companyName', {})}
                    />
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>Do you have a website? If so add it below!</Typography>
                    <TextField
                        className={classes.field}
                        fullWidth
                        placeholder="https://example.com"
                        error={!!errors.website}
                        helperText={errors?.website?.message}
                        {...register('website', {})}
                    />
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>What type of Investor are you?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.investorType)}
                        defaultValue={user.ActiveProfile?.meta?.investorType || ''}
                        {...register('investorType', {
                            required: 'Required Field',
                        })}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Angel Investor">Angel Investor</MenuItem>
                        <MenuItem value="Venture Capitalist">Venture Capitalist</MenuItem>
                        <MenuItem value="Limited Partner">Limited Partner</MenuItem>
                        <MenuItem value="Syndicate Lead">Syndicate Lead</MenuItem>
                    </Select>
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>What sectors do you like investing in?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.sectors)}
                        multiple
                        defaultValue={user.ActiveProfile?.meta?.sectors || []}
                        {...register('sectors', {
                            required: 'Required Field',
                        })}
                    >
                        {FOUNDER_SECTORS.map((sector) => {
                            return (
                                <MenuItem key={sector} value={sector}>
                                    {sector}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
