import React, { useState } from 'react';
//mods
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
//comps
import { Box, Button, Checkbox, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Lottie
import Lottie from 'lottie-react';
import SuccessAnimation from 'assets/animations/party-success.json';
//styles
import Google from 'assets/images/logos/google.png';
import useStyles from './styles';
import SeedscoutAPI from 'integrations/api/seedscout_api';

export default function Login() {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState(0);
    const [remember, setRemember] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function handleLogin(data) {
        setSubmitting(true);
        try {
            const email = data.email.toLowerCase();
            await SeedscoutAPI.get(`/v1/authentication/${email}?to=portal`);

            setState(1);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    if (state === 1) {
        return (
            <Box className={classes.linkContent}>
                <Lottie loop={false} animationData={SuccessAnimation} className={classes.animation} />
                <Typography sx={{ marginTop: 4, fontWeight: '600', mb: 1 }} variant="h3">
                    Log in link sent!
                </Typography>
                <Typography sx={{ textAlign: 'center', mb: 1 }} variant="font3">
                    An email containing a link was sent to your email
                </Typography>
                <Typography sx={{ textAlign: 'center', mb: 4 }} variant="font4">
                    Don't forget to check spam!
                </Typography>
                <LoadingButton loading={submitting} onClick={handleSubmit(handleLogin)}>
                    Didn't receive a link? Click here to resend.
                </LoadingButton>
            </Box>
        );
    }

    return (
        <Box className={classes.actionContent} component="form" onSubmit={handleSubmit(handleLogin)}>
            <Typography variant="h4" fontWeight={'600'} mb={1}>
                Welcome to Seedscout
            </Typography>
            <Typography variant="font3" color="lightText.main" mb={3}>
                Your business network!
            </Typography>
            <Tooltip title="Coming Soon" placement="top" arrow>
                <span style={{ width: '100%' }}>
                    <Button
                        fullWidth
                        disabled={true}
                        variant="contained"
                        color="lightButton"
                        startIcon={<img src={Google} style={{ height: 15 }} />}
                        sx={{ mb: 4 }}
                    >
                        Sign in with Google
                    </Button>
                </span>
            </Tooltip>
            <Box className={classes.orBorder} sx={{ mb: 4 }}>
                <Typography variant="font3">or sign in with</Typography>
            </Box>
            <Box className={classes.fieldSet} sx={{ marginBottom: 2 }}>
                <Typography variant="font3" mb={1.2} fontWeight={'500'}>
                    Username / Email Address
                </Typography>
                <TextField
                    className={classes.field}
                    size="small"
                    fullWidth
                    placeholder="user@example.com"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', {
                        required: 'Invalid email address',
                        validate: (value) => validator.isEmail(value),
                    })}
                />
            </Box>
            <FormControlLabel
                control={<Checkbox checked={remember} onChange={(e) => setRemember(e.target.checked)} />}
                label={
                    <Typography variant="font3" color="darkText.main" fontWeight={'400'} sx={{ my: 2 }}>
                        Remember this device
                    </Typography>
                }
            />
            <input type="submit" style={{ display: 'none' }} />

            <LoadingButton fullWidth variant="contained" loading={submitting} onClick={handleSubmit(handleLogin)}>
                Sign In
            </LoadingButton>
            <Box className={classes.row} sx={{ mt: 4 }}>
                <Typography variant="font3">New to Seedscout? </Typography>
                <Typography
                    variant="font3"
                    color="primary"
                    sx={{ cursor: 'pointer', mt: 0.2 }}
                    loading={submitting}
                    component={Link}
                    className="link"
                    to="/signup"
                >
                    Create an account
                </Typography>
            </Box>
        </Box>
    );
}
