import React from 'react';
import { Box, Skeleton } from '@mui/material';
// Styles
import useStyles from './styles';

export default function StatSectionSkeleton() {
    const classes = useStyles();
    return (
        <>
            {[1, 2, 3, 4].map((box, i) => {
                return (
                    <Box
                        className={classes.rankingContainer}
                        sx={{ padding: 1 }}
                        key={`box-${i}`}
                    >
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Box>
                );
            })}
        </>
    );
}
