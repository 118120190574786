import React, { useState } from 'react';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import { JOB_SEEKER_CATEGORIES, JOB_SEEKER_STACKS, JOB_SEEKER_WORKFORCE } from 'utils/constants';

export default function JobSeekerDetails({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));

    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            talentType: user.ActiveProfile?.meta?.talentType || '',
            timeInWorkforce: user.ActiveProfile?.meta?.timeInWorkforce || '',
            website: user.ActiveProfile?.meta?.website || '',
            coverLetter: user.ActiveProfile?.meta?.coverLetter || '',
            stacks: Array.isArray(user.ActiveProfile?.meta?.stacks) ? user.ActiveProfile?.meta?.stacks : [],
        },
    });

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const { file, ...rest } = values;
            let body = rest;
            if (file) {
                body = new FormData();
                body.append('file', file);
                body.append('oneLiner', values.oneLiner);
                body.append('companyName', values.companyName);
                body.append('startupURL', values.startupURL);
            }

            const response = await SeedscoutAPI.patch('/v1/signup/seeker/initial', body);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Setup your Investor Profile" subtitle="Add your logo if you have one!">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />

                <Box className={classes.fieldContainer}>
                    <Typography>What role do I play on a team?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.talentType)}
                        defaultValue={user.ActiveProfile?.meta?.talentType || ''}
                        {...register('talentType', {
                            required: 'Required Field',
                        })}
                    >
                        <MenuItem value="">Select a value</MenuItem>

                        {JOB_SEEKER_CATEGORIES.map((category) => {
                            return (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>How long in workforce?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.timeInWorkforce)}
                        defaultValue={user.ActiveProfile?.meta?.timeInWorkforce || ''}
                        {...register('timeInWorkforce', {
                            required: 'Required Field',
                        })}
                    >
                        <MenuItem value="">Select a value</MenuItem>

                        {JOB_SEEKER_WORKFORCE.map((value) => {
                            return (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>Where can people find your work online?</Typography>
                    <TextField
                        className={classes.field}
                        fullWidth
                        placeholder="https://example.com"
                        error={!!errors.website}
                        helperText={errors?.website?.message}
                        {...register('website', {})}
                    />
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>
                        Help startups on the platform understand the stacks you use to do your job. The more you can
                        share here, the better. What products are you using on a day to day basis when working
                    </Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.stacks)}
                        multiple
                        defaultValue={
                            Array.isArray(user.ActiveProfile?.meta?.stacks) ? user.ActiveProfile?.meta?.stacks : []
                        }
                        {...register('stacks', {
                            required: 'Required Field',
                        })}
                    >
                        {JOB_SEEKER_STACKS.map((stack) => {
                            return (
                                <MenuItem key={stack} value={stack}>
                                    {stack}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>Write a 280 character cover letter.</Typography>
                    <TextField
                        className={classes.field}
                        fullWidth
                        rows={4}
                        multiline
                        placeholder="I am...."
                        error={!!errors.coverLetter}
                        helperText={errors?.coverLetter?.message}
                        {...register('coverLetter', {})}
                    />
                </Box>
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
