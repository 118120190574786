import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI
import {
    IconButton,
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Badge,
    Button,
} from '@mui/material';
import { CircleNotifications } from '@mui/icons-material';
import Dropdown from 'components/DropdownV2/Dropdown';
import { NotificationIcon } from './NotificationIcon';
// styles
import useStyles from './styles';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import NotificationsRedux from 'redux/actions/notifications';
import dayjs from 'dayjs';

export default function NotificationsList() {
    const classes = useStyles();
    const { notifications } = useSelector(({ notifications }) => ({ notifications }));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function handleAction(notification) {
        dispatch(NotificationsRedux.read(notification));

        if (notification.type === 'Payment Success') {
            // navigate('/');
        } else if (notification.type === 'New Listings') {
            navigate('/listings');
        }
    }

    return (
        <Dropdown
            title={
                <IconButton>
                    <Badge
                        invisible={notifications.unread === 0 || !notifications.unread}
                        badgeContent={notifications.unread}
                        color="primary"
                    >
                        <CircleNotifications color="foregroundText" sx={{ fontSize: 34 }} />
                    </Badge>
                </IconButton>
            }
        >
            <Box className={classes.notificationBox}>
                <Box className={classes.notificationHeader}>
                    <Typography>Notifications</Typography>
                    <Link to="/notifications">
                        <Button color="secondary">View All</Button>
                    </Link>
                </Box>
                {notifications.items.length === 0 && (
                    <Box sx={{ padding: 2 }}>
                        <Typography variant="font3" sx={{ fontWeight: '200' }}>
                            You have no notifications.
                        </Typography>
                    </Box>
                )}
                {notifications.items.length > 0 ? (
                    <List className={classes.notificationList}>
                        {notifications.items.map((notification, i) => {
                            return (
                                <ListItem
                                    className={clsx({
                                        [classes.unread]: !notification.readAt,
                                    })}
                                    button
                                    onClick={() => handleAction(notification)}
                                    key={`noti-${i}`}
                                >
                                    <ListItemIcon>
                                        <NotificationIcon type={notification.type} />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ maxWidth: '250px' }}
                                        primary={`${notification.meta.title}`}
                                        secondary={notification.meta.body}
                                        classes={{
                                            primary: classes.notificationPrimary,
                                            secondary: classes.notificationSecondary,
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <Typography variant="font5" sx={{ fontWeight: '200' }}>
                                            {dayjs(notification.createdAt).format('MMM DD')}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : null}
            </Box>
        </Dropdown>
    );
}
