import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        // boxShadow: '0 3px 20px #1d26260d',
        border: `1px solid #e4e9f1`,
        backgroundColor: 'white',
        padding: 24,
        borderRadius: theme.custom.cardBorderRadius,
        boxSizing: 'border-box',
    },
    cardTitle: {
        paddingBottom: 12,
        borderBottom: '1px solid #E4E4E4',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 4,
        },
    },
    cardTitleChildren: {
        paddingBottom: 12,
        borderBottom: '1px solid #E4E4E4',
    },
    cardContent: {},
    contentGutter: {
        marginTop: theme.spacing(2),
    },

    noStyle: {
        borderRadius: 0,
        boxShadow: 'unset',
    },
}));

export default useStyles;
