import React, { useEffect, useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Pages
import Account from './Account';
import Introductions from './Introductions';
import Investors from './Investors';
import SubscriptionSuccess from 'containers/Other/SubscriptionSuccess';
import Navigation from 'components/Navigation/Navigation';
import Founders from './Founders/Founders';
import Profile from './Profile/Profile';
import JobSeekers from './JobSeekers/JobSeekers';
import AccountSettings from './AccountSettings/AccountSettings';
import FinishSignup from './FinishSignup/FinishSignup';
import Notifications from './Notifications/Notifications';
import Admin from './Admins/Admin';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsRedux from 'redux/actions/notifications';
import Bookmarks from './Bookmarks/Bookmarks';

const AuthStack = (props) => {
    const { user } = useSelector(({ user }) => ({ user }));
    const networking = useRef({});
    const dispatch = useDispatch();

    useEffect(() => {
        async function getNotifications() {
            if (networking.current.notis) return;
            networking.current.notis = true;
            try {
                await dispatch(NotificationsRedux.get());
            } catch (error) {
                console.log(error);
                // dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
            }
            networking.current.notis = false;
        }

        if (user) getNotifications();
    }, [user]);

    if (!user.ActiveProfile || user?.ActiveProfile?.meta?.signup !== 'Finished') {
        return (
            <Routes>
                <Route path="finish-signup" element={<FinishSignup />} />
                <Route path="*" element={<Navigate to="/finish-signup" replace />} />
            </Routes>
        );
    }

    return (
        <Navigation>
            <Routes>
                {user?.permissions?.admin && <Route path="admin" element={<Admin />} />}
                <Route path="" element={<Account />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="founders" element={<Founders />} />
                <Route path="founder/:id" element={<Profile />} />
                <Route path="investors" element={<Investors />} />
                <Route path="investor/:id" element={<Profile />} />
                <Route path="introductions" element={<Introductions />} />
                <Route path="bookmarks" element={<Bookmarks />} />
                <Route path="sub-success" element={<SubscriptionSuccess />} />
                <Route path="seekers" element={<JobSeekers />} />
                <Route path="seeker/:id" element={<Profile />} />
                <Route path="settings" element={<AccountSettings />} />
                {/* End Routes */}

                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Navigation>
    );
};

export default AuthStack;
