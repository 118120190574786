import { Box, useTheme } from '@mui/material';
import { CreditCard, Done, Home } from '@mui/icons-material';
import useStyles from './styles';

const NOTIFICATION_TYPE = {
    'Payment Success': { icon: CreditCard, color: (theme) => theme.palette.success.main },
    'New Listings': { icon: Home, color: (theme) => theme.palette.info.main },
};

export function NotificationIcon({ type, ...props }) {
    const classes = useStyles();
    const theme = useTheme();

    const Icon = NOTIFICATION_TYPE?.[type]?.icon ?? Done;
    const color = NOTIFICATION_TYPE?.[type]?.color ?? theme.palette.primary.main;

    return (
        <Box className={classes.notificationIconContainer} sx={{ backgroundColor: color }}>
            <Icon style={{ fill: color }} />
        </Box>
    );
}
