import * as React from 'react';
// Modules
import { Link, useLocation } from 'react-router-dom';
// UI
import { Box, Typography, Fade, Collapse } from '@mui/material';
// images
import useStyles from './styles';
import clsx from 'clsx';

export default function MobileNavItem({ item, showTitle }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const location = useLocation();

    if (item.type === 'text') {
        return !showTitle ? (
            <Box className={classes.navDot} />
        ) : (
            <Box component={Fade} in className={classes.navTitle}>
                <Typography variant="font2">{item.title}</Typography>
            </Box>
        );
    }

    if (item.type === 'link') {
        const isPath = location.pathname === item.pathname;
        return (
            <Box
                component={Link}
                to={item.pathname}
                className={clsx(classes.navItem, {
                    [classes.activeNavItem]: isPath,
                })}
            >
                <Box className={classes.navItemIcon}>
                    <item.Icon />
                </Box>
                {showTitle && (
                    <Fade in>
                        <Typography variant="font3">{item.name}</Typography>
                    </Fade>
                )}
            </Box>
        );
    }

    if (item.type === 'dropdown') {
        const isPath = location.pathname.includes(item.pathname);
        return (
            <Box className={clsx(classes.dropDown)}>
                <Box
                    onClick={() => setOpen(!open)}
                    className={clsx(classes.navItem, {
                        [classes.activeNavItem]: isPath,
                    })}
                >
                    <Box className={classes.navItemIcon}>
                        <item.Icon />
                    </Box>
                    {showTitle && (
                        <Fade in>
                            <Typography variant="font2">{item.title}</Typography>
                        </Fade>
                    )}
                </Box>
                <Collapse in={showTitle && open}>
                    <Box className={classes.innerList}>
                        {item.items.map((innerItem, i) => {
                            const isPath = location.pathname.includes(innerItem.pathname);
                            return (
                                <Box
                                    component={Link}
                                    key={i}
                                    to={innerItem.pathname}
                                    className={clsx(classes.innerListItem, {
                                        [classes.activeInnerItem]: isPath,
                                    })}
                                >
                                    <Typography variant="font3">{innerItem.title}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Collapse>
            </Box>
        );
    }

    return null;
}
