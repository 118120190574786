import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, Typography } from '@mui/material';
import useStyles from './styles';
import { setSnackbar } from 'redux/actions/snackbar';
import { LoadingButton } from '@mui/lab';
import checkError from 'utils/check-error';
import dayjs from 'dayjs';
import wait from 'utils/wait';
import { useConfirm } from 'material-ui-confirm';
import UserRedux from 'redux/actions/user';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';
import SubscriptionProductsRedux from 'redux/actions/subscriptionProducts';

export default function StripeWidget(props) {
    const { user, subscriptionProducts } = useSelector(({ user, subscriptionProducts }) => ({
        user,
        subscriptionProducts,
    }));
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const userProduct = user?.ActiveSubscription?.product;
    const confirm = useConfirm();

    useEffect(() => {
        dispatch(SubscriptionProductsRedux.get());
    }, []);

    async function handleSubscribe(price) {
        setLoading(true);
        try {
            const response = await SeedscoutAPI.get(`/v1/stripe/session/${price.id}`);
            window.location.replace(response.data.session.url);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    async function handleCancel() {
        setLoading(true);
        try {
            await SeedscoutAPI.delete(`/v1/stripe/subscription`);

            await wait(5);

            await dispatch(UserRedux.getCurrentUser());
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    async function handleDashboard() {
        setLoading(true);
        try {
            const response = await SeedscoutAPI.get(`/v1/stripe/portal`);
            window.open(response.data.session.url);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    async function handleUpdate(newPrice) {
        const currentPlan = userProduct.default_price;
        if (newPrice.unit_amount > currentPlan.unit_amount) {
            confirm({
                description:
                    'You will be charged immediately when upgrading a plan and your new credits will also be applied immediately',
            })
                .then(async () => _handleUpdate(newPrice))
                .catch(() => {});
        } else await _handleUpdate(newPrice);
    }

    async function _handleUpdate(newPrice) {
        setUpdating(true);
        try {
            await SeedscoutAPI.patch(`/v1/stripe/update/${newPrice.id}`);

            dispatch(
                setSnackbar({
                    severity: 'success',
                    message: 'Subscription update was successful, please wait while we update our system.',
                }),
            );

            await wait(5);

            await dispatch(UserRedux.getCurrentUser());
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setUpdating(false);
    }

    async function handleRenew() {
        setLoading(true);
        try {
            await SeedscoutAPI.patch(`/v1/stripe/renew`);

            await wait(5);

            await dispatch(UserRedux.getCurrentUser());
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    function renderSubscriptionless() {
        return (
            <Card cardOnly>
                {(user?.requests?.intros || 0) !== 0 && (
                    <Alert severity="info" sx={{ marginBottom: 3 }}>
                        <Typography>
                            You have been gifted {user.requests.intros} intro request credits by the Seedscout Team!
                            Test away!
                        </Typography>
                    </Alert>
                )}
                <Typography variant="h6">Want to Request Intros? Upgrade Today.</Typography>
                <Typography variant="font5" sx={{ marginBottom: 3 }}>
                    In order to request intros to others on Seedscout, you must be a paying user. Upgrade Below
                </Typography>
                {subscriptionProducts?.data?.map?.((product) => {
                    return (
                        <Typography sx={{ marginBottom: 1 }} variant="font2" key={product.id}>
                            <span className="bold">
                                {product.name} (${product?.default_price?.unit_amount / 100}):{' '}
                            </span>
                            {product?.metadata?.description}
                        </Typography>
                    );
                })}

                <Box sx={{ marginTop: 6 }} className={classes.actions}>
                    {subscriptionProducts?.data?.map?.((product) => {
                        return (
                            <LoadingButton
                                key={`${product.id}-b`}
                                loading={loading}
                                onClick={() => handleSubscribe(product.default_price)}
                                variant="contained"
                            >
                                {product.name}
                            </LoadingButton>
                        );
                    })}
                </Box>
            </Card>
        );
    }

    function renderSubscription() {
        return (
            <>
                <Card title="Subscription Information">
                    {user?.ActiveSubscription?.paymentStatus === 'failed' && (
                        <Alert sx={{ marginBottom: 3, cursor: 'pointer' }} severity="error" onClick={handleDashboard}>
                            Payment failed, please visit the Stripe Dashboard to update your payment method.
                        </Alert>
                    )}
                    <Box className={classes.grid}>
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                Subscription Name
                            </Typography>
                            <Typography variant="font5">{user?.ActiveSubscription?.product?.name}</Typography>
                        </Box>
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                Credits Available
                            </Typography>
                            <Typography variant="font5">{user.requests?.intros || 0}</Typography>
                        </Box>
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                Credits Per Month
                            </Typography>
                            <Typography variant="font5">
                                {user?.ActiveSubscription?.product?.metadata?.requests}
                            </Typography>
                        </Box>
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                Auto Intros Available
                            </Typography>
                            <Typography variant="font5">{user.requests?.autoIntros || 0}</Typography>
                        </Box>
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                Auto Intros Per Month
                            </Typography>
                            <Typography variant="font5">
                                {user.ActiveSubscription?.product?.metadata?.autoIntros}
                            </Typography>
                        </Box>
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                {user.ActiveSubscription?.metadata?.cancel_at_period_end
                                    ? 'Ends on'
                                    : 'Next Billing Date'}
                            </Typography>
                            <Typography variant="font5">
                                {dayjs(user.ActiveSubscription?.metadata?.current_period_end * 1000).format(
                                    'MM/DD/YYYY hh:mma',
                                )}
                            </Typography>
                        </Box>
                        {!user.ActiveSubscription?.metadata?.cancel_at_period_end && (
                            <Box className={classes.infoBox}>
                                <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                    Next Billing Cost
                                </Typography>
                                <Typography variant="font5">
                                    ${user.ActiveSubscription?.metadata?.cost / 100}
                                </Typography>
                            </Box>
                        )}
                        <Box className={classes.infoBox}>
                            <Typography variant="font6" color="Gray1.main" mb={0.5}>
                                Status
                            </Typography>
                            <Typography variant="font5">{user.ActiveSubscription.status}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: 6 }}>
                        <LoadingButton loading={loading} onClick={handleDashboard} variant="contained">
                            View payment methods
                        </LoadingButton>

                        {!user.ActiveSubscription?.metadata?.cancel_at_period_end && (
                            <LoadingButton
                                loading={loading}
                                onClick={handleCancel}
                                sx={{
                                    marginLeft: { xs: 0, md: 1 },
                                    marginTop: { xs: 1, md: 0 },
                                }}
                                variant="contained"
                            >
                                Cancel Your Plan
                            </LoadingButton>
                        )}
                        {user.ActiveSubscription?.metadata?.cancel_at_period_end && (
                            <LoadingButton
                                loading={loading}
                                onClick={handleRenew}
                                sx={{
                                    marginLeft: { xs: 0, md: 1 },
                                    marginTop: { xs: 1, md: 0 },
                                }}
                                variant="contained"
                            >
                                Renew plan
                            </LoadingButton>
                        )}
                        <Button
                            sx={{
                                marginLeft: { xs: 0, md: 1 },
                                marginTop: { xs: 1, md: 0 },
                                color: 'white',
                                '&:visited': {
                                    color: 'white',
                                },
                            }}
                            component="a"
                            href="https://calendly.com/d/nmk-nk7-v3t/book-seedscout-strategy-call?month=2023-01"
                            target="_blank"
                            variant="contained"
                            color="secondary"
                        >
                            Book a Strategy Call
                        </Button>
                    </Box>
                </Card>
                {!user.ActiveSubscription?.status?.cancel_at_period_end && (
                    <Card title="Upgrade your plan">
                        <Alert severity="info" sx={{ mb: 2 }}>
                            <Typography variant="font5">
                                <span className="bold">Note:</span> Upgrading plans will be reflected in the next months
                                billings and credits.
                            </Typography>
                        </Alert>
                        {subscriptionProducts?.data?.map?.((product) => {
                            return (
                                <Typography sx={{ marginBottom: 1 }} variant="font2" key={product.id}>
                                    <span className="bold">
                                        {product.name} (${product?.default_price?.unit_amount / 100}):{' '}
                                    </span>
                                    {product?.metadata?.description}
                                </Typography>
                            );
                        })}
                        {userProduct && (
                            <>
                                <Typography variant="font2" mt={3} mb={1}>
                                    Out of credits? Restart your current billing cycle to get more!
                                </Typography>
                                <LoadingButton
                                    loading={updating}
                                    onClick={() => handleUpdate(userProduct.default_price)}
                                    variant="contained"
                                >
                                    Restart Billing Cycle
                                </LoadingButton>

                                <Typography variant="font2" my={3}>
                                    Or Change Your Plan
                                </Typography>
                            </>
                        )}
                        <Box sx={{ marginTop: 3 }} className={classes.actions}>
                            {subscriptionProducts?.data?.map?.((product) => {
                                return (
                                    <LoadingButton
                                        key={`${product.id}-b`}
                                        loading={updating}
                                        onClick={() => handleUpdate(product.default_price)}
                                        variant="contained"
                                    >
                                        {product.name}
                                    </LoadingButton>
                                );
                            })}
                        </Box>
                    </Card>
                )}
            </>
        );
    }

    if (Boolean(user.ActiveSubscription)) {
        return renderSubscription();
    }
    return renderSubscriptionless();
}
