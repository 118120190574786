import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    list: {
        padding: 0,

        '& > li': {
            borderTop: '0.3px solid #e6e6e6',
        },
    },
    title: {
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid #E4E4E4`,
    },
    listItem: {
        paddingTop: 21,
        paddingBottom: 21,
    },
    listSecondaryAction: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    listItemIcon: {
        background: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
    },

    textPrimary: {
        fontSize: 18,
        fontWeight: '600',
    },
    textSecondary: {
        fontSize: 18,
        fontWeight: '200',
    },

    /** TAbs */

    tabLeft: {
        paddingLeft: 24,
        borderTopLeftRadius: 5,
    },
    tabRight: {
        borderTopRightRadius: 5,
        paddingRight: 24,
    },
    tabSelected: {
        fontWeight: '600',
    },
    tabContent: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
    },
}));

export default useStyles;
