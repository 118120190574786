export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const INIT = {
    message: null,
};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_SNACKBAR:
            return payload;
        case CLOSE_SNACKBAR:
            return { message: null };
        default:
            return state;
    }
}

export default reducer;
