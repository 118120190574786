import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI
import { Box, Container, Grid } from '@mui/material';
// Styles
import useStyles from './styles';
import UserProfileCard from 'components/Cards/UserProfileCard/UserCard';
import ProfileCard from 'components/Cards/ProfileCard/ProfileCard';
import ViewsCard from 'components/Cards/ViewsCard/ViewsCard';
import BookmarksCard from 'components/Cards/BookmarkCard/BookmarkCard';
import UserRedux from 'redux/actions/user';

export default function Account(props) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    async function handleEdit(updatedUser) {
        dispatch(UserRedux.setUser(updatedUser));
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Box className={classes.column}>
                        <UserProfileCard onEdit={handleEdit} user={user} profile={user.ActiveProfile} />
                        <ProfileCard onEdit={handleEdit} user={user} profile={user.ActiveProfile} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box className={classes.column}>
                        <ViewsCard />
                        <BookmarksCard />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
