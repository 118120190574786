import { AutoAwesome, Business, MyLocation, QuestionAnswer, Send, AutoFixHigh, Code } from '@mui/icons-material';

const FounderFilters = [
    {
        label: 'Auto Intro Enabled',
        field: 'autoIntros',
        type: 'boolean',
        Icon: AutoFixHigh,
    },
    {
        label: 'Startup Name',
        field: 'companyName',
        type: 'string',
        Icon: Business,
    },
    {
        label: 'Sector',
        field: 'startupSector',
        type: 'string',
        Icon: AutoAwesome,
    },
    { label: 'City', field: 'city', type: 'string', Icon: MyLocation },
    {
        label: '>= # of Intro Requests Received',
        field: 'introsReceived',
        type: 'number',
        Icon: Send,
    },
    {
        label: '>= # of Requests Engaged With',
        field: 'introsTaken',
        type: 'number',
        Icon: QuestionAnswer,
    },
];

export default FounderFilters;
