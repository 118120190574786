import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: 'calc(100% - 24px)',
        },
    },
    dialogTitle: {
        padding: '20px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    actionContainer: {
        padding: '14px 24px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 12,
        borderTop: `1px solid ${theme.palette.border.main}`,
    },
    fields: {
        '& > *:not(:last-child)': {
            marginBottom: 12,
        },
    },
    fieldRow: {
        display: 'flex',
        flexDirection: 'column',
    },
    fieldTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 9,
    },
    fieldBox: {
        display: 'flex',
        flexWrap: 'wrap',

        '& > :nth-child(1)': {
            flexBasis: '50%',
        },
        '& > :nth-child(2)': {
            flexBasis: '50%',
        },
        '& > :nth-child(3)': {
            flexBasis: '100%',
        },
    },
    field: {
        backgroundColor: 'white',
        borderRadius: 0,
        [`& fieldset`]: {
            borderRadius: 0,
        },
    },

    deleteBtn: {
        color: theme.palette.Gray0.main,
        cursor: 'pointer',
        transition: '0.2s all ease-in',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },

    actions: {
        marginTop: 16,
        paddingTop: 16,
        borderTop: `1px solid ${theme.palette.border.main}`,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 8,
    },
    actionButton: {
        padding: '14px 28px',
        borderRadius: 0,
        boxShadow: 'unset',
    },
    actionButtonSm: {
        padding: '6px 16px',
        borderRadius: 0,
        boxShadow: 'unset',
    },
    footer: {
        width: '100%',
        padding: '12px 0px',
        backgroundColor: 'white',
        borderTop: `1px solid ${theme.palette.border.main}`,
        textAlign: 'center',
    },
}));

export default useStyles;
