export const RequestStatus = {
    0: 'Intro Request Sent',
    1: 'Introduced',
    2: 'Circle back in 6 months',
    3: 'Passed on intro',
};

export const FOUNDER_SECTORS = [
    'FinTech',
    'PropTech',
    'HealthTech',
    'HRTech',
    'DeepTech',
    'EdTech',
    'Creator Tools',
    'Developer Tools',
    'Productivity Tools',
    'Consumer',
    'Blockchain',
    'AR/VR',
    'MarTech',
    'ClimateTech/Sustainability',
    'Gaming/Esports',
    'Marketplace',
    'Ecommerce',
    'AgriTech / FoodTech',
    'B2B SaaS',
    'FemTech',
    'Sector Agnostic',
];

export const TYPE_TO_PATH = {
    Investor: 'investor',
    Founder: 'founder',
    'Job Seeker': 'seeker',
};

export const JOB_SEEKER_STACKS = [
    'Notion',
    'Figma',
    'Adobe Suite',
    'Hubspot',
    'Wordpress',
    'Monday',
    'Typescript',
    'Node.js',
    'Python',
    'Docker',
    'React',
    'PostgreSQL',
    'MongoDB',
    'Redis',
    'Jira',
    'Mailchimp',
    'Salesforce',
    'Tableau',
    'PowerB',
    'Webflow',
    'Zapier',
    'Canva',
    'Amazon Web Services',
    'Google Cloud',
    'Java',
    'C',
    'C#',
    'C++',
    'Matlab',
    'Git',
    'Outreach',
    'Salesloft',
    'Gong',
    'Chorus',
    'Airtable',
    'Pitchbook',
    'ChatGPT',
    'Photoshop',
    'Illustrator',
    'Invision',
    'Klayvio',
    'Customer.io',
    'Mailjet',
    'Framer',
    'Canva',
    'Lucidchart',
    'Workday',
    'LinkdIn Sales Navigator',
    'Retool',
    'Clay',
    'Other',
];

export const JOB_SEEKER_CATEGORIES = [
    'Hacker (Technical - web/app development skills)',
    'Hipster (Techical - UX/Design skills)',
    'Hustler (non technical - marketing/sales/customer support)',
];

export const JOB_SEEKER_WORKFORCE = ['Under 1 year', '1-3 Years', '4-10 Years', '10+ Years'];
