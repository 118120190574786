import React, { useState } from 'react';
// Custom
import {
    Box,
    TextField,
    InputAdornment,
    Button,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Select,
    MenuItem,
} from '@mui/material';
import { Search } from '@mui/icons-material';
// Styles
import useStyles from './styles';
import clsx from 'clsx';

export default function FilterList({ onAddFilter, filters }) {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState();
    const [error, setError] = useState('');

    function handleAddFilter() {
        if (!selected) return setError('Select a filter');
        if (selected.type === 'string' && !search) {
            return setError('Value required');
        }

        onAddFilter({
            value: search,
            Icon: selected.Icon,
            field: selected.field,
            label: selected.label,
        });

        setSelected();
        setSearch('');
        setError('');
    }

    function handleSetSearch(e) {
        setSearch(e.target.value);
        setError('');
    }

    function handleSelectedFilter(value) {
        if (value.type === 'boolean') {
            onAddFilter({
                value: value.label,
                Icon: value.Icon,
                field: value.field,
                label: value.label,
            });

            setSelected();
            setSearch('');
            setError('');
        } else {
            setSelected(value);
            setError('');
        }
    }

    console.log(selected);

    return (
        <Box sx={{ padding: 2 }}>
            {selected?.type === 'dropdown' && (
                <Select
                    className={classes.field}
                    value={search}
                    onChange={(e) => handleSetSearch(e)}
                    displayEmpty
                    fullWidth
                    size="small"
                >
                    <MenuItem value="">Select a sector</MenuItem>
                    {selected.items.map((item, i) => {
                        return (
                            <MenuItem mey={i} value={item}>
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            )}
            {['string', 'number'].includes(selected?.type) && (
                <TextField
                    placeholder="Input value"
                    error={!!error}
                    helperText={error}
                    value={search}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleAddFilter();
                    }}
                    onChange={(e) => handleSetSearch(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    marginY: 2,
                }}
            />
            <List>
                {filters.map((value, i) => {
                    return (
                        <ListItemButton
                            key={`val-${i}`}
                            className={clsx({
                                [classes.activeButton]: value.field === selected?.field,
                            })}
                            onClick={() => handleSelectedFilter(value)}
                        >
                            <ListItemIcon sx={{ minWidth: '36px' }}>
                                <value.Icon />
                            </ListItemIcon>
                            <ListItemText primary={value.label} />
                        </ListItemButton>
                    );
                })}
            </List>
            <Button onClick={handleAddFilter} fullWidth>
                Add Filter
            </Button>
        </Box>
    );
}
