import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
    },
    section: {
        borderBottom: `1px solid ${theme.palette.border.main}`,
        paddingBottom: 24,
        marginTop: 24,
        position: 'relative',
    },
    sectionNoBorder: {
        borderBottom: 'none',
    },
    noMargin: {
        marginTop: 0,
    },
    logoPhoto: {
        height: 120,
        width: 120,
        borderRadius: '50%',
        objectFit: 'cover',
    },
    editInfoBtn: {
        position: 'absolute',
        right: 10,
        top: -10,
    },

    dropBox: {
        border: '1px dashed rgb(232, 232, 232)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#E7ECF7',
        padding: 24,
        cursor: 'pointer',
        transition: '0.2s all ease-in',

        '& > svg': {
            transition: '0.2s all ease-in',
        },

        '&:hover': {
            backgroundColor: theme.palette.OffWhite.main,
            '& > svg': {
                fill: theme.palette.primary.main,
            },
        },
    },

    progress: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        borderRadius: 6,
        width: '25%',
    },
    file: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 3,
        minWidth: 130,
    },
    key: {
        backgroundColor: '#A6BAE2',
        padding: '29px 0px',
        display: 'flex',
        justifyContent: 'center',
    },
    footer: {
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 12px',
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    listItem: {
        boxShadow: '0 3px 20px #1d26260d',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 12,

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
    },
    listItemLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    listItemRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },
}));

export default useStyles;
