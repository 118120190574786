import { makeStyles } from '@mui/styles';
import theme from 'theme';
import LoginImg from 'assets/images/backgrounds/login.jpeg';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -15,
        marginLeft: -15,
        maxHeight: '100vh',
        overflow: 'hidden',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            marginRight: 0,
            marginLeft: 0,
        },
    },
    left: {
        flex: '0 0 58.3333333333%',
        maxWidth: '58.3333333333%',

        backgroundImage: `linear-gradient(0deg, rgba(0,0,0, 0.5), rgba(0, 0, 0, 0.7)), url(${LoginImg})`,
        backgroundPosition: '50% 25%',
        backgroundSize: 'cover',

        '&:before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: 0.3,
            background:
                'radial-gradient(rgb(210,241,223),rgb(211,215,250),rgb(186,216,244)) 0% 0%/400% 400%',
            animation: '15s ease 0s infinite normal none running gradient',
            zIndex: -1,
        },

        [theme.breakpoints.down('sm')]: {
            flex: 'unset',
            maxWidth: 'unset',

            height: 120,
        },
    },
    logo: {
        height: 45,
        marginLeft: 40,
    },
    leftContainer: {
        padding: 24,
        height: '100%',
    },
    loginImgContainer: {
        display: 'flex',
        height: 'calc(100vh - 83px)',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    loginImg: {
        height: 500,
    },

    right: {
        flex: '0 0 41.6666666667%',
        maxWidth: '41.6666666667%',
        backgroundColor: 'white',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 'unset',
            flex: 'unset',
            height: 'calc(100vh - 120px)',
        },
    },
    rightContainer: {
        padding: 32,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',

        [theme.breakpoints.down('sm')]: {
            padding: '0px 20px',
        },
    },
}));

export default useStyles;
