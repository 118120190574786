import React, { useState } from 'react';
//mods
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import UserRedux from 'redux/actions/user';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import validator from 'validator';
//comps
import { Box, Button, Checkbox, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//images
import Google from 'assets/images/logos/google.png';
//styles
import useStyles from './styles';
import { Link } from 'react-router-dom';
import SeedscoutAPI from 'integrations/api/seedscout_api';

export default function Start() {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    async function handleSignup(data) {
        setSubmitting(true);
        try {
            if (!read) {
                setError('read');
                throw new Error('Please read terms before continuing');
            }

            const email = data.email.toLowerCase();
            const response = await SeedscoutAPI.post(`/v1/signup`, {
                email,
            });

            dispatch(
                UserRedux.setUser({
                    authToken: response.data.token,
                    ...response.data.user,
                }),
            );
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Box className={classes.actionContent} component="form" onSubmit={handleSubmit(handleSignup)}>
            <Typography variant="h4" fontWeight={'600'} mb={1}>
                Welcome to Seedscout
            </Typography>
            <Typography variant="font3" color="lightText.main" mb={3}>
                Your business network!
            </Typography>
            <Tooltip title="Coming Soon" placement="top" arrow>
                <span style={{ width: '100%' }}>
                    <Button
                        fullWidth
                        disabled={true}
                        variant="contained"
                        color="lightButton"
                        startIcon={<img src={Google} style={{ height: 15 }} />}
                        sx={{ mb: 4 }}
                    >
                        Sign in with Google
                    </Button>
                </span>
            </Tooltip>
            <Box className={classes.orBorder} sx={{ mb: 4 }}>
                <Typography variant="font3">or sign up with</Typography>
            </Box>
            <Box className={classes.fieldSet} sx={{ marginBottom: 2 }}>
                <Typography variant="font3" mb={1.2} fontWeight={'500'}>
                    Email Address
                </Typography>
                <TextField
                    size="small"
                    className={classes.field}
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    placeholder="user@example.com"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', {
                        required: 'Invalid email address',
                        validate: (value) => validator.isEmail(value),
                    })}
                />
            </Box>
            <FormControlLabel
                control={<Checkbox checked={read} onChange={(e) => setRead(e.target.checked)} />}
                label={
                    <Typography
                        variant="font3"
                        color={error === 'read' ? 'error' : 'darkText.main'}
                        fontWeight={'400'}
                        sx={{ my: 2 }}
                    >
                        I have read the terms & services and agree
                    </Typography>
                }
            />
            <input type="submit" style={{ display: 'none' }} />
            <LoadingButton fullWidth variant="contained" loading={submitting} onClick={handleSubmit(handleSignup)}>
                Finish Signup
            </LoadingButton>

            <Box className={classes.row} sx={{ mt: 4 }}>
                <Typography variant="font3">Already have a Seedscout account? </Typography>
                <Typography
                    variant="font3"
                    color="primary"
                    sx={{ cursor: 'pointer', mt: 0.2 }}
                    loading={submitting}
                    component={Link}
                    className="link"
                    to="/"
                >
                    Sign in
                </Typography>
            </Box>
        </Box>
    );
}
