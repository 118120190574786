import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import useStyles from './styles';

export default function RequestDialog({
    open,
    handleClose,
    handleRequest,
    requesting,
    firstName,
    lastName,
}) {
    const classes = useStyles();
    const [message, setMessage] = useState('');
    const { user } = useSelector(({ user }) => ({ user }));

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle
                className={classes.dialogTitle}
                id="alert-dialog-title"
            >
                Customize Message
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mb: 2 }}>
                    Below is what{' '}
                    <Box component="span" sx={{ fontWeight: 700 }}>
                        {firstName + ' ' + lastName}
                    </Box>{' '}
                    will see when you request an intro. You have the option to
                    include a personalized message below. If you need to make
                    any changes to this information, update your profile, and
                    the changes will update automatically.
                </Typography>
                <Box className={classes.messageTemplate} sx={{ ml: 4 }}>
                    <Typography sx={{ mb: 1 }} variant="font5">
                        Hi {firstName},
                    </Typography>
                    <Typography variant="font5" mb={1}>
                        You have a new intro request from{' '}
                        <a
                            href={user.linkedin}
                            target="_blank"
                            className="link"
                        >
                            {user.firstName + ' ' + user.lastName}
                        </a>
                        .
                    </Typography>
                    <Typography variant="font5" mb={1}>
                        ---
                    </Typography>
                    <Typography variant="font5">
                        I am working on{' '}
                        <a
                            href={user.ActiveProfile.meta.startupURL}
                            target="_blank"
                            className="link"
                        >
                            {user.ActiveProfile.meta.companyName}
                        </a>
                        . {user.ActiveProfile.meta.oneLiner}
                    </Typography>
                    <Typography
                        sx={{
                            mb: 1,
                            whiteSpace: 'nowrap',
                        }}
                        variant="font5"
                    >
                        Custom message: <br />
                        <span className={classes.customMessage}>
                            Fill out the field below to add a custom message.
                        </span>
                    </Typography>
                    <Typography
                        variant="font5"
                        component="a"
                        mb={2}
                        href={user.ActiveProfile.meta.pitchDeck}
                        target="_blank"
                        className="link"
                    >
                        View our Pitch Deck here
                    </Typography>
                    <Typography variant="font5" mb={1}>
                        Looking forward to hearing back from you,
                    </Typography>
                    <Typography variant="font5">
                        {user.firstName + ' ' + user.lastName}
                    </Typography>
                </Box>
                <Box className={classes.textField}>
                    <TextField
                        id="dialog-message"
                        placeholder="I saw on your LinkedIn/website that you...."
                        multiline
                        rows={4}
                        fullWidth
                        value={message}
                        onChange={handleMessageChange}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions className={classes.actionContainer}>
                <Box className={classes.actionLink}>
                    <Typography variant="font5" mb={0.5}>
                        Not sure what to put in your custom message?
                    </Typography>
                    <Typography
                        variant="font5"
                        component="a"
                        href="https://www.youtube.com/watch?v=z7xEZ8Nzpjw"
                        target="_blank"
                        className="link"
                    >
                        Click here
                    </Typography>
                </Box>
                <LoadingButton
                    variant="contained"
                    loading={requesting}
                    onClick={() => handleRequest(message)}
                >
                    Send Intro Request
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
