import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    /** Drawer */
    logo: {
        height: 80,
        width: 80,
    },

    drawer: {
        width: '90%',
        backgroundImage: 'none',
    },
    drawerBox: {
        padding: 20,
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: theme.spacing(4),
    },

    navTitle: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textTransform: 'uppercase',
    },
    navDot: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        '&:before': {
            display: 'block',
            content: '""',
            height: 8,
            width: 8,
            borderRadius: '50%',
            backgroundColor: 'rgba(255,255,255,0.7)',
            marginLeft: 18,
        },
    },
    navItems: {
        '& > *:not(last-child)': {
            borderBottom: `1px solid ${theme.palette.border.main}`,
        },
    },
}));

export default useStyles;
