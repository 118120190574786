import {
    Badge,
    AutoAwesome,
    Business,
    MyLocation,
    QuestionAnswer,
    Send,
    AccountBox,
    AutoFixHigh,
} from '@mui/icons-material';

const AdminFilters = [
    { label: 'Name', field: 'name', type: 'string', Icon: Badge },
    {
        label: 'Profile Type',
        field: 'profileType',
        type: 'string',
        Icon: AccountBox,
    },
    {
        label: 'Email',
        field: 'email',
        type: 'string',
        Icon: Send,
    },
    // {
    //     label: 'Sector',
    //     field: 'startupSector',
    //     type: 'string',
    //     Icon: AutoAwesome,
    // },
    // {
    //     label: 'Startup Name',
    //     field: 'companyName',
    //     type: 'string',
    //     Icon: Business,
    // },
    // {
    //     label: 'Auto Intro Enabled',
    //     field: 'autoIntros',
    //     type: 'boolean',
    //     Icon: AutoFixHigh,
    // },
    // { label: 'City', field: 'city', type: 'string', Icon: MyLocation },
    // {
    //     label: '>= # of Intro Requests Received',
    //     field: 'introsReceived',
    //     type: 'number',
    //     Icon: Send,
    // },
    // {
    //     label: '>= # of Requests Engaged With',
    //     field: 'introsTaken',
    //     type: 'number',
    //     Icon: QuestionAnswer,
    // },

    //3)
    // last intro request sent date, in ASC order. this could be doable in sending a date?

    //4)
    // Filter by investors who have NOT been reached out too.
];

export default AdminFilters;
