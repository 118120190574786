import React, { useState } from 'react';
import { Box, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import NumericInput from 'components/inputs/NumericInput';

export default function InvestorType({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            minCheckSize: user.ActiveProfile?.meta?.minCheckSize?.numberWithCommas(2) || '',
            maxCheckSize: user.ActiveProfile?.meta?.maxCheckSize?.numberWithCommas(2) || '',
            geographyPreferences: user.ActiveProfile?.meta?.geographyPreferences || '',
            founderTraits: user.ActiveProfile?.meta?.founderTraits || '',
        },
    });

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/investor/type', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Additional Information" sx={{ width: '100%' }}>
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />

                <Box className={classes.fieldContainer}>
                    <Typography>What is your checksize?</Typography>
                    <NumericInput
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="start">Minimum</InputAdornment>,
                        }}
                        className={classes.field}
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors?.[`minCheckSize`]}
                        helperText={errors?.[`minCheckSize`]?.message}
                        {...register(`minCheckSize`, {
                            required: 'Please enter an amount,',
                        })}
                    />
                    <NumericInput
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="start">Maximum</InputAdornment>,
                        }}
                        className={classes.field}
                        fullWidth
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        error={!!errors?.[`maxCheckSize`]}
                        helperText={errors?.[`maxCheckSize`]?.message}
                        {...register(`maxCheckSize`, {
                            required: 'Please enter an amount,',
                        })}
                    />
                </Box>
                <Box className={classes.fieldContainer}>
                    <Typography>Where do you like to invest?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        defaultValue={user.ActiveProfile?.meta?.geographyPreferences || ''}
                        error={Boolean(errors.geographyPreferences)}
                        helperText={errors?.[`geographyPreferences`]?.message}
                        {...register('geographyPreferences', {
                            required: 'Required Field',
                        })}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Within my city">Within my city</MenuItem>
                        <MenuItem value="Across my state">Across my state</MenuItem>
                        <MenuItem value="Across the country">Across the country</MenuItem>
                        <MenuItem value="Across the world">Across the world</MenuItem>
                    </Select>
                </Box>
                <Box className={classes.fieldContainer}>
                    <Typography>What qualities do you look for in a founder?</Typography>
                    <TextField
                        multiline
                        rows={2}
                        className={classes.field}
                        fullWidth
                        error={!!errors.founderTraits}
                        helperText={errors?.founderTraits?.message}
                        {...register('founderTraits', {
                            required: 'Please enter some qualities',
                        })}
                    />
                </Box>
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
