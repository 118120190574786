import dayjs from 'dayjs';
import DefaultUser from 'assets/images/icons/default-user.png';
import Card from 'components/Cards/Card/Card';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { Bookmark, BookmarkRemove, Visibility } from '@mui/icons-material';
import { useState } from 'react';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { useDispatch } from 'react-redux';

export default function JobSeekerListCard({ onRemove, profile }) {
    const classes = useStyles();
    const [bookmarked, setBookmarked] = useState(profile.isBookmarked);
    const [toggling, setToggling] = useState(false);
    const dispatch = useDispatch();

    async function toggleBookmark() {
        setToggling(true);
        try {
            const response = await SeedscoutAPI.post(`/v1/bookmarks/toggle/${profile.id}/Profile`);
            const bookmarked = Boolean(response.data);
            setBookmarked(bookmarked);
            dispatch(
                setSnackbar({
                    message: bookmarked
                        ? `You bookmarked ${profile.User.firstName}.`
                        : `We  removed your bookmark for ${profile.User.firstName}`,
                }),
            );

            if (!bookmarked) onRemove?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setToggling(false);
    }

    return (
        <Card cardOnly>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Box className={classes.userCardHeader}>
                        <img src={profile.User.photo || DefaultUser} />
                        <Box className={classes.userInfo}>
                            <Typography variant="font6" color="lightText.main" fontWeight="500">
                                {profile.meta.companyName}
                            </Typography>
                            <Typography variant="font3" fontWeight="600">
                                {profile.User.firstName} {profile.User.lastName}
                            </Typography>
                            <Typography variant="font5" color="thinText.main">
                                Joined on {dayjs(profile.createdAt).format('MMM DD YYYY')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box className={classes.userInfo}>
                        <Typography variant="font5" color="lightText.main" fontWeight="500">
                            Location
                        </Typography>
                        <Tooltip
                            arrow
                            placement={'top'}
                            title={`${profile.User.city}, ${profile.User.state} ${profile.User.country}`}
                        >
                            <Typography width="fit-content" variant="font4" fontWeight="600">
                                {profile.User.city}
                            </Typography>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box className={classes.userInfo}>
                        <Typography variant="font5" color="lightText.main" fontWeight="500">
                            Years of Experience
                        </Typography>
                        <Typography variant="font4" fontWeight="600">
                            {profile?.meta?.timeInWorkforce}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box className={classes.userInfo}>
                        <Typography variant="font5" color="lightText.main" fontWeight="500">
                            Work Type
                        </Typography>
                        <Typography variant="font4" fontWeight="600">
                            {profile.meta?.talentType?.split?.(' (')?.[0] || 'Not Specified'}
                        </Typography>
                    </Box>
                </Grid>
                {/* <Grid item xs={12} md={2}>
                    <Box className={classes.userInfo}>
                        <Typography variant="font5" color="lightText.main" fontWeight="500">
                            Last Engagement
                        </Typography>
                        <Typography variant="font4" fontWeight="600">
                            {profile.User.lastEngageDate
                                ? dayjs(profile.User?.lastEngageDate).format('MMM DD YYYY')
                                : '-'}
                        </Typography>
                    </Box>
                </Grid> */}
                <Grid item xs={12} md={3}>
                    <Box className={classes.actions}>
                        {onRemove && (
                            <Tooltip arrow placement="top" title={'Remove Bookmark'}>
                                <Box className={classes.actionButton} onClick={toggling ? null : toggleBookmark}>
                                    {toggling && <CircularProgress size={10} />}
                                    {!toggling && <BookmarkRemove />}
                                </Box>
                            </Tooltip>
                        )}
                        {!onRemove && (
                            <Tooltip arrow placement="top" title={bookmarked ? 'Remove Bookmark' : 'Bookmark'}>
                                <Box className={classes.actionButton} onClick={toggling ? null : toggleBookmark}>
                                    {toggling && <CircularProgress size={10} />}
                                    {!toggling && (bookmarked ? <BookmarkRemove /> : <Bookmark />)}
                                </Box>
                            </Tooltip>
                        )}
                        <Tooltip placement="top" title={'View Profile'} arrow>
                            <Box
                                className={classes.actionButton}
                                target="_blank"
                                component={Link}
                                to={`/seeker/${profile.id}`}
                            >
                                <Visibility />
                            </Box>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
