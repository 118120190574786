import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        color: '#0077FF !important',
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
