export const SET_SUBSCRIPTION_PRODUCTS = 'SET_SUBSCRIPTION_PRODUCTS';
export const SUBSCRIPTION_PRODUCTS_ERROR = 'SUBSCRIPTION_PRODUCTS_ERROR';
export const INIT = { filters: {}, sort: {} };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_SUBSCRIPTION_PRODUCTS:
            return payload;
        case SUBSCRIPTION_PRODUCTS_ERROR:
        default:
            return state;
    }
}

export default reducer;
