import React, { useState } from 'react';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import clsx from 'clsx';
import LinkButton from 'components/Buttons/LinkButton/LinkButton';
import UpdateSeekerDialog from 'components/Dialogs/UpdateSeekerDialog/UpdateSeekerDialog';

export default function SeekerProfileCard({ profile, editable, onEdit }) {
    const classes = useStyles();
    const [dialog, setDialog] = useState(false);

    return (
        <Card cardOnly>
            <UpdateSeekerDialog open={dialog === 'update-seeker'} onEdit={onEdit} onClose={() => setDialog(false)} />
            <Box className={clsx(classes.section, classes.noMargin)}>
                <Typography variant="font4" fontWeight={'500'} color="lightText.main">
                    Cover Letter
                </Typography>
                <Typography variant="font4" maxWidth={'500px'} fontWeight={'400'} mt={2}>
                    {profile?.meta?.coverLetter}
                </Typography>
            </Box>
            <Box className={clsx(classes.section, classes.sectionNoBorder)}>
                {editable && (
                    <IconButton className={classes.editInfoBtn} onClick={() => setDialog('update-seeker')}>
                        <Edit />
                    </IconButton>
                )}
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    Job seeking details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">What role do I play on a team?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile?.meta?.talentType}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">What is my stack?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile?.meta?.stacks?.join?.(', ')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">How long have I been in the workforce?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile?.meta?.timeInWorkforce}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">Where can you find my portfolio?</Typography>
                        <LinkButton link={profile?.meta?.website} />
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}
