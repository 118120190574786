import SeedscoutAPI from 'integrations/api/seedscout_api';
import { SET_CURRENT_USER, LOGOUT, USER_ERROR } from '../reducers/user';
import cloneDeep from 'lodash/cloneDeep';

export default class UserRedux {
    static getCurrentUser = () => async (dispatch, getState) => {
        const state = getState();
        if (!state.user) return;

        try {
            const response = await SeedscoutAPI.get(`/v1/users/current`);

            const payload = { ...state.user, ...response.data.user };
            payload.lastSyncTime = new Date().getTime() / 1000;
            payload.isAdmin = payload.permissions?.admin;

            dispatch({
                payload,
                type: SET_CURRENT_USER,
            });

            return payload;
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 423) {
                dispatch(UserRedux.signOut());
            }
            throw error;
        }
    };

    static setUser = (payload) => (dispatch, getState) => {
        const user = cloneDeep(getState().user);

        payload = { ...user, ...payload };
        payload.lastSyncTime = new Date().getTime() / 1000;
        payload.isAdmin = payload.groups?.includes?.('Admin');

        dispatch({
            type: SET_CURRENT_USER,
            payload,
        });
    };

    static signOut = () => async (dispatch) => {
        try {
            dispatch({
                payload: null,
                type: LOGOUT,
            });

            delete SeedscoutAPI.defaults.headers.common['x-auth'];

            return true;
        } catch (error) {
            console.log(error);
            dispatch({
                type: USER_ERROR,
            });

            return false;
        }
    };

    static subtractIntro = () => (dispatch, getState) => {
        const { user } = getState();
        const _user = cloneDeep(user);
        if (_user?.requests?.intros) _user.requests.intros -= 1;

        return dispatch({ payload: _user, type: SET_CURRENT_USER });
    };
    static subtractAutoIntro = () => (dispatch, getState) => {
        const { user } = getState();
        const _user = cloneDeep(user);
        if (_user?.requests?.autoIntros) _user.requests.autoIntros -= 1;

        return dispatch({ payload: _user, type: SET_CURRENT_USER });
    };
}

export const subtractIntro = () => (dispatch, getState) => {
    const { user } = getState();
    const _user = cloneDeep(user);
    if (_user?.requests?.intros) _user.requests.intros -= 1;

    return dispatch({ payload: _user, type: SET_CURRENT_USER });
};

export const subtractAutoIntro = () => (dispatch, getState) => {
    const { user } = getState();
    const _user = cloneDeep(user);
    if (_user?.requests?.autoIntros) _user.requests.autoIntros -= 1;

    return dispatch({ payload: _user, type: SET_CURRENT_USER });
};
