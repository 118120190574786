import React from 'react';

// Modules
import { useDispatch } from 'react-redux';
// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import Logo from 'assets/images/logos/logo_text.png';
import Signup from './Signup/Signup';

import useStyles from './styles';
import { Box } from '@mui/material';
import Login from './Login/Login';
import Authenticate from './Authenticate/Authenticate';

const UnauthStack = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.left}>
                <Box className={classes.leftContainer}>
                    <a href="https://seedscout.com" target="_blank">
                        <img src={Logo} className={classes.logo} />
                    </a>
                    {/* <Box className={classes.loginImgContainer}>
                        <img src={LoginImg} className={classes.loginImg} />
                    </Box> */}
                </Box>
            </Box>

            <Box className={classes.right}>
                <Box className={classes.rightContainer}>
                    <Routes>
                        <Route path="" element={<Login />} />
                        <Route path="signup" element={<Signup />} />
                        <Route path="authenticate" element={<Authenticate />} />
                        {/* End Routes */}

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default UnauthStack;
