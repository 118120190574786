import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    linkContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    actionContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fieldSet: {
        width: '100%',
        textAlign: 'left',
    },

    logo: {
        maxWidth: 200,
    },

    animation: {
        maxWidth: '40%',
    },

    orBorder: {
        textAlign: 'center',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: '25%',
            top: '50%',
            height: 1,
            background: '#e5eaef',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            width: '25%',
            top: '50%',
            height: 1,
            background: '#e5eaef',
        },
    },

    row: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
    },

    field: {
        backgroundColor: 'white',
        borderRadius: theme.custom.fieldBorderRadius,
        fontSize: 14,
        [`& fieldset`]: {
            borderRadius: theme.custom.fieldBorderRadius,
        },

        '& input::placeholder': {
            fontSize: 14,
        },
        '& input': {
            fontSize: 14,
        },
    },
}));

export default useStyles;
