import * as React from 'react';
// Modules
import { useDispatch, useSelector } from 'react-redux';
import UserRedux from 'redux/actions/user';
import { Link } from 'react-router-dom';
// UI
import { Box, Typography, Divider } from '@mui/material';
import { ExitToApp, AccountCircle, ExpandMore, Settings, Bookmark } from '@mui/icons-material';
// images
import DefaultUser from 'assets/images/icons/default-user.png';
// Styles
import useStyles from './styles';
import Dropdown from 'components/DropdownV2/Dropdown';

export default function AccountButton() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    async function handleLogout() {
        await dispatch(UserRedux.signOut());
    }

    return (
        <Dropdown
            title={
                <Box className={classes.profileDropdown}>
                    <img src={user.photo || DefaultUser} className={classes.profilePicture} />
                    <Typography variant="font3" fontWeight={'500'}>
                        Hi, {user.firstName}
                    </Typography>
                    <ExpandMore color="lightText" />
                </Box>
            }
        >
            <Box className={classes.dropdownContent}>
                <Box className={classes.menuItem} component={Link} to="/">
                    <AccountCircle color="lightText" />
                    <Typography variant="font3" fontWeight={'500'}>
                        My Account
                    </Typography>
                </Box>
                <Box className={classes.menuItem} component={Link} to="/bookmarks">
                    <Bookmark color="lightText" />
                    <Typography variant="font3" fontWeight={'500'}>
                        My Bookmarks
                    </Typography>
                </Box>
                <Box className={classes.menuItem} component={Link} to="/settings">
                    <Settings color="lightText" />
                    <Typography variant="font3" fontWeight={'500'}>
                        Account Settings
                    </Typography>
                </Box>
                <Divider sx={{ marginY: 1, borderColor: 'rgba(0,0,0,0.06)' }} />
                <Box className={classes.menuItem} onClick={handleLogout}>
                    <ExitToApp color="lightText" />
                    <Typography variant="font3" fontWeight={'500'}>
                        Logout
                    </Typography>
                </Box>
            </Box>
        </Dropdown>
    );
}
