import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    sectionContainer: {
        backgroundColor: 'white',
        boxShadow: theme.boxShadows[0],
        borderRadius: 6,
        padding: 24,
    },

    gridItem: {
        marginBottom: theme.spacing(10),
    },
    /** Auxiliary */
    center: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles;
