import React, { useState } from 'react';
import useStyles from './styles';
import DefaultLogo from 'assets/images/logos/logo-black-tp.png';
import Card from 'components/Cards/Card/Card';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import clsx from 'clsx';
import LinkButton from 'components/Buttons/LinkButton/LinkButton';
import AvatarUpload from 'components/inputs/AvatarUpload/AvatarUpload';
import { useDispatch } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import UpdateInvestorDialog from 'components/Dialogs/UpdateInvestorDialog';
import UpdateInvestmentsDialog from 'components/Dialogs/UpdateInvestmentsDialog';

export default function InvestorProfileCard({ profile, user, editable, onEdit }) {
    const classes = useStyles();
    const [dialog, setDialog] = useState(false);
    const [photo, setPhoto] = useState({ src: profile?.logo || DefaultLogo });
    const dispatch = useDispatch();

    function handleImageChanged(e) {
        if (!e) return setValue('photo', null);

        const file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            setPhoto({ src: e.target.result, type: file.type });
            handleUpdatePhoto(file);
        };
        reader.readAsDataURL(file);
    }

    async function handleUpdatePhoto(file) {
        try {
            let body = {};
            if (file) {
                body = new FormData();
                body.append('file', file);
            }

            const response = await SeedscoutAPI.patch(`/v1/profiles/${profile.UserId}/logo`, body);

            onEdit(response.data);
            dispatch(setSnackbar({ message: 'Logo updated!' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    let geoState = '';
    if (profile?.meta?.geographyPreferences === 'Within my city') {
        geoState = `(${user.city})`;
    } else if (profile?.meta?.geographyPreferences === 'Across my state') {
        geoState = `(${user.state})`;
    } else if (profile?.meta?.geographyPreferences === 'Across the country') {
        geoState = `(${user.country})`;
    }

    return (
        <Card cardOnly>
            <UpdateInvestorDialog
                profile={profile}
                onEdit={onEdit}
                open={dialog === 'update-investor'}
                onClose={() => setDialog(false)}
            />
            <UpdateInvestmentsDialog
                profile={profile}
                onEdit={onEdit}
                open={dialog === 'update-investments'}
                onClose={() => setDialog(false)}
            />
            <Box className={clsx(classes.section, classes.noMargin)}>
                <Typography variant="font4" fontWeight={'500'} color="lightText.main">
                    Qualities in founders I look for
                </Typography>
                <Typography variant="font4" maxWidth={'400px'} fontWeight={'400'} mt={2}>
                    {profile?.meta?.founderTraits}
                </Typography>
            </Box>
            <Box className={classes.section}>
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    Firm Logo
                </Typography>
                <AvatarUpload
                    disabled={!editable}
                    src={photo.src}
                    classes={{ profileImageView: classes.logoPhoto }}
                    onChange={(e) => handleImageChanged(e)}
                />
            </Box>
            <Box className={clsx(classes.section)}>
                {editable && (
                    <IconButton className={classes.editInfoBtn} onClick={() => setDialog('update-investor')}>
                        <Edit />
                    </IconButton>
                )}
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    Investment Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">Firm Name</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile?.meta?.companyName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">What type of Investor am I?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile?.meta?.investorType}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">What locations do I invest in?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile?.meta?.geographyPreferences} {geoState}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">What sectors do I invest in?</Typography>
                        <Typography variant="font4" color="lightText.main" maxWidth={'350px'}>
                            {profile?.meta?.sectors?.join?.(', ')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">What is my minimum check size?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            ${profile?.meta?.minCheckSize?.numberWithCommas?.(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">What is my maximum check size?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            ${profile?.meta?.maxCheckSize?.numberWithCommas?.(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">Website</Typography>
                        <Typography variant="font4" color="lightText.main">
                            <LinkButton link={profile?.meta?.website} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className={clsx(classes.section, classes.sectionNoBorder)}>
                {editable && (
                    <IconButton className={classes.editInfoBtn} onClick={() => setDialog('update-investments')}>
                        <Edit />
                    </IconButton>
                )}
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    Past Investments
                </Typography>
                <Box className={classes.list}>
                    {profile?.meta?.investments?.map?.((investment, i) => {
                        return (
                            <Box className={classes.listItem} key={i}>
                                <Box className={classes.listItemLeft}>
                                    <Typography variant="font2" fontWeight={'600'}>
                                        {investment.name}
                                    </Typography>
                                    <LinkButton link={investment.website} />
                                </Box>
                                <Box className={classes.listItemRight}>
                                    <Typography variant="font4" color="lightText.main">
                                        Investment Amount
                                    </Typography>
                                    {Boolean(investment.amount) && (
                                        <Typography variant="font3">
                                            ${investment.amount?.stringToNumber?.()?.numberWithCommas(2)}
                                        </Typography>
                                    )}
                                    {!investment.amount && <Typography variant="font3">Not Specified</Typography>}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Card>
    );
}
