import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useStyles from './styles';
import UserRedux from 'redux/actions/user';
import { useFieldArray, useForm } from 'react-hook-form';
import { Close, DeleteForever } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import NumericInput from 'components/inputs/NumericInput';
import clsx from 'clsx';
import Lottie from 'lottie-react';
import EmptyTable from 'assets/animations/empty-table2.json';

export default function UpdateInvestmentsDialog({ open, profile, onClose, onEdit }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            investments: profile.meta.investments || [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'investments', // unique name for your Field Array
    });

    async function handleUpdate(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch(`/v1/users/${profile.UserId}`, values);
            onEdit(response.data);
            onClose?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Box className={classes.dialogTitle} id="alert-dialog-title">
                <Typography variant="font1">Edit Past Investments</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleUpdate)} mt={1}>
                    <input style={{ display: 'none' }} type="submit" />
                    {fields.length === 0 && (
                        <Box>
                            <Typography>No investments added. Click "Add" to add a new one!</Typography>
                            <Lottie loop={false} animationData={EmptyTable} className={classes.animation} />
                        </Box>
                    )}
                    {fields.map((field, i) => {
                        return (
                            <Box key={field.id} className={clsx(classes.fieldRow)}>
                                <Box className={clsx(classes.fieldTitle)} sx={{ mb: 1 }}>
                                    <Typography variant="font1" fontWeight={'500'}>
                                        Investment {i + 1}
                                    </Typography>
                                    <DeleteForever className={classes.deleteBtn} onClick={() => remove(i)} />
                                </Box>
                                <Box className={classes.fieldBox}>
                                    <TextField
                                        className={classes.field}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">Company Name</InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        placeholder="Google"
                                        error={!!errors?.investments?.[i]?.name}
                                        helperText={errors?.investments?.[i]?.name?.message}
                                        {...register(`investments.${i}.name`, {
                                            required: 'Please fill out this value',
                                        })}
                                    />
                                    <NumericInput
                                        fullWidth
                                        className={classes.field}
                                        precision={0}
                                        placeholder="Investment Amount"
                                        decimalChar="."
                                        thousandChar=","
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        error={!!errors?.investments?.[i]?.amount}
                                        helperText={errors?.investments?.[i]?.amount?.message}
                                        {...register(`investments.${i}.amount`)}
                                    />

                                    <TextField
                                        className={classes.field}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">Startup website</InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        placeholder="https://example.com"
                                        error={!!errors?.investments?.[i]?.website}
                                        helperText={errors?.investments?.[i]?.website?.message}
                                        {...register(`investments.${i}.website`, {
                                            required: 'Field is required',
                                        })}
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </DialogContent>
            <Box className={classes.actionContainer}>
                <Button
                    disabled={fields.length === 4}
                    onClick={() => fields.length < 4 && append({ name: '', amount: '', website: '' })}
                >
                    Add New
                </Button>
                <LoadingButton variant="contained" loading={submitting} onClick={handleSubmit(handleUpdate)}>
                    Update
                </LoadingButton>
            </Box>
        </Dialog>
    );
}
