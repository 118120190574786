import React, { useState } from 'react';
import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';

export default function Persona({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            persona: '',
        },
    });

    const persona = watch('persona', '');

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/persona', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="I am a(n)....">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />
                <TextField
                    fullWidth
                    className={classes.field}
                    error={!!errors.persona}
                    helperText={errors?.persona?.message}
                    value={persona}
                    onChange={(e) => setValue('persona', e.target.value)}
                    {...register('persona', {
                        required: 'Please select an option',
                    })}
                    select
                    SelectProps={{ displayEmpty: true }}
                >
                    <MenuItem value={''}>Select an option</MenuItem>
                    <MenuItem value={'Founder'}>Founder</MenuItem>
                    <MenuItem value={'Investor'}>Investor</MenuItem>
                    <MenuItem value={'Job Seeker'}>Job Seeker</MenuItem>
                    {/* <MenuItem value={'Job Seeker'}>Job Seeker</MenuItem> */}
                </TextField>
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
