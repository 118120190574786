import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    drawerContainer: {
        backgroundColor: 'white',
        width: 350,

        '& > div:not(:last-child)': {
            borderBottom: '1px solid #efefef',
        },
    },
    section: {
        padding: theme.spacing(2),
    },

    divider: {
        margin: '12px 0px',
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.Gray0,
    },
    /* Profile Header */
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
    },
    headerIcons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },

    profileInfo: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    membership: {
        border: `1px solid ${theme.colors.Green}`,
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    userType: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
    },
    /*Engagement Section*/

    listContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listItem: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },

    /*Intro Management Section*/

    intros: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    centerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    /* Recent Connection Section */

    connectionList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        gap: 16,
    },
    connectionInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },

    /* Contact Info Section */
    contactList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'column',
    },

    /* User Settings */

    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonOne: {
        width: '100%',
        border: `1px solid ${theme.colors.Red}`,
        borderRadius: '20px',
        color: theme.colors.Red,
        backgroundColor: 'white',
        '&:visited': {
            color: 'white',
        },
        '&:hover': {
            backgroundColor: 'rgba(128, 128, 128, 0.2)',
        },
    },
    buttonTwo: {
        width: '100%',
        border: `1px solid ${theme.colors.Red}`,
        borderRadius: '20px',
        color: 'white',
        '&:visited': {
            color: 'white',
        },
        '&:hover': {
            backgroundColor: 'rgba(128, 128, 128, 0.2)',
        },
    },
}));

export default useStyles;
