import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    fields: {
        '& > *:not(:last-child)': {
            marginBottom: 24,
        },
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
    },
    field: {
        backgroundColor: 'white',
        borderRadius: 0,
        [`& fieldset`]: {
            borderRadius: 0,
        },
    },
    innerField: {
        // paddingLeft: 24,
        // position: 'relative',
        // '&:before': {
        //     content: '""',
        //     height: 10,
        //     width: 10,
        //     position: 'absolute',
        //     left: 0,
        //     top: '50%',
        //     transform: 'translateY(-50%)',
        //     borderRadius: '50%',
        //     backgroundColor: theme.palette.primary.main,
        // },
    },
    actions: {
        marginTop: 16,
        paddingTop: 16,
        borderTop: `1px solid ${theme.palette.border.main}`,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 8,
    },
    actionButton: {
        padding: '14px 28px',
        borderRadius: 0,
        boxShadow: 'unset',
    },
    footer: {
        width: '100%',
        padding: '12px 0px',
        backgroundColor: 'white',
        borderTop: `1px solid ${theme.palette.border.main}`,
        textAlign: 'center',
    },
}));

export default useStyles;
