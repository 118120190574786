import React, { useEffect, useState } from 'react';
import {
    Alert,
    Container,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useStyles from './styles';
import Logo from 'assets/images/logos/logo_text.png';
import { setSnackbar } from 'redux/actions/snackbar';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';

export default function IntroResponse() {
    const classes = useStyles();
    const params = useParams();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const [reply, setReply] = useState('');
    const [status, setStatus] = useState('1');
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function getRequest() {
            const query = queryString.parse(search);

            setLoading(true);
            try {
                const response = await SeedscoutAPI.get(`/v1/requests/${params.id}/${query.hash}`);

                setRequest(response.data.request);
            } catch (error) {
                navigate('/');
                dispatch(
                    setSnackbar({
                        severity: 'error',
                        message: checkError(error),
                    }),
                );
            }

            setLoading(false);
        }

        getRequest();
    }, []);

    async function handleSend() {
        const query = queryString.parse(search);

        setSubmitting(true);
        try {
            await SeedscoutAPI.patch(`/v1/requests/respond/${params.id}/${query.hash}`, {
                reply,
                status,
            });

            window.location.replace('https://app.seedscout.com');
        } catch (error) {
            setSubmitting(false);
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    return (
        <Container maxWidth={'lg'} className={classes.root}>
            <img src={Logo} />
            {request && request.status !== 0 && (
                <Alert fullWidth severity="warning" sx={{ marginBottom: 3 }}>
                    <Typography>You have already responded to this request</Typography>
                </Alert>
            )}
            <Paper classes={{ root: classes.respondBox }}>
                <Typography variant="h6">How would you like to respond?</Typography>

                <FormControl sx={{ marginTop: 2, marginBottom: 1 }}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="1"
                        name="radio-buttons-group"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Get an intro" />
                        <FormControlLabel value="2" control={<Radio />} label="Tell them to circle back in 6months" />
                        <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Pass on the intro (Optimally share why with a few words)"
                        />
                    </RadioGroup>
                </FormControl>

                {status !== '1' && (
                    <TextField
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        label="Give them a quick explaination of your answer"
                    />
                )}
                <LoadingButton loading={submitting} sx={{ marginTop: 2 }} onClick={handleSend} variant="contained">
                    Submit
                </LoadingButton>
            </Paper>
        </Container>
    );
}
