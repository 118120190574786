import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import useStyles from './styles';
import DefaultUser from 'assets/images/icons/default-user.png';
import { DeleteForever, Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { TYPE_TO_PATH } from 'utils/constants';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';

export default function BookmarksItem({ mark, onRemove }) {
    const classes = useStyles();
    const [toggling, setToggling] = useState(false);
    const dispatch = useDispatch();

    async function toggleBookmark() {
        setToggling(true);
        try {
            await SeedscoutAPI.post(`/v1/bookmarks/toggle/${mark.Profile.id}/Profile`);
            dispatch(
                setSnackbar({
                    message: `We have removed ${mark.Profile?.User?.firstName} from your bookmarks.`,
                }),
            );
            onRemove?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setToggling(false);
    }

    return (
        <Box className={classes.listItem}>
            <Box className={classes.listItemLeft}>
                <img src={mark.Profile?.User?.photo || DefaultUser} />
                <Box className={classes.listItemText}>
                    <Typography variant="font4">
                        {mark.Profile?.User?.firstName} {mark.Profile?.User?.lastName}
                    </Typography>
                    <Typography variant="font5" color="lightText.main">
                        {mark.Profile?.meta?.companyName || mark.Profile?.meta?.firmName}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.listItemRight}>
                <Box className={classes.actionButton} onClick={toggling ? null : toggleBookmark}>
                    {toggling && <CircularProgress size={10} />}
                    {!toggling && <DeleteForever />}
                </Box>
                <Box
                    className={classes.actionButton}
                    component={Link}
                    to={`/${TYPE_TO_PATH[mark.Profile.type]}/${mark.Profile.id}`}
                >
                    <Visibility />
                </Box>
            </Box>
        </Box>
    );
}
