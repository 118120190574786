import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
        padding: '0px !important',
    },
    bookmarkBtn: {
        color: 'white',
        fill: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        padding: '6px 12px',
        position: 'absolute',
        top: 10,
        right: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 6,
        cursor: 'pointer',
        '& > svg': {
            fontSize: 18,
        },

        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },

        '&[disabled]': {
            backgroundColor: 'gray',
            color: 'black',
            cursor: 'not-allowed',
        },
    },
    background: {
        width: '100%',
        height: 300,
        position: 'relative',
        border: `1px solid #e4e9f1`,
        backgroundSize: 'cover',
        boxSizing: 'border-box',

        [theme.breakpoints.down('sm')]: {
            height: 200,
        },
    },
    userPhoto: {
        position: 'absolute !important',
        height: 120,
        width: 120,
        borderRadius: '50%',
        objectFit: 'cover',
        bottom: -30,
        left: 30,
    },
    userInfo: {
        padding: '24px',
        position: 'relative',
    },
    editInfoBtn: {
        position: 'absolute',
        right: 10,
        top: 10,
    },
    linkBox: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        color: '#0077FF',
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    score: {
        position: 'absolute',
        right: 10,
        top: -10,
    },
}));

export default useStyles;
