import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import dayjs from 'dayjs';
import RequestDialog from 'components/Dialogs/RequestDialog/RequestDialog';
// MUI
import { Box, Typography, Tooltip, Button } from '@mui/material';
import CustomTable from 'components/Widgets/CustomTable';
import { LoadingButton } from '@mui/lab';
// Styles
import useStyles from './styles';
import { RequestStatus } from 'utils/constants';
import UserRedux from 'redux/actions/user';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';

const TableHeaders = ['To', 'Status', 'Their Response', 'Created'];

export default function IntroRequestCard({ profile, requests, onAutoIntro, onRequest }) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    const [requesting, setRequesting] = useState(false);
    const [requestingAuto, setRequestingAuto] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [disableRequesting, setDisableRequesting] = useState(false);

    async function handleRequest(message) {
        setRequesting(true);
        try {
            await SeedscoutAPI.post(`/v1/requests/intro/${profile.id}`, {
                message,
            });
            dispatch(
                setSnackbar({
                    severity: 'success',
                    message: `Intro request was sent to ${profile.User.firstName}`,
                }),
            );

            dispatch(UserRedux.subtractIntro());
            await onRequest?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setRequesting(false);
        setShowDialog(!showDialog);
        setDisableRequesting(!disableRequesting);
    }

    async function handleAutoIntro() {
        setRequestingAuto(true);
        try {
            await SeedscoutAPI.post(`/v1/requests/auto-intro/${profile.id}`);
            dispatch(
                setSnackbar({
                    severity: 'success',
                    message: `Auto intro request was sent to ${profile.User.firstName}`,
                }),
            );

            dispatch(UserRedux.subtractAutoIntro());
            await onAutoIntro?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setRequestingAuto(false);
    }

    return (
        <>
            <RequestDialog
                open={showDialog}
                handleClose={() => setShowDialog(false)}
                handleRequest={handleRequest}
                requesting={requesting}
                firstName={profile?.User?.firstName}
                lastName={profile?.User?.lastName}
            />
            <Card
                titleChildren={
                    <Box className={classes.introRequestHeader}>
                        <Typography variant="h5">Intro Requests</Typography>
                        <Box className={classes.center}>
                            <LoadingButton
                                variant="contained"
                                loading={requesting}
                                disabled={disableRequesting}
                                onClick={() => setShowDialog(true)}
                                sx={{ marginLeft: 1 }}
                            >
                                Request Intro
                            </LoadingButton>
                            {profile?.User?.autoIntros?.active && (
                                <Tooltip
                                    arrow
                                    title={
                                        !user.requests?.autoIntros && (
                                            <Typography variant="body2">You have no auto intros available</Typography>
                                        )
                                    }
                                >
                                    <span>
                                        <LoadingButton
                                            component="span"
                                            variant="contained"
                                            loading={requestingAuto}
                                            disabled={
                                                profile?.User?.autoIntros?.capacity === 0 || !user.requests?.autoIntros
                                            }
                                            onClick={handleAutoIntro}
                                            sx={{ marginLeft: 1 }}
                                        >
                                            {profile?.User?.autoIntros?.capacity === 0
                                                ? 'Auto Intro at Capacity'
                                                : `Trigger Auto Intro (${profile?.User?.autoIntros?.capacity})`}
                                        </LoadingButton>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                }
            >
                <CustomTable
                    count={requests.count}
                    initialOrderIndex={3}
                    headers={TableHeaders}
                    pagination={false}
                    rows={requests.items.map((item) => {
                        return {
                            columns: [
                                <Typography variant="font5">
                                    {item.To.User.firstName} {item.To.User.lastName}
                                </Typography>,
                                <Typography variant="font5">{RequestStatus[item.status]}</Typography>,
                                <Typography variant="font5">{item.reply ?? 'No Reply yet'}</Typography>,
                                <Typography variant="font5">{dayjs(item.createdAt).format('MM/DD/YYYY')}</Typography>,
                            ],
                        };
                    })}
                />
                {requests.items.length === 0 && (
                    <Box className={classes.emptyTable}>
                        <Typography variant="font4">No Intro Requests made yet!</Typography>
                    </Box>
                )}
            </Card>
        </>
    );
}
