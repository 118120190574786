import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listItemLeft: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        '& > img': {
            height: 50,
            width: 50,
            borderRadius: '50%',
            objectFit: 'cover',
        },
    },

    actionButton: {
        height: 25,
        width: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        border: `1px solid rgb(203, 213, 225)`,
        transition: '0.2s all ease-in',
        '& > svg': {
            fontSize: 16,
            fill: 'rgb(203, 213, 225)',
        },

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            // border: 'none',

            '& > svg': {
                fill: 'white',
            },
        },
    },
}));

export default useStyles;
