import React, { useEffect, useRef } from 'react';
// Modules
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import UserRedux from 'redux/actions/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { Box, CircularProgress, Typography } from '@mui/material';

import useStyles from './styles';
import SeedscoutAPI from 'integrations/api/seedscout_api';

export default function Authenticate() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const navigate = useNavigate();
    const authenticating = useRef();

    useEffect(() => {
        handleAuthenticate();
    }, []);

    async function handleAuthenticate() {
        if (authenticating.current) return;
        authenticating.current = true;

        const { email, token, redirect } = queryString.parse(search);
        try {
            const response = await SeedscoutAPI.post(`/v1/authentication/authenticate`, {
                email,
                password: token,
            });

            SeedscoutAPI.defaults.headers.common['x-auth'] = response.data.token;

            dispatch(
                UserRedux.setUser({
                    authToken: response.data.token,
                    ...response.data.user,
                    isAdmin: response.data.permissions?.includes?.('admin'),
                }),
            );

            if (redirect) {
                navigate(redirect);
            }
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
            navigate('/');
        }
    }

    return (
        <Box className={classes.root}>
            {/* <img src={Logo} className={classes.logo} /> */}
            <Typography sx={{ marginTop: 10, marginBottom: 3 }}>Authenticating</Typography>
            <CircularProgress />
        </Box>
    );
}
