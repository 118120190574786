import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    sectionContainer: {
        backgroundColor: 'white',
        boxShadow: theme.boxShadows[0],
        borderRadius: 6,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    boxLayout: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spacedBoxLayout: {
        justifyContent: 'space-between',
    },
    buttonLayout: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
        flexWrap: 'wrap',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'flex-start',
    },
    /** Auxiliary */
    center: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles;
