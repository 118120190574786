import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import phoneModule from 'phone';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import PlaceSearch from 'components/inputs/PlaceSearch/PlaceSearch';

export default function UpdateUserDialog({ open, onClose, user, onEdit }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            phone: user.phone || '',
            linkedin: user.linkedin || '',
            intro: user.intro || '',
        },
    });

    async function handleUpdate(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch(`/v1/users/${user.id}`, values);
            onEdit(response.data);
            onClose?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Box className={classes.dialogTitle} id="alert-dialog-title">
                <Typography variant="font1">Edit Profile</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleUpdate)} mt={1}>
                    <input style={{ display: 'none' }} type="submit" />
                    <Box className={classes.fieldContainer}>
                        <Typography>First Name</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="John"
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message}
                            {...register('firstName', {
                                required: 'Please enter your first name',
                            })}
                        />
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>Last Name</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="Doe"
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message}
                            {...register('lastName', {
                                required: 'Please enter your last name',
                            })}
                        />
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>Phone Number</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="(555) 555 5555"
                            error={!!errors.phone}
                            helperText={errors?.phone?.message}
                            {...register('phone')}
                        />
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>LinkedIn</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="https://linkedin.com/my-profile"
                            error={!!errors.linkedin}
                            helperText={errors?.linkedin?.message}
                            {...register('linkedin', {
                                required: 'Field is required',
                            })}
                        />
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>Introduce yourself in 1-2 sentences</Typography>
                        <TextField
                            className={classes.field}
                            multiline
                            rows={3}
                            fullWidth
                            placeholder="I am the founder of Seedscout which is aiming to build a modern day LinkedIn. When i'm not working, I love spending time with my wife & going hiking in northern Arizona."
                            error={!!errors.intro}
                            helperText={errors?.intro?.message}
                            {...register('intro')}
                        />
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>Selected Location</Typography>
                        <Typography variant="font3">
                            {user.city}, {user.state} {user.country}
                        </Typography>
                        {/* <Typography variant="font6" color="lightText.main">
                            {user.longitude}, {user.latitude}
                        </Typography> */}
                    </Box>
                    <Box className={classes.fieldContainer}>
                        <Typography>New Location</Typography>
                        <PlaceSearch
                            className={classes.field}
                            placeholder={'Input new location'}
                            onChange={(place) => {
                                place.address_components.forEach((component) => {
                                    if (component.types.includes('locality')) {
                                        setValue('city', component.long_name);
                                    } else if (component.types.includes('administrative_area_level_1')) {
                                        setValue('state', component.short_name);
                                    } else if (component.types.includes('country')) {
                                        setValue('country', component.long_name);
                                    }
                                });

                                setValue('coordinates', [place.geometry.location.lng(), place.geometry.location.lat()]);
                            }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <Box className={classes.actionContainer}>
                <LoadingButton variant="contained" loading={submitting} onClick={handleSubmit(handleUpdate)}>
                    Update
                </LoadingButton>
            </Box>
        </Dialog>
    );
}
