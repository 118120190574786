import FounderProfileCard from 'components/Cards/FounderProfileCard/FounderProfileCard';
import InvestorProfileCard from 'components/Cards/InvestorProfileCard/InvestorProfileCard';
import React from 'react';
import SeekerProfileCard from '../SeekerProfileCard/SeekerProfileCard';

export default function ProfileCard({ profile, user, onEdit, editable = true }) {
    if (profile?.type === 'Founder') {
        return <FounderProfileCard onEdit={onEdit} profile={profile} user={user} editable={editable} />;
    } else if (profile?.type === 'Investor') {
        return <InvestorProfileCard onEdit={onEdit} profile={profile} user={user} editable={editable} />;
    } else if (profile?.type === 'Job Seeker') {
        return <SeekerProfileCard onEdit={onEdit} profile={profile} user={user} editable={editable} />;
    }

    return null;
}
