import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    form: {
        maxWidth: '550px',
        flex: 1,
        paddingTop: '2%',
        width: '100%',
        margin: 'auto',
        boxSizing: 'border-box',

        [theme.breakpoints.down('sm')]: {
            padding: '0px 6px',
        },
    },
    imgContainer: {
        textAlign: 'center',
    },
    footer: {
        width: '100%',
        padding: '12px 0px',
        backgroundColor: 'white',
        borderTop: `1px solid ${theme.palette.border.main}`,
        textAlign: 'center',
    },
}));

export default useStyles;
