import * as React from 'react';
import { Box, Typography, AppBar, IconButton, Toolbar, Hidden } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import MobileDrawer from './Drawer';
import Logo from 'assets/images/logos/logo-black.png';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItems } from './data';
import { getNestedProperty } from 'utils/nested-key';
import UserRedux from 'redux/actions/user';
import AccountButton from 'components/Widgets/AccountButton/AccountButton';
import NotificationsList from 'components/Widgets/NotificationsList/NotificationsList';

export default function Navigation({ children }) {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        setDrawerOpen(false);
    }, [location.pathname]);

    async function handleLogout() {
        setDrawerOpen(false);
        await dispatch(UserRedux.signOut());
    }

    return (
        <Box sx={{ flexGrow: 1, paddingBottom: 6 }}>
            <AppBar position="sticky" classes={{ root: classes.appBar }}>
                <Toolbar classes={{ root: classes.toolbar }}>
                    <Hidden smDown>
                        <img src={Logo} className={classes.logo} />
                    </Hidden>
                    <Hidden smUp>
                        <IconButton onClick={() => setDrawerOpen(true)}>
                            <Menu />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        <Box sx={{ flexGrow: 1 }} className={classes.navigation}>
                            {MenuItems.map((item) => {
                                if (item.requirement) {
                                    const requirement = getNestedProperty(user, item.requirement);
                                    if (!requirement) return null;
                                }

                                return (
                                    <Link to={item.pathname} key={item.pathname}>
                                        <Box
                                            className={clsx(classes.navItem, {
                                                [classes.activeItem]: location.pathname === item.pathname,
                                            })}
                                        >
                                            <item.Icon />
                                            <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                {item.name}
                                            </Typography>
                                        </Box>
                                    </Link>
                                );
                            })}
                        </Box>
                    </Hidden>
                    <Box className={classes.rightContent}>
                        <NotificationsList />
                        <AccountButton />
                    </Box>
                </Toolbar>
            </AppBar>
            <MobileDrawer open={drawerOpen} onLogout={handleLogout} onClose={() => setDrawerOpen(false)} />
            {children}
        </Box>
    );
}
