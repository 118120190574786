import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    /* STAT SECTION */
    sectionContainer: {
        backgroundColor: 'white',
        boxShadow: theme.boxShadows[0],
        borderRadius: 6,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    headerInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    statInfo: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    statSelector: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        backgroundColor: 'rgb(232, 232, 232)',
        height: 1,
        width: '100%',
        marginTop: '0.75em',
        marginBottom: '0.75em',
    },

    /** Stat Ranking Chart */
    statTitle: {
        cursor: 'pointer',
    },
    activeTitle: {
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.Gray1.main,
    },
    statUnderline: {
        backgroundColor: 'rgb(232, 232, 232)',
        height: 1,
        width: '100%',
        position: 'sticky',
    },

    rankingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid',
        borderRadius: '5px',
        cursor: 'pointer',
    },

    /** Auxiliary */
    center: {
        display: 'flex',
        alignItems: 'center',
    },

    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Two equal-width columns
        gap: '12px',
    },
}));

export default useStyles;
