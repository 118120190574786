import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import Dropdown from 'components/Dropdown/Dropdown';
import FilterList from 'components/Widgets/FilterList/FilterList';
import AdminFilters from './Filters';
import dayjs from 'dayjs';
import { isSubscriptionActive } from 'utils/active-subscription';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
import { CSVLink } from 'react-csv';
import clsx from 'clsx';

// MUI
import { Box, Button, Chip, Tooltip, Skeleton, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// Styles
import useStyles from './styles';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';

const TableHeaders = ['Name', 'Email', 'Profile', 'Subscribed', 'Eng. Rate', 'Joined'];

const translation = {
    Name: 'User.firstName',
    Email: 'User.email',
    Profile: 'User.persona',
    Subscribed: 'subStatus',
    'Eng. Rate': 'engagement',
    Joined: 'User.createdAt',
};

export default function UserTable({ openDrawer, refresh }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [users, setUsers] = useState({ items: [], count: 0 });
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'Name',
    });
    const _dropdownClose = useRef();
    const _filters = useRef([]);
    const [filters, setFilters] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selected, setSelected] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const selectedCount = Object.keys(selected).length;

    useEffect(() => {
        getUsers();
    }, [sort, page, perPage, filters, refresh]);

    async function getUsers() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];

            let search = '';
            filters.forEach((filter) => {
                search += `&${filter.field}=${filter.value}`;
            });

            const response = await SeedscoutAPI.get(
                `/v1/admin/users?perPage=${perPage}&page=${page}&order=${sort.order}&orderBy=${orderByTl}${search}`,
            );
            setUsers(response.data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }

        setLoading(false);
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // const OGSelected = {...selected};
            const newSelected = users.items.reduce((accumulator, user) => {
                accumulator[user.UserId] = user;
                return accumulator;
            }, {});
            setSelected({ ...selected, ...newSelected });
            setIsButtonDisabled(false);
            return;
        }
        setSelected({});
        setIsButtonDisabled(true);
    };

    const handleCheckBoxClick = (event, id) => {
        event.stopPropagation();
        let newSelected = { ...selected };
        let selectedUser = users.items.find((user) => user.UserId === id);

        if (newSelected[id]) {
            delete newSelected[id];
        } else {
            newSelected[id] = selectedUser;
        }

        setSelected(newSelected);
        setIsButtonDisabled(Object.keys(newSelected).length === 0);
    };

    const csvData = [
        ['Name', 'Email', 'Profile', 'Subscribed', 'Engagement Rate', 'Joined'],
        ...Object.values(selected).map(({ User, engagement }) => [
            `${User.firstName} ${User.lastName}`,
            User.email,
            User.persona,
            isSubscriptionActive(User?.stripe?.subscription) ? 'Yes' : 'No',
            `${(engagement * 100).toFixed(0)}%`,
            dayjs(User.createdAt).format('MM/DD/YYYY'),
        ]),
    ];

    function handleAddFilter({ value, Icon, field, label }) {
        _filters.current.push({ value, Icon, field, label });
        setFilters([..._filters.current]);
        _dropdownClose.current();
    }

    function handleRemoveFilter(idx) {
        _filters.current.splice(idx, 1);
        setFilters([..._filters.current]);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    const resetSelected = () => {
        setSelected({});
        setIsButtonDisabled(true);
    };

    return (
        <Card title="Seedscout Users">
            <Box
                className={clsx(classes.boxLayout, {
                    [classes.spacedBoxLayout]: !isButtonDisabled,
                })}
            >
                {!isButtonDisabled && (
                    <Box className={classes.label} sx={{ ml: 2 }}>
                        <Typography variant="font5" color={'Gray0.main'}>
                            {selectedCount > 1
                                ? `${selectedCount} Profiles selected`
                                : `${selectedCount} Profile selected`}
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<RestartAltIcon />}
                            onClick={resetSelected}
                            sx={{ mr: 2 }}
                        >
                            Reset
                        </Button>
                    </Box>
                )}
                <Box className={classes.boxLayout}>
                    <Box className={classes.buttonLayout}>
                        {isButtonDisabled ? (
                            <Button variant="contained" startIcon={<ExitToAppIcon />} disabled sx={{ mr: 2 }}>
                                Export to CSV
                            </Button>
                        ) : (
                            <CSVLink data={csvData} filename="seedscout_user_data.csv">
                                <Button variant="contained" startIcon={<ExitToAppIcon />} sx={{ mr: 2 }}>
                                    Export to CSV
                                </Button>
                            </CSVLink>
                        )}
                    </Box>
                    <Dropdown
                        title="Filter"
                        close={(fn) => {
                            _dropdownClose.current = fn;
                        }}
                        buttonProps={{
                            startIcon: <FilterListIcon />,
                        }}
                    >
                        <FilterList filters={AdminFilters} onAddFilter={handleAddFilter} />
                    </Dropdown>
                </Box>
            </Box>
            <Box className={classes.filters} sx={{ marginTop: 2, marginBottom: 1 }}>
                {filters.map((filter, i) => {
                    return (
                        <Tooltip arrow title={`${filter.label} Filter`}>
                            <Chip
                                icon={<filter.Icon />}
                                key={`chip-${i}`}
                                label={filter.value}
                                onDelete={() => handleRemoveFilter(i)}
                            />
                        </Tooltip>
                    );
                })}
            </Box>
            <CustomTable
                page={page}
                count={users.count}
                onSort={handleOnSort}
                rowsPerPage={perPage}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                pagination={true}
                dense={true}
                check={true}
                adminTable={true}
                cellPadding="6px"
                onSelectAllClick={handleSelectAllClick}
                handleClick={handleCheckBoxClick}
                openProfileDrawer={openDrawer}
                selected={selected}
                initialOrderIndex={0}
                headers={TableHeaders}
                rows={
                    loading
                        ? Array.from(new Array(5)).map((_, i) => {
                              return {
                                  columns: [<Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />],
                              };
                          })
                        : users.items.map((item) => {
                              return {
                                  navigate: {
                                      //   target: '_blank',
                                  },
                                  id: item.UserId,
                                  columns: [
                                      <Typography variant="font5">
                                          {item.User.firstName} {item.User?.lastName}
                                      </Typography>,
                                      <Typography variant="font5" sx={{ maxWidth: 200 }}>
                                          {item.User?.email}
                                      </Typography>,
                                      <Typography variant="font5" sx={{ maxWidth: 200 }}>
                                          {item.User?.persona}
                                      </Typography>,
                                      <Typography variant="font5" sx={{ maxWidth: 200 }}>
                                          {isSubscriptionActive(item?.User?.stripe?.subscription) ? 'Yes' : 'No'}
                                      </Typography>,
                                      <Typography variant="font5" sx={{ maxWidth: 200 }}>
                                          {(item?.engagement * 100).toFixed(0)}%
                                      </Typography>,
                                      <Typography variant="font5" sx={{ maxWidth: 200 }}>
                                          {dayjs(item.User.createdAt).format('MM/DD/YYYY')}
                                      </Typography>,
                                  ],
                              };
                          })
                }
            />
        </Card>
    );
}
