import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { FOUNDER_SECTORS } from 'utils/constants';
import NumericInput from 'components/inputs/NumericInput';
import clsx from 'clsx';

export default function UpdateFounderDialog({ open, profile, onClose, onEdit }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            companyName: profile?.meta?.companyName || '',
            sector: profile?.meta?.sector || '',
            startupURL: profile?.meta?.startupURL || '',
            oneLiner: profile?.meta?.oneLiner || '',
            stage: profile?.meta?.stage || '',
            fullTime: profile?.meta?.fullTime || '',
            employees: profile?.meta?.employees || '',
            raised: profile?.meta?.raised || '',
            amountRaised: profile?.meta?.amountRaised || '',
            ownerPercent: profile?.meta?.ownerPercent || '',
            hasUsers: profile?.meta?.hasUsers || '',
            activeUsers: profile?.meta?.activeUsers || '',
            profitable: profile?.meta?.profitable || '',
        },
    });

    const sector = watch('sector');
    const fullTime = watch('fullTime', '');
    const raised = watch('raised', '');
    const hasUsers = watch('hasUsers', '');

    async function handleUpdate(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch(`/v1/users/${profile.UserId}`, values);
            onEdit(response.data);
            onClose?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Box className={classes.dialogTitle} id="alert-dialog-title">
                <Typography variant="font1">Edit Profile</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleUpdate)} mt={1}>
                    <input style={{ display: 'none' }} type="submit" />

                    <Box className={classes.fieldContainer}>
                        <Typography>What is your company's name?</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="Jerry's Springer's"
                            error={!!errors.companyName}
                            helperText={errors?.companyName?.message}
                            {...register('companyName', {})}
                        />
                    </Box>

                    <Typography>What Sector are you in?</Typography>
                    <Autocomplete
                        freeSolo
                        value={sector || ''}
                        inputValue={sector || ''}
                        onInputChange={(e, v) => {
                            setValue('sector', v);
                        }}
                        options={FOUNDER_SECTORS}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!errors.sector}
                                helperText={errors?.sector?.message}
                                {...register('sector', {})}
                            />
                        )}
                    />
                </Box>
                <Box className={classes.fieldContainer}>
                    <Typography>What is your startup's URL?</Typography>
                    <TextField
                        className={classes.field}
                        fullWidth
                        placeholder="https://my-company-website.com"
                        error={!!errors.startupURL}
                        helperText={errors?.startupURL?.message}
                        {...register('startupURL', {})}
                    />
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>What is your startup's one liner?</Typography>
                    <TextField
                        rows={4}
                        multiline
                        className={classes.field}
                        fullWidth
                        error={!!errors.oneLiner}
                        helperText={errors?.oneLiner?.message}
                        {...register('oneLiner', {})}
                    />
                </Box>

                <Box className={classes.fieldContainer}>
                    <Typography>Current Product Stage</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.stage)}
                        defaultValue={profile?.meta?.stage || ''}
                        {...register('stage', {})}
                    >
                        <MenuItem value="">Select your stage</MenuItem>
                        <MenuItem value="Idea">Idea</MenuItem>
                        <MenuItem value="MVP/Prototyping">MVP/Prototyping</MenuItem>
                        <MenuItem value="Live product being used">Live product being used</MenuItem>
                    </Select>
                </Box>
                <Box className={classes.fieldContainer}>
                    <Typography>
                        Are you full time on <span className="underline">{profile.meta?.companyName}</span>?
                    </Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.fullTime)}
                        defaultValue={profile?.meta?.fullTime || ''}
                        {...register('fullTime', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </Box>
                {fullTime === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>How many people besides yourself are current working?</Typography>
                        <NumericInput
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`employees`]}
                            helperText={errors?.[`employees`]?.message}
                            {...register(`employees`, {})}
                        />
                    </Box>
                )}
                <Box className={classes.fieldContainer}>
                    <Typography>Have you raised money yet?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.raised)}
                        defaultValue={profile?.meta?.raised || ''}
                        {...register('raised', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </Box>
                {raised === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>How much have you currently raised?</Typography>
                        <NumericInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`amountRaised`]}
                            helperText={errors?.[`amountRaised`]?.message}
                            {...register(`amountRaised`, {})}
                        />
                    </Box>
                )}
                {/* {raised === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>
                            How much ownership do you have?
                        </Typography>
                        <NumericInput
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            className={classes.field}
                            fullWidth
                            precision={2}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`ownerPercent`]}
                            helperText={errors?.[`ownerPercent`] && 'Choose a value between 0 and 100'}
                            {...register(`ownerPercent`, {
                                validate: (value) => {
                                    console.log(value);
                                    return value?.stringToNumber?.() >= 0 && value?.stringToNumber?.() <= 100;
                                },
                            })}
                        />
                    </Box>
                )} */}

                <Box className={classes.fieldContainer}>
                    <Typography>
                        Do you have users/customers using <span className="underline">{profile.meta?.companyName}</span>
                        ?
                    </Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.hasUsers)}
                        defaultValue={profile?.meta?.hasUsers || ''}
                        {...register('hasUsers', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </Box>
                {hasUsers === 'Yes' && (
                    <Box className={clsx(classes.fieldContainer, classes.innerField)}>
                        <Typography>How many users/customers do you have?</Typography>
                        <NumericInput
                            className={classes.field}
                            fullWidth
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            error={!!errors?.[`activeUsers`]}
                            helperText={errors?.[`activeUsers`]?.message}
                            {...register(`activeUsers`, {})}
                        />
                    </Box>
                )}

                <Box className={classes.fieldContainer}>
                    <Typography>Are you current making money?</Typography>
                    <Select
                        className={classes.field}
                        displayEmpty
                        fullWidth
                        error={Boolean(errors.profitable)}
                        defaultValue={profile?.meta?.profitable || ''}
                        {...register('profitable', {})}
                    >
                        <MenuItem value="">Select a value</MenuItem>
                        <MenuItem value="Yes, profitable">Yes, profitable</MenuItem>
                        <MenuItem value="Yes, but not profitable">Yes, but not profitable</MenuItem>
                        <MenuItem value="No, we aren’t making money">No, we aren’t making money</MenuItem>
                    </Select>
                </Box>
            </DialogContent>
            <Box className={classes.actionContainer}>
                <LoadingButton variant="contained" loading={submitting} onClick={handleSubmit(handleUpdate)}>
                    Update
                </LoadingButton>
            </Box>
        </Dialog>
    );
}
