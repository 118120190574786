import React from 'react';
import Logo from 'assets/images/logos/logo_text.png';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// MUI
import { Alert, Box, Typography } from '@mui/material';
// Components
import BasicInformation from './BasicInformation/BasicInformation';
import ProfilePhoto from './ProfilePhoto/ProfilePhoto';
import UserLocation from './UserLocation/UserLocation';
import Persona from './Persona/Persona';
import StartupInformation from './Founder/StartupInformation/StartupInformation';
import ProductInformation from './Founder/ProductInformation/ProductInformation';
import PitchDeck from './Founder/PitchDeck/PitchDeck';
// STyles
import useStyles from './styles';
import InvestorDetails from './Investor/InvestorDetails/InvestorDetails';
import InvestorType from './Investor/InvestorType/InvestorType';
import InvestorPast from './Investor/InvestorPast/InvestorPast';
import FounderLogo from './Founder/FounderLogo/FounderLogo';
import InvestorLogo from './Investor/InvestorLogo/InvestorLogo';
import JobSeekerDetails from './JobSeeker/JobSeekerDetails/JobSeekerDetails';

export default function FinishSignup() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    function handleError(error) {
        dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
    }

    let Component = BasicInformation;
    if (user?.metadata?.signup === 'Photo') Component = ProfilePhoto;
    if (user?.metadata?.signup === 'Location') Component = UserLocation;
    if (user?.metadata?.signup === 'Persona') Component = Persona;
    if (user.ActiveProfile && user?.metadata?.signup === 'Finished') {
        //Founder
        if (user.ActiveProfile.type === 'Founder') Component = StartupInformation;
        if (user.ActiveProfile.type === 'Founder' && user.ActiveProfile?.meta?.signup === 'Logo')
            Component = FounderLogo;
        if (user.ActiveProfile.type === 'Founder' && user.ActiveProfile?.meta?.signup === 'Product')
            Component = ProductInformation;
        if (user.ActiveProfile.type === 'Founder' && user.ActiveProfile?.meta?.signup === 'PitchDeck')
            Component = PitchDeck;
        // Investor
        if (user.ActiveProfile.type === 'Investor') Component = InvestorDetails;
        if (user.ActiveProfile.type === 'Investor' && user.ActiveProfile?.meta?.signup === 'Logo')
            Component = InvestorLogo;
        if (user.ActiveProfile.type === 'Investor' && user.ActiveProfile?.meta?.signup === 'Type')
            Component = InvestorType;
        if (user.ActiveProfile.type === 'Investor' && user.ActiveProfile?.meta?.signup === 'Investments')
            Component = InvestorPast;

        // Job Seeker
        if (user.ActiveProfile.type === 'Job Seeker') Component = JobSeekerDetails;
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.form}>
                <Box className={classes.imgContainer}>
                    <img src={Logo} />
                </Box>
                {!user?.metadata?.signup && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        <span className="bold">Alert!</span> Since you logged in last we have updated our application!
                        Migrate your account to our latest data set to get started!
                    </Alert>
                )}
                <Component onError={handleError} />
            </Box>
            <Box className={classes.footer} sx={{ mt: 4 }}>
                <Typography>@{new Date().getFullYear()} Seedscout</Typography>
            </Box>
        </Box>
    );
}
