export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const INIT = { items: [], count: 0 };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_NOTIFICATIONS:
            return payload;
        case NOTIFICATIONS_ERROR:
        default:
            return state;
    }
}

export default reducer;
