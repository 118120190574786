import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import DefaultUser from 'assets/images/icons/default-user.png';
import useStyles from './styles';
import StripeWidget from 'components/Widgets/StripeWidget/StripeWidget';
import clsx from 'clsx';
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
import UserRedux from 'redux/actions/user';

export default function Setting() {
    const classes = useStyles();
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);

    async function handleLogout() {
        dispatch(UserRedux.signOut());
    }

    return (
        <Container maxWidth={'lg'} sx={{ mt: 4 }}>
            <Box className={classes.root}>
                <Box
                    className={classes.background}
                    sx={{
                        mb: 4,
                        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://seedscout-public.s3.amazonaws.com/banners/${
                            user.id % 11
                        }.jpg)`,
                    }}
                ></Box>

                <Grid container spacing={6}>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.header}>
                            <img src={user.photo || DefaultUser} className={classes.userPhoto} />
                            <Box className={classes.userInfo}>
                                <Typography variant="font3" fontWeight={'600'}>
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <Typography variant="font5" color="lightText.main">
                                    {user.email}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.tabs}>
                            <Box
                                onClick={() => setTab(0)}
                                className={clsx(classes.tab, {
                                    [classes.activeTab]: tab === 0,
                                })}
                            >
                                <Typography>Subscription</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.logout}>
                            <Button size="small" variant="contained" onClick={handleLogout}>
                                Logout
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TabPanel value={tab} index={0}>
                            <Box className={classes.column}>
                                <StripeWidget />
                            </Box>
                        </TabPanel>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
