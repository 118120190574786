import { createTheme } from '@mui/material/styles';

export const AppColors = {
    /** Main Colors */
    Primary: '#EA5C0B',
    Purple: '#35248B',
    DarkBlue: '#0648AF',
    OffWhite: '#f4f7fa',
    Gray0: '#9fa4b5',
    Gray1: '#737681',
    Green: '#2C9532',
    Red: '#CF2127',
    Error: '#ff3333',
};

const palette = {};
Object.keys(AppColors).forEach((key) => {
    palette[key] = { main: AppColors[key], contrastText: '#fff' };
});

const { breakpoints } = createTheme({});

// Create a theme instance.
const theme = createTheme({
    colors: {
        ...AppColors,
    },
    palette: {
        ...palette,

        lightBodyText: {
            main: '#64748B',
        },
        lightTitleText: {
            main: '#94A3B8',
        },
        lightText: {
            main: '#818588',
        },
        lightButton: {
            main: '#f3f6f8',
            light: 'rgb(228, 236, 242)',
            dark: 'rgb(228, 236, 242)',
        },
        border: {
            main: '#e4e9f1',
        },
        primary: {
            main: '#EA5C0B',
        },
        secondary: {
            main: '#0546AF',
        },
        transparent: {
            main: '#00000000',
        },
    },
    typography: {
        fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,

        h1: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 64,

            [breakpoints.down('sm')]: {
                fontSize: 34,
            },
        },
        h6: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 24,
            fontWeight: '600',
        },
        font1: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 22,
            display: 'block',
        },
        font2: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 20,
            display: 'block',
        },
        font3: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 18,
            display: 'block',
        },
        font4: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 16,
            display: 'block',
        },
        font5: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 14,
            display: 'block',
        },
        font6: {
            fontFamily: `"Nunito Sans", Nunito, Montserrat, Roboto`,
            fontSize: 12,
            display: 'block',
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#262B30',
                    padding: '12px 18px',
                    fontSize: 14,
                },
                arrow: {
                    color: '#262B30',
                },
            },
        },
    },
    custom: {
        fieldBorderRadius: 2,
    },
    shadows: ['none', ...Array.from(new Array(26)).fill('rgba(0, 0, 0, 0.15) 0px 1px 5px 0px', 0, 25)],
    boxShadows: {
        0: 'rgba(0, 0, 0, 0.15) 0px 1px 5px 0px',
    },
});

export default theme;
