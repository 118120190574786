import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: theme.spacing(5),
    },
    box: {
        backgroundColor: 'white',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
}));

export default useStyles;
