import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';
import Lottie from 'lottie-react';
import EmptyViews from 'assets/animations/no-views.json';
import ViewsItem from './ViewsItem/ViewsItem';

export default function ViewsCard() {
    const classes = useStyles();
    const [views, setViews] = useState({ items: [], count: 0 });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function _getViews() {
            setLoading(true);
            try {
                const response = await SeedscoutAPI.get('/v1/views?perPage=4&order=DESC&orderBy=createdAt');
                setViews(response.data);
            } catch (error) {
                dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
            }
            setLoading(false);
        }

        _getViews();
    }, []);

    return (
        <Card title="Recently Viewed">
            {loading && (
                <Box className={classes.skeleton}>
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                </Box>
            )}
            <Box className={classes.list}>
                {!loading && views?.items?.length == 0 && (
                    <Box className={classes.animationContainer}>
                        <Typography>You haven't viewed anyone yet!</Typography>
                        <Lottie loop={true} animationData={EmptyViews} className={classes.animation} />
                    </Box>
                )}
                {views?.items?.map?.((view, i) => {
                    return <ViewsItem view={view} key={i} />;
                })}
            </Box>
        </Card>
    );
}
