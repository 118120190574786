import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    input: {
        display: 'none',
    },
    profileImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    },
    profileImageView: {
        display: 'flex',
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    editImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

        position: 'absolute',
        height: '100%',
        width: '100%',
        borderRadius: '50%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
    },
}));

export default useStyles;
