import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Custom
import {
    Box,
    Container,
    Typography,
    Skeleton,
    Link as MuiLink,
    TablePagination,
    Breadcrumbs,
    InputAdornment,
    TextField,
    MenuItem,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import StripeWidget from 'components/Widgets/StripeWidget/StripeWidget';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';
import InvestorListCard from 'components/Cards/InvestorListCard/InvestorListCard';
import { Link } from 'react-router-dom';
import FounderListCard from 'components/Cards/FounderListCard/FounderListCard';
import JobSeekerListCard from 'components/Cards/JobSeekerListCard/JobSeekerListCard';
import cloneDeep from 'lodash/cloneDeep';

import useStyles from './styles';

export default function Bookmarks(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const [bookmarks, setBookmarks] = useState({ items: [], count: 0 });
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'createdAt',
        value: 'Newest',
    });
    const [search, setSearch] = useState('');

    const activeSubscription = user?.ActiveSubscription?.status === 'active' || (user?.requests?.intros || 0) !== 0;

    useEffect(() => {
        if (activeSubscription) getBookmarks();
    }, [sort, page, perPage]);

    async function getBookmarks() {
        setLoading(true);
        try {
            const response = await SeedscoutAPI.get(
                `/v1/bookmarks?perPage=${perPage}&page=${page}&order=${sort.order}&orderBy=${sort.orderBy}&search=${search}`,
            );
            setBookmarks(response.data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleSort = (e) => {
        let orderBy = 'createdAt';
        let order = 'DESC';
        if (e.target.value === 'Oldest') order = 'ASC';

        setSort({ order, orderBy, value: e.target.value });
    };

    function handleRemove(idx) {
        const _bookmarks = cloneDeep(bookmarks);
        _bookmarks.items.splice(idx, 1);
        _bookmarks.count--;
        setBookmarks(_bookmarks);
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <Box className={classes.header} sx={{ mb: 4 }}>
                <Typography variant="h4" fontWeight={'600'}>
                    My Bookmarks
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <MuiLink component={Link} underline="hover" color="inherit" to="/">
                        <Typography>Dashboard</Typography>
                    </MuiLink>
                    <Typography color="primary">Bookmarks</Typography>
                </Breadcrumbs>
            </Box>

            <Box className={classes.filterActions} sx={{ mb: 2 }}>
                <TextField
                    size="small"
                    placeholder="Search user name here"
                    className={classes.searchField}
                    value={search}
                    sx={{ minWidth: { xs: 'auto', md: '400px' } }}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            getBookmarks();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search sx={{ fontSize: 16 }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box display={'flex'} gap={'12px'} alignItems={'center'}>
                    <TextField
                        value={sort.value}
                        onChange={handleSort}
                        size="small"
                        className={classes.searchField}
                        select
                    >
                        <MenuItem value="Newest">Newest</MenuItem>
                        <MenuItem value="Oldest">Oldest</MenuItem>
                    </TextField>
                </Box>
            </Box>

            {!activeSubscription ? (
                <StripeWidget sx={{ marginTop: 2 }} />
            ) : (
                <>
                    <Box className={classes.list}>
                        {loading &&
                            Array.from(new Array(6)).map((_, i) => {
                                return (
                                    <Skeleton
                                        height={118}
                                        key={i}
                                        width={'100%'}
                                        variant="rectangular"
                                        sx={{ borderRadius: '2px' }}
                                    />
                                );
                            })}
                        {!loading &&
                            bookmarks.items.map((bookmark, i) => {
                                const profile = bookmark.Profile;
                                if (profile?.type === 'Investor') {
                                    return (
                                        <InvestorListCard onRemove={() => handleRemove(i)} key={i} profile={profile} />
                                    );
                                } else if (profile?.type === 'Founder') {
                                    return (
                                        <FounderListCard onRemove={() => handleRemove(i)} key={i} profile={profile} />
                                    );
                                } else if (profile?.type === 'Job Seeker') {
                                    return (
                                        <JobSeekerListCard onRemove={() => handleRemove(i)} key={i} profile={profile} />
                                    );
                                }
                            })}
                    </Box>

                    {!loading && bookmarks.items.length === 0 && (
                        <Card cardOnly>
                            <Box className={classes.emptyTable}>
                                <Typography variant="font4">No Bookmarks Found!</Typography>
                            </Box>
                        </Card>
                    )}
                    <TablePagination
                        sx={{ justifyContent: 'flex-end', mt: 2 }}
                        color="primary"
                        component="div"
                        count={bookmarks.count}
                        page={page}
                        rowsPerPage={perPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRows}
                    />
                </>
            )}
        </Container>
    );
}
