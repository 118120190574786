import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
// Custom
import {
    Box,
    Container,
    Typography,
    Skeleton,
    Link as MuiLink,
    Chip,
    Tooltip,
    Button,
    TablePagination,
    Breadcrumbs,
    InputAdornment,
    TextField,
    MenuItem,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import StripeWidget from 'components/Widgets/StripeWidget/StripeWidget';
import Dropdown from 'components/Dropdown/Dropdown';
import FilterList from 'components/Widgets/FilterList/FilterList';
import FounderFilters from './Filters';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';
import { Link } from 'react-router-dom';

import useStyles from './styles';
import FounderListCard from 'components/Cards/FounderListCard/FounderListCard';

export default function Founders(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const [founders, setProfiles] = useState({ items: [], count: 0 });
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: Math.floor(Math.random() * (50000 - 1) + 1),
        orderBy: 'Random',
        value: 'Random',
    });
    const _dropdownClose = useRef();
    const [search, setSearch] = useState('');
    const _filters = useRef([]);
    const [filters, setFilters] = useState([]);

    const activeSubscription = user?.ActiveSubscription?.status === 'active' || (user?.requests?.intros || 0) !== 0;

    useEffect(() => {
        if (activeSubscription) getFounders();
    }, [sort, page, perPage, filters]);

    async function getFounders() {
        setLoading(true);
        try {
            let params = '';
            filters.forEach((filter) => {
                params += `&${filter.field}=${filter.value}`;
            });

            if (search) {
                params += `&name=${search}`;
            }

            const response = await SeedscoutAPI.get(
                `/v1/profiles?type=Founder&perPage=${perPage}&page=${page}&order=${sort.order}&orderBy=${sort.orderBy}${params}`,
            );
            setProfiles(response.data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoading(false);
    }

    function handleAddFilter({ value, Icon, field, label }) {
        _filters.current.push({ value, Icon, field, label });
        setFilters([..._filters.current]);
        _dropdownClose.current();
    }

    function handleRemoveFilter(idx) {
        _filters.current.splice(idx, 1);
        setFilters([..._filters.current]);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleSort = (e) => {
        let order = 'DESC';
        let orderBy = 'User.createdAt';
        if (e.target.value === 'Oldest') order = 'ASC';
        if (e.target.value === 'LastEngaged') orderBy = 'User.lastEngageDate';
        if (e.target.value === 'Random') {
            order = Math.floor(Math.random() * (50000 - 1) + 1);
            orderBy = 'Random';
        }

        setSort({ order, orderBy, value: e.target.value });
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <Box className={classes.header} sx={{ mb: 4 }}>
                <Typography variant="h4" fontWeight={'600'}>
                    Founders
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <MuiLink component={Link} underline="hover" color="inherit" to="/">
                        <Typography>Dashboard</Typography>
                    </MuiLink>
                    <Typography color="primary">Founders</Typography>
                </Breadcrumbs>
            </Box>

            <Box className={classes.filterActions} sx={{ mb: 2 }}>
                <TextField
                    size="small"
                    placeholder="Search founders name here"
                    className={classes.searchField}
                    value={search}
                    sx={{ minWidth: { xs: 'auto', md: '400px' } }}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            getFounders();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search sx={{ fontSize: 16 }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box display={'flex'} gap={'12px'} alignItems={'center'}>
                    <Dropdown
                        title="Add Filter"
                        close={(fn) => {
                            _dropdownClose.current = fn;
                        }}
                        buttonProps={{
                            startIcon: <Add />,
                        }}
                    >
                        <FilterList filters={FounderFilters} onAddFilter={handleAddFilter} />
                    </Dropdown>

                    <TextField
                        value={sort.value}
                        onChange={handleSort}
                        size="small"
                        className={classes.searchField}
                        select
                    >
                        <MenuItem value="Random">Random</MenuItem>
                        <MenuItem value="Newest">Newest</MenuItem>
                        <MenuItem value="Oldest">Oldest</MenuItem>
                        <MenuItem value="LastEngaged">Last Engaged</MenuItem>
                    </TextField>
                </Box>
            </Box>
            {filters.length > 0 && (
                <Box className={classes.filters} sx={{ mb: 2 }}>
                    {filters.map((filter, i) => {
                        return (
                            <Tooltip arrow title={`${filter.label} Filter`}>
                                <Chip
                                    icon={<filter.Icon />}
                                    key={`chip-${i}`}
                                    label={filter.value}
                                    onDelete={() => handleRemoveFilter(i)}
                                />
                            </Tooltip>
                        );
                    })}
                </Box>
            )}
            {!activeSubscription ? (
                <StripeWidget sx={{ marginTop: 2 }} />
            ) : (
                <>
                    <TablePagination
                        sx={{ justifyContent: 'flex-end', mt: 2 }}
                        color="primary"
                        component="div"
                        count={founders.count}
                        page={page}
                        rowsPerPage={perPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRows}
                    />
                    <Box className={classes.list}>
                        {loading &&
                            Array.from(new Array(6)).map((_, i) => {
                                return (
                                    <Skeleton
                                        height={118}
                                        key={i}
                                        width={'100%'}
                                        variant="rectangular"
                                        sx={{ borderRadius: '2px' }}
                                    />
                                );
                            })}
                        {!loading &&
                            founders.items.map((profile, i) => {
                                return <FounderListCard key={i} profile={profile} />;
                            })}
                    </Box>

                    {!loading && founders.items.length === 0 && (
                        <Card cardOnly>
                            <Box className={classes.emptyTable}>
                                <Typography variant="font4">No Founders Found!</Typography>
                            </Box>
                        </Card>
                    )}
                    <TablePagination
                        sx={{ justifyContent: 'flex-end', mt: 2 }}
                        color="primary"
                        component="div"
                        count={founders.count}
                        page={page}
                        rowsPerPage={perPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRows}
                    />
                </>
            )}
        </Container>
    );
}
