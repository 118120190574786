import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useStyles from './styles';
import UserRedux from 'redux/actions/user';
import { Close } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import FileInput from 'components/inputs/FileInput/FileInput';

export default function UpdatePitchDeckDialog({ open, onEdit, onClose, profile }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState(null);

    function handleFileChanged(value) {
        if (!value) return setFile('file', null);
        setFile(value);
    }

    async function handleUpdate() {
        setSubmitting(true);
        try {
            let body = {};
            if (file) {
                body = new FormData();
                body.append('file', file);
            }

            const response = await SeedscoutAPI.patch(`/v1/profiles/${profile.UserId}/pitch-deck`, body);
            onEdit(response.data);
            onClose?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    async function onError(error) {
        dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Box className={classes.dialogTitle} id="alert-dialog-title">
                <Typography variant="font1">Update Pitch Deck</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <FileInput onChange={handleFileChanged} onError={onError} />
            </DialogContent>
            <Box className={classes.actionContainer}>
                <LoadingButton variant="contained" loading={submitting} onClick={handleUpdate}>
                    Update
                </LoadingButton>
            </Box>
        </Dialog>
    );
}
