import React, { useEffect, useState } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';
import Lottie from 'lottie-react';
import EmptyTable from 'assets/animations/bookmark.json';
import BookmarksItem from './BookmarkItem/BookmarkItem';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';

export default function BookmarksCard() {
    const classes = useStyles();
    const [bookmarks, setBookmarks] = useState({ items: [], count: 0 });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function _getBookmarks() {
            setLoading(true);
            try {
                const response = await SeedscoutAPI.get('/v1/bookmarks?perPage=8');
                setBookmarks(response.data);
            } catch (error) {
                dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
            }
            setLoading(false);
        }

        _getBookmarks();
    }, []);

    function handleRemove(idx) {
        const _bookmarks = cloneDeep(bookmarks);
        _bookmarks.items.splice(idx, 1);
        _bookmarks.count--;
        setBookmarks(_bookmarks);
    }

    return (
        <Card
            title="My Bookmarks"
            actions={
                <Button to="/bookmarks" component={Link}>
                    View All
                </Button>
            }
        >
            {loading && (
                <Box className={classes.skeleton}>
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                    <Skeleton height="60px" variant="rectangular" width="100%" />
                </Box>
            )}
            <Box className={classes.list}>
                {!loading && bookmarks?.items?.length == 0 && (
                    <Box className={classes.animationContainer}>
                        <Typography>You haven't bookmarked anyone yet!</Typography>
                        <Lottie loop={false} animationData={EmptyTable} className={classes.animation} />
                    </Box>
                )}
                {bookmarks?.items?.map?.((mark, i) => {
                    return <BookmarksItem onRemove={() => handleRemove(i)} mark={mark} key={i} />;
                })}
            </Box>
        </Card>
    );
}
