import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Snackbar from './components/Widgets/Snackbar';
import Authorizer from './Authorizer';
import IntroResponse from 'containers/Other/IntroResponse';

const Router = (props) => {
    return (
        <React.Fragment>
            <Snackbar />
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Authorizer />} />

                    <Route
                        path="/intro-response/:id"
                        element={<IntroResponse />}
                    />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default Router;
