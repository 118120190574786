import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    editContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 8,
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
}));

export default useStyles;
