import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { getEngagementData } from './data';
import ProfileDrawerSkeleton from 'components/Skeletons/ProfileDrawer/ProfileDrawerSkeleton';
//MUI
import { Box, Button, Drawer, Grid, Switch, Typography } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { Hiking, Explore, Work } from '@mui/icons-material';
import { GiTakeMyMoney } from 'react-icons/gi';
//STYLES
import { AppColors } from 'theme';
import useStyles from './styles';
import clsx from 'clsx';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { LoadingButton } from '@mui/lab';
import { useConfirm } from 'material-ui-confirm';

const personaIcons = {
    Investor: <GiTakeMyMoney size="1.5em" />,
    'Job Seeker': <Work fontSize="10px" />,
    Founder: <Hiking fontSize="10px" />,
};

export default function ProfileDrawer(props) {
    const { setProfileId, onClose, onDelete, profileID } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loadingConnections, setLoadingConnections] = useState(false);
    const dispatch = useDispatch();
    const [profile, setProfile] = useState({});
    const [connectionList, setConnectionList] = useState([]);
    const [introSwitch, setIntroSwitch] = useState(true);
    const [autoIntroSwitch, setAutoIntroSwitch] = useState(false);
    const engagementData = getEngagementData(profile);
    const confirm = useConfirm();

    useEffect(() => {
        if (profileID) {
            getProfile();
            getConnections();
        }
    }, [profileID]);

    async function getProfile() {
        setLoading(true);
        try {
            const response = await SeedscoutAPI.get(`/v1/admin/users/${profileID}`);
            setProfile(response.data.profile);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }

        setLoading(false);
    }

    async function getConnections() {
        setLoadingConnections(true);
        try {
            const response = await SeedscoutAPI.get(`/v1/admin/connections/${profileID}`);
            const { connections } = response.data;

            setConnectionList(connections);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setLoadingConnections(false);
    }

    function engagementIntroSwitch() {
        setIntroSwitch(!introSwitch);
    }

    function engagementAutoIntroSwitch() {
        setAutoIntroSwitch(!autoIntroSwitch);
    }

    function handleDeleteUser() {
        confirm({
            description: `You are about to delete ${profile?.User?.firstName} ${profile?.User?.lastName}`,
        })
            .then(async () => {
                setDeleting(true);
                try {
                    await SeedscoutAPI.delete(`/v1/admin/users/${profileID}`);
                    onDelete?.(profileID);
                    onClose?.();
                } catch (error) {
                    dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
                }
                setDeleting(false);
            })
            .catch(() => {});
    }

    function handlePauseUser() {
        confirm({
            description: `You are about to pause ${profile?.User?.firstName} ${profile?.User?.lastName}`,
        })
            .then(async () => {
                setDeleting(true);
                try {
                    await SeedscoutAPI.patch(`/v1/admin/users/${profileID}/pause`);
                    dispatch(setSnackbar({ message: 'User paused' }));
                } catch (error) {
                    dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
                }
                setDeleting(false);
            })
            .catch(() => {});
    }

    return (
        <Drawer anchor="right" open={Boolean(profileID)} onClose={onClose}>
            {loading ? (
                <ProfileDrawerSkeleton onClose={onClose} />
            ) : (
                <Box className={classes.drawerContainer}>
                    <Box className={clsx(classes.section, classes.header)}>
                        <Typography variant="font4" sx={{ color: AppColors.Gray1, fontWeight: 700 }}>
                            Profile
                        </Typography>
                        <Box className={classes.headerIcons}>
                            <Box className={classes.icon}>
                                <IosShareOutlinedIcon />
                            </Box>
                            <Box onClick={onClose} className={classes.icon} sx={{ ml: 2 }}>
                                <CloseOutlinedIcon />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.section}>
                        <Box className={classes.profileInfo}>
                            <Typography variant="h6" color="primary">
                                {profile?.User?.firstName + ' ' + profile?.User?.lastName}
                            </Typography>
                            <Box className={classes.membership}>
                                <Typography
                                    variant="font6"
                                    sx={{
                                        color: AppColors.Green,
                                        p: 0.2,
                                        ml: 1,
                                        mr: 1,
                                    }}
                                >
                                    {Boolean(profile?.User?.ActiveSubscription) ? 'Paid' : 'Free'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="font6" sx={{ fontWeight: 700, mb: 1 }}>
                                {profile?.meta?.companyName}
                            </Typography>
                            <Box className={classes.userType} sx={{ mb: 1 }}>
                                {personaIcons[profile?.User?.persona] ?? <Hiking fontSize="10px" />}
                                <Typography variant="font6">
                                    {`${profile?.User?.persona} | ${
                                        profile?.meta?.startupSector ?? profile?.meta?.sectorInterests
                                    }`}
                                </Typography>
                            </Box>
                            <Box className={classes.userType}>
                                <Explore fontSize="10px" />
                                <Typography variant="font6">{profile?.User?.city}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.section}>
                        <Typography variant="font4" sx={{ color: AppColors.Gray1, fontWeight: 700 }}>
                            Engagement
                        </Typography>
                        {engagementData.map((item, i) => {
                            return (
                                <Grid container spacing={1} key={`row-${i}`}>
                                    <Grid item xs={6}>
                                        <Typography sx={{ color: AppColors.Gray1 }}>{item.title}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight: 700 }}>{item.data}</Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Box>
                    <Box className={classes.section}>
                        <Box className={classes.intros}>
                            <Typography
                                variant="font5"
                                sx={{
                                    color: introSwitch ? AppColors.Gray1 : 'rgba(128, 128, 128, 0.5)',
                                    fontWeight: 700,
                                }}
                            >
                                Intro Requests
                            </Typography>
                            <Box className={classes.centerRow}>
                                <Typography
                                    variant="font6"
                                    sx={{
                                        fontStyle: 'italic',
                                        color: introSwitch ? AppColors.Gray1 : 'rgba(128, 128, 128, 0.5)',
                                    }}
                                >
                                    {introSwitch ? 'Enabled' : 'Disabled'}
                                </Typography>
                                <Switch color="primary" defaultChecked onChange={engagementIntroSwitch} />
                            </Box>
                        </Box>
                        <Typography
                            variant="font5"
                            sx={{
                                color: introSwitch ? 'inherit' : 'rgba(128, 128, 128, 0.5)',
                            }}
                        >
                            {`${profile?.User?.requests?.intros ?? 0} of  
                                ${
                                    profile?.User?.ActiveSubscription?.product?.metadata?.requests ?? 0
                                } intro requests have been used.`}
                        </Typography>
                        <Box className={classes.intros}>
                            <Typography
                                variant="font5"
                                sx={{
                                    color: autoIntroSwitch ? AppColors.Gray1 : 'rgba(128, 128, 128, 0.5)',
                                    fontWeight: 700,
                                }}
                            >
                                Auto Intro Requests
                            </Typography>
                            <Box className={classes.centerRow}>
                                <Typography
                                    variant="font6"
                                    sx={{
                                        fontStyle: 'italic',
                                        color: autoIntroSwitch ? AppColors.Gray1 : 'rgba(128, 128, 128, 0.5)',
                                    }}
                                >
                                    {autoIntroSwitch ? 'Enabled' : 'Disabled'}
                                </Typography>
                                <Switch color="primary" onChange={engagementAutoIntroSwitch} />
                            </Box>
                        </Box>
                        <Typography
                            variant="font5"
                            sx={{
                                color: autoIntroSwitch ? 'inherit' : 'rgba(128, 128, 128, 0.5)',
                            }}
                        >
                            {`${profile?.User?.requests?.autoIntros ?? 0} of  
                                ${
                                    profile?.User?.ActiveSubscription?.product?.metadata?.autoIntros ?? 0
                                } intro requests have been used.`}
                        </Typography>
                    </Box>
                    <Box className={classes.section}>
                        <Typography variant="font5" sx={{ color: AppColors.Gray1, fontWeight: 700 }} mb={1}>
                            Recent connections
                        </Typography>
                        <Box className={classes.connectionList}>
                            {!loadingConnections && connectionList.length === 0 && (
                                <Typography>No Recent Connections</Typography>
                            )}
                            {!loadingConnections &&
                                connectionList?.map?.((request, i) => {
                                    let otherProfile = request?.From?.id === profile.id ? request.To : request.From;

                                    return (
                                        <Box
                                            key={`row-${i}`}
                                            className={classes.connectionInfo}
                                            onClick={() => setProfileId(otherProfile.User.id)}
                                        >
                                            <Typography variant="font6" sx={{ fontWeight: 700 }}>
                                                {`${otherProfile.User.firstName} ${otherProfile.User.lastName}`}
                                            </Typography>
                                            <Typography variant="font6" sx={{ fontWeight: 700 }}>
                                                {otherProfile.User.persona}
                                            </Typography>
                                            <Typography variant="font6">
                                                {dayjs(request.updatedAt).format('MM/DD/YYYY')}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                    <Box className={classes.section}>
                        <Typography variant="font4" sx={{ color: AppColors.Gray1, fontWeight: 700 }} mb={1}>
                            Contact Information
                        </Typography>
                        <Box className={classes.contactList}>
                            <EmailIcon />
                            <Box className={classes.contactInfo} sx={{ ml: 1 }}>
                                <Typography variant="font6" sx={{ color: AppColors.Gray1 }}>
                                    Email (Primary)
                                </Typography>
                                <Typography variant="font6">{profile?.User?.email}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.section}>
                        <Typography
                            variant="font4"
                            sx={{
                                color: AppColors.Gray1,
                                fontWeight: 700,
                                mb: 1,
                            }}
                        >
                            User Settings
                        </Typography>
                        <Box className={classes.buttonLayout}>
                            <LoadingButton
                                sx={{ mb: 1 }}
                                loading={deleting}
                                onClick={handlePauseUser}
                                variant="contained"
                                className={classes.buttonOne}
                            >
                                Pause Account
                            </LoadingButton>
                            <LoadingButton
                                loading={deleting}
                                onClick={handleDeleteUser}
                                sx={{ backgroundColor: AppColors.Red }}
                                variant="contained"
                                className={classes.buttonTwo}
                            >
                                Remove User
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            )}
        </Drawer>
    );
}
