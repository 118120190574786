import React, { useState } from 'react';
import clsx from 'clsx';
import DefaultUser from 'assets/images/icons/default-user.png';
import useStyles from './styles';
import { Box } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

const AvatarUpload = ({
    sx,
    defaultPhoto = DefaultUser,
    disabled,
    height = 150,
    width = 150,
    src,
    onChange,
    ...props
}) => {
    const classes = useStyles(props);

    let inputField = React.createRef();

    const [showEditImage, setShowEditImage] = useState(false);

    function handleClick() {
        if (!props.value) return inputField.click();

        inputField.value = '';
        if (typeof onChange === 'function') {
            onChange('');
        }
    }

    return (
        <React.Fragment>
            <input
                ref={(el) => (inputField = el)}
                className={classes.input}
                id="avatar-upload-component"
                multiple
                onChange={onChange}
                type="file"
            />
            <Box
                className={clsx(classes.profileImageView)}
                onMouseEnter={(e) => setShowEditImage(true)}
                onMouseLeave={(e) => setShowEditImage(false)}
                sx={{ height, width, ...sx }}
            >
                {showEditImage && !disabled ? (
                    <Box onClick={!disabled && handleClick} className={clsx(classes.editImage)}>
                        <CloudUpload style={{ fontSize: 24, color: 'white' }} />
                    </Box>
                ) : null}
                <img src={src || defaultPhoto} className={clsx(classes.profileImage)} alt="User" />
            </Box>
        </React.Fragment>
    );
};

export default AvatarUpload;
