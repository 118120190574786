import { Business, MyLocation, QuestionAnswer, Send, Work, AutoFixHigh, AutoAwesome } from '@mui/icons-material';
import { FOUNDER_SECTORS } from 'utils/constants';

const InvestorFilters = [
    {
        label: 'Firm Name',
        field: 'companyName',
        type: 'string',
        Icon: Business,
    },
    {
        label: 'Auto Intro Enabled',
        field: 'autoIntros',
        type: 'boolean',
        Icon: AutoFixHigh,
    },
    {
        label: 'Sectors',
        field: 'sectors',
        items: FOUNDER_SECTORS,
        type: 'dropdown',
        Icon: AutoAwesome,
    },
    {
        label: 'Investor Type',
        field: 'investorType',
        type: 'string',
        Icon: Work,
    },
    { label: 'City', field: 'city', type: 'string', Icon: MyLocation },
    {
        label: '>= # of Intro Requests Received',
        field: 'introsReceived',
        type: 'number',
        Icon: Send,
    },
    {
        label: '>= # of Requests Engaged With',
        field: 'introsTaken',
        type: 'number',
        Icon: QuestionAnswer,
    },
];

export default InvestorFilters;
