import React, { useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import phoneModule from 'phone';
import { LoadingButton } from '@mui/lab';

export default function BasicInformation({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            linkedin: user.linkedin,
            intro: user.intro,
        },
    });

    const hearAboutUs = watch('hearAboutUs');

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/basic', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card
            title={user.ActiveProfile ? 'Start your migration!' : 'Thanks for signing up!'}
            subtitle="Let's get started"
        >
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />

                <Autocomplete
                    freeSolo
                    value={hearAboutUs || ''}
                    inputValue={hearAboutUs || ''}
                    onInputChange={(e, v) => setValue('hearAboutUs', v)}
                    options={['Word of Mouth', 'Search Engine', 'Social Media', 'Email Marketing', 'Website', 'Other']}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.field}
                            error={!!errors.hearAboutUs}
                            helperText={errors?.hearAboutUs?.message}
                            label="How did you hear about us?"
                            {...register('hearAboutUs', { required: 'Please fill out this field.' })}
                        />
                    )}
                />
                <TextField
                    className={classes.field}
                    label="First Name"
                    fullWidth
                    placeholder="John"
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    {...register('firstName', {
                        required: 'Please enter your first name',
                    })}
                />
                <TextField
                    className={classes.field}
                    label="Last Name"
                    fullWidth
                    placeholder="Doe"
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    {...register('lastName', {
                        required: 'Please enter your last name',
                    })}
                />
                <TextField
                    className={classes.field}
                    label="Phone Number"
                    fullWidth
                    placeholder="(555) 555 5555"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    {...register('phone', {
                        required: 'Invalid phone number',
                        validate: (value) => phoneModule(value).isValid,
                    })}
                />
                <TextField
                    className={classes.field}
                    label="LinkedIn"
                    fullWidth
                    placeholder="https://linkedin.com/my-profile"
                    error={!!errors.linkedin}
                    helperText={errors?.linkedin?.message}
                    {...register('linkedin', {})}
                />
                <TextField
                    className={classes.field}
                    multiline
                    rows={3}
                    label="Introduce yourself in 1-2 sentences"
                    fullWidth
                    placeholder="I am the founder of Seedscout which is aiming to build a modern day LinkedIn. When i'm not working, I love spending time with my wife & going hiking in northern Arizona."
                    error={!!errors.intro}
                    helperText={errors?.intro?.message}
                    {...register('intro', {
                        required: 'Please enter an introduction',
                    })}
                />
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
