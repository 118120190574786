import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    userCardHeader: {
        display: 'flex',
        gap: 12,
        '& > :first-child': {
            height: 70,
            width: 70,
            borderRadius: 6,
            objectFit: 'cover',
        },
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    searchField: {
        fontSize: 12,

        '& input::placeholder': {
            fontSize: 12,
        },
        '& input': {
            fontSize: 12,
        },
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        gap: 4,
    },

    actionButton: {
        height: 40,
        cursor: 'pointer',
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        border: `1px solid rgb(203, 213, 225)`,
        transition: '0.2s all ease-in',
        '& > svg': {
            fontSize: 20,
            fill: 'rgb(203, 213, 225)',
        },

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            // border: 'none',

            '& > *': {
                color: 'white !important',
                fill: 'white !important',
            },
        },
    },
}));

export default useStyles;
