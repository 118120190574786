import { formatDate } from 'utils/string';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const getEngagementData = (profile) => [
    { title: 'Intros Accepted', data: profile?.introsAccepted },
    { title: 'Intros Rejected', data: profile?.introsRejected },
    {
        title: 'Intros Ignored',
        data:
            profile?.introsReceived +
            profile?.introsSent -
            (profile?.introsAccepted + profile?.introsRejected),
    },
    {
        title: 'Member Since',
        data: formatDate(profile?.createdAt, 'MM/DD/YYYY'),
    },
    {
        title: 'Last Login',
        data: profile?.User?.lastLogin
            ? dayjs(profile?.User?.lastLogin).fromNow()
            : 'N/A',
    },
    {
        title: 'Last Engagement',
        data: profile?.User?.lastEngageDate
            ? dayjs(profile?.User?.lastEngageDate).fromNow()
            : 'N/A',
    },
];
