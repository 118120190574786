import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    dropBox: {
        border: '1px dashed rgb(232, 232, 232)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#E7ECF7',
        padding: 24,
        cursor: 'pointer',
        transition: '0.2s all ease-in',

        '& > svg': {
            transition: '0.2s all ease-in',
        },

        '&:hover': {
            backgroundColor: theme.palette.OffWhite.main,
            '& > svg': {
                fill: theme.palette.primary.main,
            },
        },
    },
    progress: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        borderRadius: 6,
        width: '25%',
    },
    animation: {
        maxWidth: '40%',
    },

    file: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 3,
    },
    key: {
        backgroundColor: '#A6BAE2',
        padding: '29px 0px',
        display: 'flex',
        justifyContent: 'center',
    },
    uploadedImage: {
        maxWidth: '60%',
    },
    footer: {
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 12px',
    },
}));

export default useStyles;
