import axios from 'axios';
import ApiResource from './api_resource';
import { store } from 'redux/config';

const SeedscoutAPI = axios.create({
    baseURL: ApiResource.endpoint, // Your API base URL
});

// Add request interceptor
SeedscoutAPI.interceptors.request.use(
    function (config) {
        const { user } = store.getState();
        // Perform actions before sending the request (e.g., adding headers)
        // You can access your user profile information here if needed.
        if (user && !config.headers['x-auth']) {
            config.headers['x-auth'] = user.authToken;
            SeedscoutAPI.defaults.headers.common['x-auth'] = user.authToken;
        } else if (!user && config.headers['x-auth']) {
            delete config.headers['x-auth'];
            delete SeedscoutAPI.defaults.headers.common['x-auth'];
        }

        return config;
    },
    function (error) {
        // Handle request error (e.g., network issue)
        return Promise.reject(error);
    },
);

// Export the Axios SeedscoutAPI for use in your components
export default SeedscoutAPI;
