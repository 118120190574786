import React, { useEffect, useState } from 'react';
// MUI
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import { Close, NoteAdd } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import Lottie from 'lottie-react';
// Lottie
import UploadAnimation from 'assets/animations/upload.json';
// Styles
import { AppColors } from 'theme';
import useStyles from './styles';

export default function FileInput({ onChange, onError }) {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [maxSizeError, setMaxSizeError] = useState(false);

    useEffect(() => {
        onChange?.(file);
    }, [file]);

    async function upload(files) {
        if (file) handleClear();

        try {
            const _file = files[0];
            if (_file.size > 10000000) {
                setMaxSizeError(true);
                throw new Error('Max file size of 10mb reached');
            } else if (maxSizeError) setMaxSizeError(false);

            setFile(_file);
        } catch (error) {
            console.log(error);
            onError?.(error);
        }
    }

    async function handleClear() {
        setFile(null);
    }

    if (file) {
        return (
            <Box className={classes.dropBox}>
                <Box className={classes.file}>
                    <Box className={classes.key}>
                        {file.type.split('/')[0] === 'image' ? (
                            <img src={file.key} className={classes.uploadedImage} />
                        ) : (
                            <NoteAdd
                                sx={{
                                    fill: AppColors.DarkBlue,
                                    fontSize: 60,
                                }}
                            />
                        )}
                    </Box>
                    <Box className={classes.footer}>
                        <Typography variant="font5">{file.type}</Typography>
                        <IconButton onClick={handleClear}>
                            <Close sx={{ fontSize: 14 }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Dropzone maxFiles={1} onDrop={upload}>
            {({ getRootProps, getInputProps }) => (
                <Box className={classes.dropBox} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <NoteAdd
                        sx={{
                            fill: AppColors.Gray0,
                            fontSize: 60,
                        }}
                    />
                    <Typography
                        variant="font2"
                        sx={{
                            marginTop: 1,
                            marginBottom: 0.5,
                        }}
                    >
                        Select File to Upload
                    </Typography>
                    <Typography
                        variant="font5"
                        sx={{
                            color: AppColors.Gray0,
                        }}
                    >
                        or Drag and Drop, Copy and Paste Files
                    </Typography>
                    <Typography
                        variant="font5"
                        sx={{
                            color: maxSizeError ? AppColors.Error : AppColors.Gray1,
                        }}
                    >
                        Max File Size: 10mb
                    </Typography>
                </Box>
            )}
        </Dropzone>
    );
}
