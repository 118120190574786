import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    sectionContainer: {
        backgroundColor: 'white',
        boxShadow: theme.boxShadows[0],
        borderRadius: 6,
        padding: 24,
    },
    profileImage: {
        borderRadius: '50%',
        width: 180,
        height: 180,
        objectFit: 'cover',
    },
    divider: {
        backgroundColor: 'rgb(232, 232, 232)',
        height: 1,
        width: '100%',
        marginTop: '0.75em',
        marginBottom: '0.75em',
    },
    introRequestHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },

    emptyTable: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
    },

    /** Auxiliary */
    center: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles;
