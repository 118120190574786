import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';

export default function Card({
    title,
    subtitle,
    noStyle,
    className,
    titleProps,
    subtitleProps,
    cardOnly,
    gutter = true,
    titleChildren,
    children,
    titleSx,
    contentSx,
    actions,
    sx,
    ...props
}) {
    const classes = useStyles(props);

    if (cardOnly) {
        return (
            <Box
                className={clsx(classes.card, className, {
                    [classes.noStyle]: noStyle,
                })}
                sx={sx}
            >
                {children}
            </Box>
        );
    }

    return (
        <Box
            className={clsx(className, classes.card, {
                [classes.noStyle]: noStyle,
            })}
            sx={sx}
        >
            {title && (
                <Box className={classes.cardTitle} sx={titleSx}>
                    <Box className={classes.title}>
                        {title && (
                            <Typography variant="h5" {...titleProps}>
                                {title}
                            </Typography>
                        )}
                        {subtitle && (
                            <Typography variant="font4" color="Gray1.main" {...subtitleProps}>
                                {subtitle}
                            </Typography>
                        )}
                    </Box>
                    {actions && <Box className={classes.titleAction}>{actions}</Box>}
                </Box>
            )}
            {titleChildren && (
                <Box className={classes.cardTitleChildren} sx={titleSx}>
                    {titleChildren}
                </Box>
            )}
            <Box
                className={clsx(classes.cardContent, {
                    [classes.contentGutter]: gutter,
                })}
                sx={contentSx}
            >
                {children}
            </Box>
        </Box>
    );
}
