import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useStyles from './styles';
import UserRedux from 'redux/actions/user';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import { JOB_SEEKER_CATEGORIES, JOB_SEEKER_STACKS, JOB_SEEKER_WORKFORCE } from 'utils/constants';

export default function UpdateSeekerDialog({ open, onClose, onEdit }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            talentType: user.ActiveProfile?.meta?.talentType || '',
            timeInWorkforce: user.ActiveProfile?.meta?.timeInWorkforce || '',
            website: user.ActiveProfile?.meta?.website || '',
            coverLetter: user.ActiveProfile?.meta?.coverLetter || '',
            stacks: Array.isArray(user.ActiveProfile?.meta?.stacks) ? user.ActiveProfile?.meta?.stacks : [],
        },
    });

    async function handleUpdate(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch(`/v1/users/${user.id}`, values);
            onEdit(response.data);
            onClose?.();
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setSubmitting(false);
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.paper }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Box className={classes.dialogTitle} id="alert-dialog-title">
                <Typography variant="font1">Edit Profile</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleUpdate)} mt={1}>
                    <input style={{ display: 'none' }} type="submit" />

                    <Box className={classes.fieldContainer}>
                        <Typography>What category would you place yourself in?</Typography>
                        <Select
                            className={classes.field}
                            displayEmpty
                            fullWidth
                            error={Boolean(errors.talentType)}
                            defaultValue={user.ActiveProfile?.meta?.talentType || ''}
                            {...register('talentType', {})}
                        >
                            <MenuItem value="">Select a value</MenuItem>

                            {JOB_SEEKER_CATEGORIES.map((category) => {
                                return (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>How long in workforce?</Typography>
                        <Select
                            className={classes.field}
                            displayEmpty
                            fullWidth
                            error={Boolean(errors.timeInWorkforce)}
                            defaultValue={user.ActiveProfile?.meta?.timeInWorkforce || ''}
                            {...register('timeInWorkforce', {})}
                        >
                            <MenuItem value="">Select a value</MenuItem>

                            {JOB_SEEKER_WORKFORCE.map((value) => {
                                return (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>Where can people find your work online?</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            placeholder="https://example.com"
                            error={!!errors.website}
                            helperText={errors?.website?.message}
                            {...register('website', {})}
                        />
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>
                            Help startups on the platform understand the stacks you use to do your job. The more you can
                            share here, the better. What products are you using on a day to day basis when working
                        </Typography>
                        <Select
                            className={classes.field}
                            displayEmpty
                            fullWidth
                            error={Boolean(errors.stacks)}
                            multiple
                            defaultValue={
                                Array.isArray(user.ActiveProfile?.meta?.stacks) ? user.ActiveProfile?.meta?.stacks : []
                            }
                            {...register('stacks', {})}
                        >
                            {JOB_SEEKER_STACKS.map((stack) => {
                                return (
                                    <MenuItem key={stack} value={stack}>
                                        {stack}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>

                    <Box className={classes.fieldContainer}>
                        <Typography>Write a 280 character cover letter.</Typography>
                        <TextField
                            className={classes.field}
                            fullWidth
                            rows={4}
                            multiline
                            placeholder="I am...."
                            error={!!errors.coverLetter}
                            helperText={errors?.coverLetter?.message}
                            {...register('coverLetter', {})}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <Box className={classes.actionContainer}>
                <LoadingButton variant="contained" loading={submitting} onClick={handleSubmit(handleUpdate)}>
                    Update
                </LoadingButton>
            </Box>
        </Dialog>
    );
}
