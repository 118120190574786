import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    background: {
        width: '100%',
        height: 120,
        borderRadius: 12,
        boxShadow: theme.boxShadows[0],
        backgroundPosition: 'top',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    userPhoto: {
        height: 70,
        width: 70,
        borderRadius: '50%',
        objectFit: 'cover',
    },
    tabs: {
        borderTop: `1px solid ${theme.palette.border.main}`,
        borderBottom: `1px solid ${theme.palette.border.main}`,
        padding: '20px 0px',
        margin: '24px 0px',

        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    tab: {
        paddingLeft: 12,
        transition: '0.2s all ease-in',
        cursor: 'pointer',
        borderLeft: '0px solid transparent',
        '&:hover': {
            borderLeft: `4px solid ${theme.palette.primary.main}`,
        },
    },
    activeTab: {
        borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
}));

export default useStyles;
