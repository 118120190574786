import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: 'calc(100% - 24px)',
        },
    },
    dialogTitle: {
        padding: '20px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    actionContainer: {
        padding: '14px 24px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.border.main}`,
    },
    fields: {
        '& > *:not(:last-child)': {
            marginBottom: 18,
        },
    },
    fieldContainer: {
        '& > :first-child': {
            marginBottom: 4,
        },
    },
    seperator: {},
    field: {
        backgroundColor: 'white',
        borderRadius: 0,
        [`& fieldset`]: {
            borderRadius: 0,
        },
    },
    actions: {
        marginTop: 16,
        paddingTop: 16,
        borderTop: `1px solid ${theme.palette.border.main}`,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 8,
    },
    actionButton: {
        padding: '14px 28px',
        borderRadius: 0,
        boxShadow: 'unset',
    },
    footer: {
        width: '100%',
        padding: '12px 0px',
        backgroundColor: 'white',
        borderTop: `1px solid ${theme.palette.border.main}`,
        textAlign: 'center',
    },
}));

export default useStyles;
