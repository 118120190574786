import { makeStyles } from '@mui/styles';
import theme from 'theme';

const HEADER_HEIGHT = 80;

const useStyles = makeStyles(() => ({
    navigation: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    appBar: {
        boxShadow: 'unset',
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    toolbar: {
        backgroundColor: 'white',
        color: 'black',
        minHeight: HEADER_HEIGHT,

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    logo: {
        height: 50,
    },
    navItem: {
        cursor: 'pointer',
        padding: '0px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',

        '& > svg': {
            marginRight: theme.spacing(1),
            fontSize: 24,
        },

        // '&:hover': {
        //     color: theme.palette.primary.main,
        // },
        '&:before': {
            transition: 'all .4s ease-in-out',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4,
            content: '""',
            display: 'block',
            height: '.25rem',
            opacity: '0',
            position: 'absolute',
            top: '0px',
            '-webkit-transform': 'translateY(50px)',
            '-ms-transform': 'translateY(16px)',
            transform: 'translateY(16px)',
            width: '100%',
            opacity: 0,
        },
        '&:hover:before': {
            opacity: 1,
        },
    },
    activeItem: {
        '&:before': {
            opacity: 1,
        },

        '& > svg': {
            color: theme.palette.primary.main,
            fillColor: theme.palette.primary.main,
        },
    },
    rightContent: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    notificationContainer: {
        backgroundColor: '#F4F5F8',
        padding: '9px 12px',
        paddingBottom: 6,
        borderRadius: 6,
        cursor: 'pointer',

        transition: '0.2s all ease-in',
        '&:hover': {
            backgroundColor: '#F0F0F2',
        },
    },
}));

export default useStyles;
