import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: 18,

        '& > :not(:last-child)': {
            paddingBottom: 18,
            borderBottom: `1px solid ${theme.palette.border.main}`,
        },
    },
    skeleton: {
        display: 'flex',
        flexDirection: 'column',
        gap: 18,
    },
}));

export default useStyles;
