import SeedscoutAPI from 'integrations/api/seedscout_api';
import { SET_SUBSCRIPTION_PRODUCTS } from 'redux/reducers/subscriptionProducts';

export default class SubscriptionProductsRedux {
    static get = () => async (dispatch, getState) => {
        const { subscriptionProducts } = getState();
        const time = new Date().getTime() / 1000;
        if (time < subscriptionProducts.lastSync + 3600) return;

        const [productsResponse] = await Promise.all([SeedscoutAPI.get(`/v1/stripe/products`)]);

        productsResponse.data.data.sort((productA, productB) => {
            if (productA.default_price.unit_amount > productB.default_price.unit_amount) return 1;
            if (productA.default_price.unit_amount < productB.default_price.unit_amount) return -1;
            return 0;
        });

        return dispatch({
            type: SET_SUBSCRIPTION_PRODUCTS,
            payload: {
                loaded: true,
                lastSync: time,
                ...productsResponse.data,
            },
        });
    };

    static set = (payload) => (dispatch, getState) => {
        dispatch({
            type: SET_SUBSCRIPTION_PRODUCTS,
            payload,
        });
    };

    static reset = () => (dispatch, getState) => {
        dispatch({
            type: SET_SUBSCRIPTION_PRODUCTS,
            payload: {},
        });
    };
}
