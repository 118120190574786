import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import FileInput from 'components/inputs/FileInput/FileInput';

export default function PitchDeck({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);

    const { handleSubmit } = useForm();

    function handleFileChanged(value) {
        if (!value) return setFile('file', null);
        setFile(value);
    }

    async function handleContinue() {
        setSubmitting(true);
        try {
            let body = {};
            if (file) {
                body = new FormData();
                body.append('file', file);
            }

            const response = await SeedscoutAPI.patch('/v1/signup/founder/pitch-deck', body);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Upload a Pitch Deck" subtitle="Last step... We promise!">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />
                {user.ActiveProfile?.meta?.pitchDeck && (
                    <Button component="a" target="_blank" href={user.ActiveProfile.meta.pitchDeck}>
                        View My current pitchdeck
                    </Button>
                )}
                <FileInput onChange={handleFileChanged} onError={onError} />
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Skip
                </LoadingButton>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
