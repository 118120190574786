import { CLOSE_SNACKBAR, SET_SNACKBAR } from '../reducers/snackbar';

export const setSnackbar = (payload) => {
    return {
        type: SET_SNACKBAR,
        payload,
    };
};

export const error = (payload) => {
    return {
        type: SET_SNACKBAR,
        payload: {
            severity: 'error',
            ...payload,
        },
    };
};
export const success = (payload) => {
    return {
        type: SET_SNACKBAR,
        payload: {
            severity: 'success',
            ...payload,
        },
    };
};
export const warning = (payload) => {
    return {
        type: SET_SNACKBAR,
        payload: {
            severity: 'warning',
            ...payload,
        },
    };
};
export const info = (payload) => {
    return {
        type: SET_SNACKBAR,
        payload: {
            severity: 'info',
            ...payload,
        },
    };
};

export const close = (payload) => {
    return {
        type: CLOSE_SNACKBAR,
    };
};
