import { AccountBox, Flag, Hiking, PlayArrowOutlined, Work } from '@mui/icons-material';
import { GiTakeMyMoney } from 'react-icons/gi';
import { AiOutlineBarChart } from 'react-icons/ai';

export const MenuItems = [
    {
        name: 'Admin Dashboard',
        pathname: '/admin',
        Icon: AiOutlineBarChart,
        requirement: 'permissions.admin',
    },
    { name: 'Investors', pathname: '/investors', Icon: GiTakeMyMoney },
    { name: 'Founders', pathname: '/founders', Icon: Hiking },
    { name: 'Job Seekers', pathname: '/seekers', Icon: Work },
    {
        name: 'Your Introductions',
        pathname: '/introductions',
        Icon: PlayArrowOutlined,
    },
];

export const DrawerItems = [
    {
        type: 'link',
        name: 'Admin Dashboard',
        pathname: '/admin',
        Icon: AiOutlineBarChart,
        requirement: 'permissions.admin',
    },
    { type: 'link', name: 'Your Profile', pathname: '/', Icon: AccountBox },
    { type: 'link', name: 'Investors', pathname: '/investors', Icon: GiTakeMyMoney },
    { type: 'link', name: 'Founders', pathname: '/founders', Icon: Hiking },
    { type: 'link', name: 'Job Seekers', pathname: '/seekers', Icon: Work },
    {
        type: 'link',
        name: 'Your Introductions',
        pathname: '/introductions',
        Icon: PlayArrowOutlined,
    },
];
