let endpoint;
let s3;
// load the correct aws config options

switch (process.env.REACT_APP_VERCEL_ENV) {
    case 'production':
        endpoint = 'https://api.seedscout.com/api';
        s3 = {
            endpoint: 'seedscout-prodstack-filess3bucket-g2kd8txvle6m',
            url: 'https://seedscout-prodstack-filess3bucket-g2kd8txvle6m.s3.amazonaws.com',
        };
        break;
    case 'development':
    case 'preview':
        endpoint = 'https://dev.api.seedscout.com/api';
        s3 = {
            endpoint: 'seedscout-devstack-filess3bucket-1nj303za2tq6i',
            url: 'https://seedscout-devstack-filess3bucket-1nj303za2tq6i.s3.amazonaws.com',
        };
        break;
    default:
        endpoint = 'http://localhost:5400/api';
        s3 = {
            endpoint: 'seedscout-devstack-filess3bucket-1nj303za2tq6i',
            url: 'https://seedscout-devstack-filess3bucket-1nj303za2tq6i.s3.amazonaws.com',
        };
        break;
}

if (process.env.REACT_APP_VERCEL_ENV !== 'production') {
    // Put stuff here
}

const ApiResource = { endpoint, s3 };

export default ApiResource;
