import React, { useState } from 'react';
import StatSection from './StatSection/StatSection';
import UserTable from './UserTable/UserTable';
import IntrosTable from './IntrosTable/IntrosTable';
import ProfileDrawer from './ProfileDrawer/ProfileDrawer';
//MUI
import { Container, Grid } from '@mui/material';
// Styles
import useStyles from './styles';

export default function Admin() {
    const classes = useStyles();

    const [profileID, setProfileID] = useState(null);
    const [refresh, setRefresh] = useState(null);

    function handleOpenDrawer(id) {
        setProfileID(id);
    }

    return (
        <Container maxWidth="lg" sx={{ marginTop: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <IntrosTable />
                </Grid>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <StatSection openDrawer={handleOpenDrawer} />
                </Grid>
                <Grid item xs={12} md={8} className={classes.gridItem}>
                    <UserTable openDrawer={handleOpenDrawer} refresh={refresh} />
                </Grid>
                <ProfileDrawer
                    onClose={() => setProfileID(null)}
                    setProfileId={handleOpenDrawer}
                    profileID={profileID}
                    onDelete={(id) => setRefresh(id)}
                />
            </Grid>
        </Container>
    );
}
