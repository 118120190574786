/**
 * I realized that i'm pretty stupid....
 * A subscription is always in the active state until stripe deletes it...
 * At that point it is cancelled...
 *
 * You must be willing to fail in order to succeed. :)
 * */

export function isSubscriptionActive(subscription) {
    const currentTime = new Date().getTime() / 1000;

    if (subscription?.status === 'active') return true;
    if (subscription?.status === 'canceled') {
        if (subscription.current_period_end < currentTime) {
            return false;
        } else {
            return true;
        }
    }

    return false;
}
