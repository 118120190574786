import React, { useState, useRef, useEffect } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';

export default function Dropdown({
    children,
    open,
    title = '',
    typeProps = {},
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'center',
    },
    popoverProps = {},
    ...props
}) {
    const classes = useStyles(props);
    const location = useLocation();

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    useEffect(() => {
        setOpenedPopover(false);
    }, [location.pathname]);

    const popoverEnter = ({ currentTarget }) => {
        setOpenedPopover(true);
    };

    const popoverLeave = ({ currentTarget }) => {
        setOpenedPopover(false);
    };

    return (
        <>
            <Box
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={popoverLeave}
                {...typeProps}
            >
                {title}
            </Box>
            <Popover
                id="mouse-over-popover"
                sx={{ marginTop: 1 }}
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent,
                }}
                open={Boolean(openedPopover || open)}
                anchorEl={popoverAnchor.current}
                anchorOrigin={anchorOrigin}
                PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
                {...popoverProps}
            >
                {children}
            </Popover>
        </>
    );
}
