import cloneDeep from 'lodash/cloneDeep';
import ApiResource from 'integrations/api/api_resource';
import { SET_NOTIFICATIONS } from 'redux/reducers/notifications';
import SeedscoutAPI from 'integrations/api/seedscout_api';

export default class NotificationsRedux {
    static get = () => async (dispatch, getState) => {
        const { user } = getState();

        const response = await SeedscoutAPI.get(`/v1/notifications`, {});

        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: response.data,
        });
    };

    static readAll = () => async (dispatch, getState) => {
        const { user, notifications } = cloneDeep(getState());

        notifications.unread = 0;

        await SeedscoutAPI.patch(`/v1/notifications/read-all`, null, {});

        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications,
        });
    };

    static read = (notification) => async (dispatch, getState) => {
        const { user, notifications } = cloneDeep(getState());

        if (notification.readAt) return;
        const { id } = notification;

        notifications.unread -= 1;
        notifications.items.forEach((n) => {
            if (n.id === id) n.readAt = new Date();
        });

        await SeedscoutAPI.patch(`/v1/notifications/read/${id}`, null, {});

        return dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications,
        });
    };

    static set = (payload) => (dispatch, getState) => {
        dispatch({
            type: SET_NOTIFICATIONS,
            payload,
        });
    };

    static reset = () => (dispatch, getState) => {
        dispatch({
            type: SET_NOTIFICATIONS,
            payload: { items: [], count: 0 },
        });
    };
}
