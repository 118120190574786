import React, { useState } from 'react';
// Custom
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    TableSortLabel,
    TablePagination,
    Checkbox,
} from '@mui/material';
// Styles
import useStyles from './styles';
import { InfoOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function CustomTable(props) {
    const {
        onSort,
        pagination = true,
        dense,
        check,
        cellPadding,
        adminTable,
        onSelectAllClick,
        handleClick,
        openProfileDrawer,
        selected = {},
        count = 0,
        headers = [],
        rowsPerPage = 25,
        page,
        rows = [],
        onChangePage,
        onChangeRows,
        tooltips = {},
        initialOrderIndex = 2,
        perPageOptions = [5, 10, 25, 50],
    } = props;

    const classes = useStyles(props);

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(initialOrderIndex); // 2 is the index for the column 'date'
    const [rowCount, numSelected] = [rows.length, Object.keys(selected).length];

    function handleRequestSort(e, index) {
        let newOrder = order;
        if (orderBy === index) {
            if (order === 'desc') {
                newOrder = 'asc';
                setOrder(newOrder);
            } else {
                newOrder = 'desc';
                setOrder(newOrder);
            }
        }
        setOrderBy(index);

        if (typeof onSort === 'function') {
            onSort(newOrder, index);
            return;
        }
    }

    function handlePageChange(e, page) {
        if (typeof onChangePage !== 'undefined') {
            onChangePage(e, page);
        }
    }

    function handleChangeRowsPerPage(e) {
        if (typeof onChangeRows !== 'undefined') {
            onChangeRows(e);
        }
    }

    return (
        <>
            <TableContainer>
                <Table size={dense ? 'small' : ''}>
                    <TableHead>
                        <TableRow>
                            {check && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        indeterminate={
                                            numSelected > 0 &&
                                            numSelected < rowCount
                                        }
                                        checked={
                                            rowCount > 0 &&
                                            numSelected >= rowCount
                                        }
                                        onChange={onSelectAllClick}
                                        inputProps={{
                                            'aria-label': 'select all users',
                                        }}
                                    />
                                </TableCell>
                            )}
                            {headers.map((header, index) => (
                                <TableCell
                                    classes={{
                                        body: classes.body,
                                        head: classes.head,
                                    }}
                                    key={`header-${index}`}
                                    style={{ padding: cellPadding }}
                                >
                                    {header && (
                                        <TableSortLabel
                                            active={orderBy === index}
                                            direction={order}
                                            onClick={(e) =>
                                                handleRequestSort(e, index)
                                            }
                                            classes={{
                                                root: classes.tableSortLabel,
                                                active: classes.tableSortLabelActive,
                                                icon: classes.tableSortLabelActive,
                                            }}
                                        >
                                            {header}
                                            {tooltips[header] && (
                                                <Tooltip
                                                    enterDelay={0}
                                                    title={
                                                        tooltips[header] ||
                                                        'Sort'
                                                    }
                                                >
                                                    <InfoOutlined
                                                        className={classes.info}
                                                    />
                                                </Tooltip>
                                            )}
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rindex) => {
                            return (
                                <React.Fragment key={`row-${rindex}`}>
                                    {adminTable ? (
                                        <TableRow
                                            key={`row-${rindex}`}
                                            onClick={() =>
                                                openProfileDrawer(row.id)
                                            }
                                            sx={
                                                row.navigate && {
                                                    cursor: 'pointer',
                                                }
                                            }
                                        >
                                            {check && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        size="small"
                                                        onClick={(e) =>
                                                            handleClick(
                                                                e,
                                                                row.id,
                                                            )
                                                        }
                                                        checked={
                                                            !!selected[row.id]
                                                        }
                                                        inputProps={{
                                                            'aria-labelledby': `row-${rindex}`,
                                                        }}
                                                    />
                                                </TableCell>
                                            )}
                                            {row.columns.map(
                                                (column, cindex) => {
                                                    return (
                                                        <TableCell
                                                            key={`${rindex}-col-${cindex}`}
                                                            classes={{
                                                                body: classes.body,
                                                                head: classes.head,
                                                            }}
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                padding:
                                                                    cellPadding,
                                                            }}
                                                        >
                                                            {column}
                                                        </TableCell>
                                                    );
                                                },
                                            )}
                                        </TableRow>
                                    ) : (
                                        <TableRow
                                            key={`row-${rindex}`}
                                            component={row.navigate && Link}
                                            target={row.navigate?.target}
                                            to={row.navigate?.to}
                                            hover={!!row.navigate}
                                            sx={
                                                row.navigate && {
                                                    cursor: 'pointer',
                                                }
                                            }
                                        >
                                            {row.columns.map(
                                                (column, cindex) => {
                                                    return (
                                                        <TableCell
                                                            key={`${rindex}-col-${cindex}`}
                                                            classes={{
                                                                body: classes.body,
                                                                head: classes.head,
                                                            }}
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                padding:
                                                                    cellPadding,
                                                            }}
                                                        >
                                                            {column}
                                                        </TableCell>
                                                    );
                                                },
                                            )}
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && (
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={perPageOptions}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        root: classes.paginationRoot,
                    }}
                />
            )}
        </>
    );
}
