import React, { useState } from 'react';
import useStyles from './styles';
import DefaultLogo from 'assets/images/logos/logo-black-tp.png';
import Card from 'components/Cards/Card/Card';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Cloud, DeleteForever, Edit, NoteAdd } from '@mui/icons-material';
import clsx from 'clsx';
import LinkButton from 'components/Buttons/LinkButton/LinkButton';
import UpdateFounderDialog from 'components/Dialogs/UpdateFounderDialog/UpdateFounderDialog';
import { useDispatch } from 'react-redux';
import UserRedux from 'redux/actions/user';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import AvatarUpload from 'components/inputs/AvatarUpload/AvatarUpload';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UpdatePitchDeckDialog from 'components/Dialogs/UpdatePitchDeckDialog/UpdatePitchDeckDialog';
import { useConfirm } from 'material-ui-confirm';

export default function FounderProfileCard({ profile, onEdit, editable }) {
    const classes = useStyles();
    const [dialog, setDialog] = useState(false);
    const [deletingPD, setDeletingPD] = useState(false);
    const [photo, setPhoto] = useState({ src: profile.logo || DefaultLogo });
    const dispatch = useDispatch();
    const confirm = useConfirm();

    function handleImageChanged(e) {
        if (!e) return setValue('photo', null);

        const file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            setPhoto({ src: e.target.result, type: file.type });
            handleUpdatePhoto(file);
        };
        reader.readAsDataURL(file);
    }

    async function handleUpdatePhoto(file) {
        try {
            let body = {};
            if (file) {
                body = new FormData();
                body.append('file', file);
            }

            const response = await SeedscoutAPI.patch(`/v1/profiles/${profile.UserId}/logo`, body);

            dispatch(UserRedux.setUser(response.data));
            dispatch(setSnackbar({ message: 'Logo updated!' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    function handleRemovePitchdeck() {
        confirm({ description: 'This will remove your pitchdeck from your profile. Are you sure?' })
            .then(async () => {
                setDeletingPD(true);
                try {
                    const response = await SeedscoutAPI.delete(`/v1/profiles/${profile.UserId}/pitch-deck`);

                    onEdit(response.data);
                    dispatch(setSnackbar({ message: 'Pitchdeck removed!' }));
                } catch (error) {
                    dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
                }
                setDeletingPD(false);
            })
            .catch(() => {});
    }

    return (
        <Card cardOnly>
            <UpdatePitchDeckDialog
                profile={profile}
                onEdit={onEdit}
                open={dialog === 'update-pitchdeck'}
                onClose={() => setDialog(false)}
            />
            <UpdateFounderDialog
                profile={profile}
                onEdit={onEdit}
                open={dialog === 'update-founder'}
                onClose={() => setDialog(false)}
            />
            <Box className={clsx(classes.section, classes.noMargin)}>
                <Typography variant="font4" fontWeight={'500'} color="lightText.main">
                    Startup One liner
                </Typography>
                <Typography variant="font4" maxWidth={'400px'} fontWeight={'400'} mt={2}>
                    {profile.meta.oneLiner || 'Not Specified'}
                </Typography>
            </Box>
            <Box className={classes.section}>
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    Startup Logo
                </Typography>
                <AvatarUpload
                    disabled={!editable}
                    src={photo.src}
                    classes={{ profileImageView: classes.logoPhoto }}
                    onChange={(e) => handleImageChanged(e)}
                />
            </Box>
            <Box className={clsx(classes.section)}>
                {editable && (
                    <IconButton className={classes.editInfoBtn} onClick={() => setDialog('update-founder')}>
                        <Edit />
                    </IconButton>
                )}
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    Startup Information
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">Company Name</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.companyName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">What stage are we at?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.stage}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">What sector are we in?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.sector}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">Have we raised any capital?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.raised}{' '}
                            {profile.meta.raised === 'Yes' &&
                                `- $${profile.meta.amountRaised?.stringToNumber?.().numberWithCommas?.()}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">Is this our fulltime job?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.fullTime}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">How many employees do we have?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.employees}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">Are we profitable?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.profitable}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">Do we have users?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.hasUsers}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="font5">How many active users/customers do we have?</Typography>
                        <Typography variant="font4" color="lightText.main">
                            {profile.meta.activeUsers}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="font5">What is our website?</Typography>
                        <LinkButton link={profile.meta.startupURL} />
                    </Grid>
                </Grid>
            </Box>
            <Box className={clsx(classes.section, classes.sectionNoBorder)}>
                {editable && (
                    <IconButton onClick={() => setDialog('update-pitchdeck')} className={classes.editInfoBtn}>
                        <Edit />
                    </IconButton>
                )}
                {editable && profile.meta.pitchDeck && (
                    <Tooltip title="Delete pitch deck">
                        <IconButton
                            disabled={deletingPD}
                            onClick={handleRemovePitchdeck}
                            className={classes.removeInfoBtn}
                        >
                            <DeleteForever />
                        </IconButton>
                    </Tooltip>
                )}
                <Typography variant="font4" fontWeight={'500'} color="lightText.main" mb={2}>
                    View our PitchDeck
                </Typography>

                {profile.meta.pitchDeck && (
                    <Box className={classes.dropBox} component="a" href={profile.meta.pitchDeck} target="_blank">
                        <Box className={classes.file}>
                            <Box className={classes.key}>
                                <NoteAdd
                                    sx={{
                                        fill: 'DarkBlue',
                                        fontSize: 60,
                                    }}
                                />
                            </Box>
                            <Box className={classes.footer}>
                                <Typography variant="font5">Pitch Deck</Typography>
                                <Cloud sx={{ fontSize: 14 }} />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Card>
    );
}
