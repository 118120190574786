import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { Snackbar, Alert } from '@mui/material';

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds

const StyledSnackbar = (props) => {
    const { snackbar } = useSelector(({ snackbar }) => ({ snackbar }));
    const dispatch = useDispatch();

    const {
        severity = 'success',
        vertical = 'top',
        horizontal = 'right',
        message,
        duration = AUTO_HIDE_TIME,
    } = snackbar;

    function handleClose() {
        dispatch(setSnackbar({ message: '' }));
    }

    if (!snackbar.message) return null;

    return (
        <Snackbar
            open={true}
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={duration}
            onClose={handleClose}
            style={{ zIndex: 99999 }}
        >
            <Alert severity={severity}>{message}</Alert>
        </Snackbar>
    );
};

export default StyledSnackbar;
