import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: theme.spacing(2),
    },
    startupBox: {
        padding: theme.spacing(3),
        height: '100%',
    },
    respondBox: {
        borderRadius: 6,
        height: '100%',
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

export default useStyles;
