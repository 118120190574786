import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import DefaultUser from 'assets/images/icons/default-user.png';
import Card from 'components/Cards/Card/Card';
import { Box, IconButton, Typography } from '@mui/material';
import { Bookmark, Edit } from '@mui/icons-material';
import LinkButton from 'components/Buttons/LinkButton/LinkButton';
import UpdateUserDialog from 'components/Dialogs/UpdateUserDialog/UpdateUserDialog';
import AvatarUpload from 'components/inputs/AvatarUpload/AvatarUpload';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import UserRedux from 'redux/actions/user';
import SeedscoutAPI from 'integrations/api/seedscout_api';

export default function UserProfileCard({ bookmarkable, user, profile, onEdit, editable = true }) {
    const classes = useStyles();
    const [dialog, setDialog] = useState(false);
    const [photo, setPhoto] = useState({ src: user?.photo || DefaultUser });
    const [bookmarking, setBookmarking] = useState(false);
    const [bookmark, setBookmark] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        async function _getBookmark() {
            if (editable) return;
            setBookmarking(true);
            try {
                const response = await SeedscoutAPI.get(`/v1/bookmarks/${profile.id}/Profile`);
                setBookmark(response.data);
            } catch (error) {}
            setBookmarking(false);
        }

        _getBookmark();
    }, [profile]);

    function handleImageChanged(e) {
        if (!e) return setValue('photo', null);

        const file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            setPhoto({ src: e.target.result, type: file.type });
            handleUpdatePhoto(file);
        };
        reader.readAsDataURL(file);
    }

    async function handleUpdatePhoto(file) {
        try {
            let body = {};
            if (file) {
                body = new FormData();
                body.append('file', file);
            }

            const response = await SeedscoutAPI.patch(`/v1/users/${user.id}/photo`, body);

            onEdit(response.data);
            dispatch(setSnackbar({ message: 'Photo updated!' }));
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
    }

    async function toggleBookmark() {
        setBookmarking(true);
        try {
            const response = await SeedscoutAPI.post(`/v1/bookmarks/toggle/${profile.id}/Profile`);
            setBookmark(response.data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setBookmarking(false);
    }

    return (
        <Card gutter={false} className={classes.root}>
            <UpdateUserDialog user={user} onEdit={onEdit} onClose={() => setDialog(false)} open={dialog} />
            <Box
                className={classes.background}
                sx={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://seedscout-public.s3.amazonaws.com/banners/${
                        user.id % 11
                    }.jpg)`,
                }}
            >
                {bookmarkable && (
                    <Box onClick={toggleBookmark} className={classes.bookmarkBtn} disabled={bookmarking}>
                        <Typography variant="font5">{bookmark ? 'Remove Bookmark' : 'Bookmark'}</Typography>
                        <Bookmark />
                    </Box>
                )}
                <AvatarUpload
                    disabled={!editable}
                    src={photo.src}
                    classes={{ profileImageView: classes.userPhoto }}
                    onChange={(e) => handleImageChanged(e)}
                />
            </Box>
            <Box className={classes.userInfo} sx={{ mt: 2 }}>
                {editable && (
                    <IconButton onClick={() => setDialog(true)} className={classes.editInfoBtn}>
                        <Edit />
                    </IconButton>
                )}
                <Typography variant="font5" color="Gray1.main" fontWeight={'600'}>
                    {profile.type}
                </Typography>
                <Typography variant="h6" fontWeight={'600'}>
                    {user?.firstName} {user?.lastName}
                </Typography>
                <Typography variant="font5" fontWeight={'600'} mb={2}>
                    Requests Sent: {profile?.introsReceived} Requests Replied To: {profile?.introsTaken}
                </Typography>
                {editable && (
                    <Typography variant="font5" fontWeight={'400'} color="Gray1.main">
                        {user?.phone ? user?.phone : 'Phone not provided, edit your profile to add your phone number.'}
                    </Typography>
                )}
                {editable && (
                    <Typography variant="font4" fontWeight={'400'} mb={2}>
                        {user?.email}
                    </Typography>
                )}
                <Typography variant="font5" color="Gray1.main" fontWeight={'400'}>
                    {user?.city}, {user?.state} {user?.country}
                </Typography>
                <LinkButton link={user?.linkedin} mb={2} />
                <Typography variant="font4" maxWidth={'400px'} fontWeight={'400'}>
                    {user?.intro}
                </Typography>
            </Box>
        </Card>
    );
}
