import React, { useMemo } from 'react';
import { TextField } from '@mui/material';

function verifyNumber(string) {
    const numericRepresentation = string.replace(/[,.]/g, '');

    return {
        isNumber: !isNaN(Number(numericRepresentation)),
        numberFormat: !isNaN(Number(numericRepresentation)) ? Number(numericRepresentation) : null,
    };
}

const NumericInput = React.forwardRef(function NumberFormatField(props, ref) {
    const { value, precision, thousandChar, decimalChar, ...inputProps } = props;
    const defaultValue = value === null ? NaN : Number(value);

    const formatter = useMemo(
        () =>
            new Intl.NumberFormat('en-US', {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            }),

        [thousandChar, decimalChar],
    );

    if (!decimalChar) {
        throw new Error('Decimal char should not be an empty string!');
    }
    if (!thousandChar) {
        throw new Error('Thousand char should not be an empty string!');
    }

    function format(number) {
        return formatter.format(number);
    }

    function handleKeyDown(e) {
        if (e.key === ' ') e.preventDefault();

        if (e.ctrlKey || e.shiftKey || e.key === 'Backspace' || e.key === 'Enter' || e.key === 'Tab') return;
        if (!verifyNumber(e.key).isNumber) e.preventDefault();
    }

    function handleChange(e) {
        const newEvent = {
            ...e,
            currentTarget: {
                ...e.currentTarget,
                name: props.name,
                value: 0,
            },
            target: {
                ...e.target,
                name: props.name,
                value: 0,
            },
        };
        let numericRepresentation = e.target.value;

        numericRepresentation = numericRepresentation.replaceAll(thousandChar, '');
        numericRepresentation = numericRepresentation.replace(decimalChar, '');

        if (numericRepresentation === '') {
            e.target.value = '';
            newEvent.target.value = null;
            newEvent.currentTarget.value = null;
            return props.onChange && props.onChange(newEvent);
        }

        const { isNumber, numberFormat } = verifyNumber(numericRepresentation);
        if (isNumber && numberFormat !== null) {
            const withPrecision = numberFormat / 10 ** precision;

            const formattedNumber = format(withPrecision);

            newEvent.target.value = withPrecision;
            newEvent.currentTarget.value = withPrecision;

            e.target.value = formattedNumber;

            props.onChange && props.onChange(newEvent);
        }
    }

    const hasValue = value !== undefined;
    let inputDefaultValue;
    let inputValue;

    if (hasValue) {
        if (isNaN(defaultValue) || value === '') {
            inputValue = null;
        } else {
            inputValue = format(defaultValue);
        }
    }

    if (!hasValue && !isNaN(defaultValue)) {
        inputDefaultValue = format(defaultValue);
    }

    return (
        <TextField
            ref={ref}
            defaultValue={inputDefaultValue}
            {...inputProps}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={inputValue}
        />
    );
});

export default NumericInput;
