import React, { useEffect } from 'react';
// Misc
import { useDispatch, useSelector } from 'react-redux';
// import { readNotifications } from 'redux/actions/notifications';
// MUI
import { Box } from '@mui/system';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    Tab,
    Tabs,
} from '@mui/material';
import { NotificationIcon } from 'components/Widgets/NotificationsList/NotificationIcon';
// Custom
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
// images
// Styles
import useStyles from './styles';
import NotificationsRedux from 'redux/actions/notifications';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Card from 'components/Cards/Card/Card';

export default function Notifications(props) {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { notifications } = useSelector(({ notifications }) => ({ notifications }));

    useEffect(() => {
        async function readAll() {
            try {
                await dispatch(NotificationsRedux.readAll());
            } catch (error) {
                dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
            }
        }

        // readAll();
    }, []);

    function handleAction(notification) {
        dispatch(NotificationsRedux.read(notification));
    }

    return (
        <Container maxWidth={'xl'} className={classes.root} sx={{ mt: 4 }}>
            <Card cardOnly sx={{ p: 4 }}>
                <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)} textColor="inherit">
                    <Tab label="All Notifications" />
                </Tabs>
                <TabPanel value={activeTab} index={0} className={classes.tabContent}>
                    {notifications.items.length === 0 && (
                        <Box sx={{ padding: 2 }}>
                            <Typography>You have no notifications.</Typography>
                        </Box>
                    )}

                    {notifications.items.length > 0 ? (
                        <List classes={{ root: classes.list }}>
                            {notifications.items.map((notification, i) => {
                                return (
                                    <ListItem
                                        onClick={() => handleAction(notification)}
                                        button
                                        key={`noti-${i}`}
                                        classes={{ root: classes.listItem }}
                                    >
                                        <ListItemIcon>
                                            <NotificationIcon type={notification.type} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={notification.meta.title}
                                            secondary={notification.meta.body}
                                            classes={{
                                                primary: classes.textPrimary,
                                                secondary: classes.textSecondary,
                                            }}
                                        />

                                        <ListItemSecondaryAction>
                                            <Typography variant="font5" sx={{ fontWeight: '200' }}>
                                                {dayjs(notification.createdAt).format('MMM DD')}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : null}
                </TabPanel>
            </Card>
        </Container>
    );
}
