import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import { usePlacesWidget } from 'react-google-autocomplete';

export default function UserLocation({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const cityPlaceWidget = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE,
        onPlaceSelected: (place) => {
            place.address_components.forEach((component) => {
                if (component.types.includes('locality')) {
                    setValue('city', component.long_name);
                } else if (component.types.includes('administrative_area_level_1')) {
                    setValue('state', component.short_name);
                } else if (component.types.includes('country')) {
                    setValue('country', component.long_name);
                }
            });

            setValue('coordinates', [place.geometry.location.lng(), place.geometry.location.lat()]);
        },
        options: {
            types: ['(regions)'],
        },
    });

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/location', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Where are you located?" subtitle="We will try to show you relevant results!">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />

                <TextField
                    inputProps={{
                        ref: cityPlaceWidget.ref,
                    }}
                    className={classes.field}
                    label="City"
                    fullWidth
                    placeholder="Phoenix"
                    error={!!errors.city}
                    helperText={'Select from dropdown'}
                    {...register('city', {
                        required: 'Please enter your city',
                    })}
                />
                <TextField
                    disabled={true}
                    className={classes.field}
                    label="State"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    placeholder="AZ"
                    error={!!errors.state}
                    helperText={errors?.state?.message}
                    {...register('state', {})}
                />
                <TextField
                    disabled={true}
                    className={classes.field}
                    label="Country"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    placeholder="United States"
                    error={!!errors.country}
                    helperText={errors?.country?.message}
                    {...register('country', {})}
                />
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
