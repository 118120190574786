import * as React from 'react';
import { useSelector } from 'react-redux';
// MUI
import { IconButton, Box, Drawer, List } from '@mui/material';
// images
import Logo from 'assets/images/logos/logo-black.png';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
// styles
import useStyles from './styles';
import { DrawerItems } from '../data';
import MobileNavItem from './MobileNavItem/MobileNavItem';
import { getNestedProperty } from 'utils/nested-key';

export default function MiniDrawer({ open, onClose, onLogout }) {
    const { user } = useSelector(({ user }) => ({ user }));
    const classes = useStyles();
    const location = useLocation();

    React.useEffect(() => {
        onClose();
    }, [location.pathname]);

    return (
        <Drawer anchor={'right'} open={open} onClose={onClose} classes={{ paper: classes.drawer }}>
            <Box role="presentation" className={classes.drawerBox}>
                <Box className={classes.drawerHeader}>
                    <img src={Logo} className={classes.logo} />
                    <IconButton onClick={onClose}>
                        <ArrowForwardIos size={16} />
                    </IconButton>
                </Box>
                <List>
                    <Box className={classes.navItems}>
                        {DrawerItems.map((item, i) => {
                            const { requirement } = item;

                            if (requirement) {
                                const requirementCheck = getNestedProperty(user, requirement);
                                if (!requirementCheck) return null;
                            }

                            return <MobileNavItem item={item} key={i} showTitle />;
                        })}
                    </Box>
                </List>
            </Box>
        </Drawer>
    );
}
