import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import DefaultUser from 'assets/images/icons/default-user.png';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { TYPE_TO_PATH } from 'utils/constants';
import dayjs from 'dayjs';

export default function ViewsItem({ view }) {
    const classes = useStyles();
    return (
        <Box className={classes.listItem}>
            <Box className={classes.listItemLeft}>
                <img src={view.Profile?.User?.photo || DefaultUser} />
                <Box className={classes.listItemText}>
                    <Typography variant="font4">
                        {view.Profile?.User?.firstName} {view.Profile?.User?.lastName}
                    </Typography>
                    <Typography variant="font5" color="lightText.main">
                        {view.Profile?.meta?.companyName || view.Profile?.meta?.firmName}
                    </Typography>
                    <Typography variant="font6" color="lightText.main">
                        Viewed: {dayjs(view.lastViewedOn).format('MMM DD, YYYY')}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.listItemRight}>
                <Box
                    className={classes.actionButton}
                    component={Link}
                    to={`/${TYPE_TO_PATH[view?.Profile?.type]}/${view?.Profile?.id}`}
                >
                    <Visibility />
                </Box>
            </Box>
        </Box>
    );
}
