import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    root: {},
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

export default useStyles;
