import { makeStyles } from '@mui/styles';

import theme from 'theme';

const useStyles = makeStyles(() => ({
    chartContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dataText: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
    },
    introCount: {
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
    },
    percentageText: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    chartInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    chartLayout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontFamily: theme.typography.fontFamily,
    },
    YAxis: {
        fontSize: 10,
    },
    XAxis: {
        // transform: 'translate(0, -50%)',
    },
    tooltip: {
        backgroundColor: 'white',
        boxShadow: theme.boxShadows[0],
        borderRadius: 6,
        padding: 10,
    },

    /** Auxiliary */
    center: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles;
