import React, { useEffect, useState } from 'react';
import { Container, Paper, Typography } from '@mui/material';
import useStyles from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import UserRedux from 'redux/actions/user';
import { setSnackbar } from 'redux/actions/snackbar';
import wait from 'utils/wait';
import checkError from 'utils/check-error';

export default function SubscriptionSuccess() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const userProduct = user?.ActiveSubscription?.product;
    const navigate = useNavigate();

    useEffect(() => {
        browse(false);

        return async () => {
            await dispatch(UserRedux.getCurrentUser());
        };
    }, []);

    async function browse(nav = true) {
        setLoading(true);
        try {
            if (!user.ActiveSubscription) {
                await wait(3);
                const payload = await dispatch(UserRedux.getCurrentUser());
                if (!payload.ActiveSubscription)
                    throw new Error('Account syncing... Please try again in a few minutes.');
            }
            nav && navigate('/');
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setLoading(false);
    }

    return (
        <Container className={classes.root} maxWidth="lg">
            <Paper className={classes.box}>
                <Typography variant="h6">You are now on the {userProduct?.name} Plan!</Typography>
                <Typography
                    variant="font3"
                    sx={{
                        maxWidth: 600,
                        margin: 'auto',
                        marginTop: 2,
                        marginBottom: 4,
                    }}
                >
                    Congrats you are now a paying Seedscout member with access to {userProduct?.metadata?.requests} per
                    month. Start browsing our network and request intros today!
                </Typography>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={browse}
                    sx={{ color: 'white !important' }}
                >
                    Browse the Seedscout Network
                </LoadingButton>
            </Paper>
        </Container>
    );
}
