import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import AvatarUpload from 'components/inputs/AvatarUpload/AvatarUpload';
import { LoadingButton } from '@mui/lab';

export default function ProfilePhoto({ onError }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState(null);

    const { watch, setValue, handleSubmit } = useForm();

    const photo = watch('photo', null);

    function handleImageChanged(e) {
        if (!e) return setValue('photo', null);

        const file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            setValue('photo', { src: e.target.result, type: file.type });
            setFile(file);
        };
        reader.readAsDataURL(file);
    }

    async function handleContinue(data) {
        setSubmitting(true);
        try {
            let body = {};
            if (file) {
                body = new FormData();
                body.append('file', file);
            }

            const response = await SeedscoutAPI.patch(`/v1/signup/photo`, body);

            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Select a profile photo">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <AvatarUpload onChange={(e) => handleImageChanged(e)} src={photo?.src} height={300} width={300} />
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Skip
                </LoadingButton>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
