import React, { useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import useStyles from './styles';
import Card from 'components/Cards/Card/Card';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import UserRedux from 'redux/actions/user';
import { LoadingButton } from '@mui/lab';
import { FOUNDER_SECTORS } from 'utils/constants';

export default function StartupInformation({ onError }) {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector(({ user }) => ({ user }));

    const {
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            companyName: user.ActiveProfile?.meta.companyName || '',
            sector: user.ActiveProfile?.meta.sector || '',
            startupURL: user.ActiveProfile?.meta.startupURL || '',
            oneLiner: user.ActiveProfile?.meta.oneLiner || '',
        },
    });

    const sector = watch('sector');

    async function handleContinue(values) {
        setSubmitting(true);
        try {
            const response = await SeedscoutAPI.patch('/v1/signup/founder/initial', values);
            dispatch(UserRedux.setUser(response.data));
        } catch (error) {
            onError(error);
        }
        setSubmitting(false);
    }

    return (
        <Card title="Setup Your Startup's Profile">
            <Box className={classes.fields} component="form" onSubmit={handleSubmit(handleContinue)}>
                <input style={{ display: 'none' }} type="submit" />
                <TextField
                    className={classes.field}
                    label="Company Name"
                    fullWidth
                    placeholder="John Doe Inc."
                    error={!!errors.companyName}
                    helperText={errors?.companyName?.message}
                    {...register('companyName', {
                        required: 'Please enter your company name',
                    })}
                />

                <Autocomplete
                    freeSolo
                    value={sector || ''}
                    inputValue={sector || ''}
                    onInputChange={(e, v) => {
                        setValue('sector', v);
                    }}
                    options={FOUNDER_SECTORS}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={!!errors.sector}
                            helperText={errors?.sector?.message}
                            label="What sector are you in?"
                            {...register('sector', { required: 'Please fill out this field.' })}
                        />
                    )}
                />
                <TextField
                    className={classes.field}
                    label="Startup URL"
                    fullWidth
                    placeholder="https://my-company-website.com"
                    error={!!errors.startupURL}
                    helperText={errors?.startupURL?.message}
                    {...register('startupURL', {})}
                />
                <TextField
                    rows={4}
                    multiline
                    className={classes.field}
                    label="One Liner"
                    fullWidth
                    error={!!errors.oneLiner}
                    helperText={errors?.oneLiner?.message}
                    {...register('oneLiner', {
                        required: 'Please enter a one liner',
                    })}
                />
            </Box>

            <Box className={classes.actions}>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    className={classes.actionButton}
                    onClick={handleSubmit(handleContinue)}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Card>
    );
}
