import React, { useEffect, useState } from 'react';
import { adminSetups } from './setup';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import clsx from 'clsx';
import StatSectionSkeleton from 'components/Skeletons/StatSection/StatSectionSkeleton';
//MUI
import { Typography, Box } from '@mui/material';
// Styles
import useStyles from './styles';
import SeedscoutAPI from 'integrations/api/seedscout_api';
import Card from 'components/Cards/Card/Card';

export default function StatSection({ openDrawer }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [topLoading, setTopLoading] = useState(false);
    const [bottomLoading, setBottomLoading] = useState(false);
    const { statOptions } = adminSetups;
    const [selectedTitle, setSelectedTitle] = useState('Top Investors');
    const [statData, setStatData] = useState({});
    const [topUsers, setTopUsers] = useState({
        'Top Investors': [],
        'Top Founders': [],
        'Top Job Seekers': [],
    });

    useEffect(() => {
        getStats();
        getTopUsers();
    }, []);

    async function getStats() {
        setTopLoading(true);
        try {
            const response = await SeedscoutAPI.get(`/v1/admin/stats/monthly`);
            const { data } = response.data;
            setStatData(data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setTopLoading(false);
    }

    async function getTopUsers() {
        setBottomLoading(true);
        try {
            const response = await SeedscoutAPI.get(`/v1/admin/users/top`);
            const { data } = response.data;
            setTopUsers(data);
        } catch (error) {
            dispatch(setSnackbar({ severity: 'error', message: checkError(error) }));
        }
        setBottomLoading(false);
    }

    function handleTitleSelect(e) {
        setSelectedTitle(e);
    }

    return (
        <Card cardOnly>
            <Typography variant="font2">
                There have been{' '}
                <Box component="span" color={'primary'}>
                    {`${statData.intros} intros`}
                </Box>{' '}
                made in the last 30 days.
            </Typography>
            <Box className={classes.divider} />
            <Box className={classes.statInfo}>
                <Typography color={'Gray1.main'}>Monthly Active Users</Typography>
                <Typography color={'primary'}>{statData.activeUsers}</Typography>
            </Box>
            <Box className={classes.statInfo}>
                <Typography color={'Gray1.main'}>New Users in the last 30 days</Typography>
                <Typography color={'primary'}>{statData.newUsers}</Typography>
            </Box>
            <Box className={classes.divider} />
            <Box className={classes.statSelector}>
                {statOptions.map((stat, i) => {
                    return (
                        <Box
                            key={`stat-${i}`}
                            className={clsx(classes.statTitle, {
                                [classes.activeTitle]: selectedTitle === stat.text,
                            })}
                            onClick={() => handleTitleSelect(stat.text)}
                        >
                            <Typography color={'Gray1.main'}>{stat.text}</Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box className={classes.statUnderline} />
            <Box className={classes.grid} sx={{ mt: 2 }}>
                {bottomLoading ? (
                    <StatSectionSkeleton />
                ) : (
                    <>
                        {topUsers[selectedTitle].map((data, i) => {
                            return (
                                <Box
                                    onClick={() => openDrawer(data?.UserId)}
                                    className={classes.rankingContainer}
                                    sx={{ padding: 1 }}
                                    key={`user-${i}`}
                                >
                                    <Typography variant="font6" sx={{ fontWeight: 700 }}>
                                        {`${data.User.firstName} ${data.User.lastName}`}
                                    </Typography>
                                    <Typography variant="font6">
                                        {data?.meta?.companyName ?? data?.meta?.firmName ?? data?.meta?.remoteOrIRL}
                                    </Typography>
                                    <Typography variant="font6">
                                        {`${data?.User?.persona} | ${
                                            data?.meta?.startupSector ??
                                            data?.meta?.sectorInterests ??
                                            data?.meta?.talentType
                                        }`}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </>
                )}
            </Box>
        </Card>
    );
}
