import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

export default function PlaceSearch({ onChange, placeholder, ...props }) {
    const [search, setSearch] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const searchTimer = React.useRef();

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
        usePlacesAutocompleteService({
            apiKey: process.env.REACT_APP_GOOGLE,
            options: {
                types: ['(regions)'],
            },
        });

    React.useEffect(() => {
        if (searchTimer.current) clearTimeout(searchTimer.current);

        searchTimer.current = setTimeout(async () => {
            getPlacePredictions({ input: search });
        }, 1000);

        return () => clearTimeout(searchTimer.current);
    }, [search]);

    return (
        <Autocomplete
            {...props}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(_, place) => {
                placesService?.getDetails({ placeId: place.place_id }, onChange);
            }}
            onInputChange={(event, newsearch) => setSearch(newsearch)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.description}`}
            options={placePredictions}
            loading={isPlacePredictionsLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isPlacePredictionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
