import { makeStyles } from '@mui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => {
    return {
        navTitle: {
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textTransform: 'uppercase',
            color: theme.palette.lightText.main,
        },
        navDot: {
            height: 40,
            display: 'flex',
            alignItems: 'center',
            '&:before': {
                display: 'block',
                content: '""',
                height: 8,
                width: 8,
                borderRadius: '50%',
                backgroundColor: 'rgba(9,9,9,0.7)',
                marginLeft: 18,
            },
        },
        navItem: {
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            cursor: 'pointer',
            color: theme.palette.lightText.main,

            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),

            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        activeNavItem: {
            '& > :first-child': {
                color: 'white',
                backgroundColor: theme.palette.primary.light,
            },
        },
        navItemIcon: {
            boxShadow: '0 3px 12px #0000000a',
            backgroundColor: 'white',
            borderRadius: 6,
            padding: '12px 16px',
            color: '#2e2828',
        },

        innerList: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: theme.spacing(3),
        },
        innerListItem: {
            padding: '12px 0px',
            cursor: 'pointer',
            color: theme.palette.lightText.main,
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            paddingLeft: theme.spacing(2),
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        activeInnerItem: {
            color: theme.palette.primary.main,
        },

        circleLogo: {
            height: 60,
            width: 60,
        },
        textLogo: {
            height: 65,
        },
    };
});

export default useStyles;
